import {
  API_DOCS_DOWNLOAD,
  API_DOCS_MULTI_DOWNLOAD,
  API_DOCUMENT_CREATE,
  API_DOCUMENT_DELETE,
  API_DOCUMENT_GET,
  API_DOCUMENT_LIST,
  API_DOCUMENT_LIST_ALL,
  API_DOCUMENT_UPDATE,
  API_GUIDELINE_CONDITION,
  API_RATESHEET,
  API_RATESHEET_UPDATE,
  API_RESOURCE_COMPANY_APPRAISAL,
  API_RESOURCE_COMPANY_APPRAISAL_BY_ID,
  API_RESOURCE_PROGRAM_RESTRICTION,
  API_TEMPLATE_PROGRAMS,
  baseUrl,
} from 'config'
import { store } from 'reducers'
import Api from 'services/api'
import { getFileName } from 'utils'

export const downloadS3Documents = (key: string) => {
  return Api.post(API_DOCS_DOWNLOAD, { key })
}

export const listAllDocuments = () => {
  return Api.get(API_DOCUMENT_LIST_ALL)
}

export const getTemplatePrograms = () => {
  return Api.get(API_TEMPLATE_PROGRAMS)
}

export const listDocuments = (type: string) => {
  return Api.get(API_DOCUMENT_LIST, {}, { type })
}

export const getDocument = (id: number) => {
  return Api.get(API_DOCUMENT_GET, {}, { id })
}

export const fileJsonConvert = (data: Record<string, string | string[] | File>) => {
  const files: File[] = []
  Object.keys(data).forEach((key) => {
    if (data[key] && (data[key] as any).size) {
      files.push(data[key] as any)
      delete data[key]
    }
  })
  return { data, files }
}

export const addNewDocument = (data: Record<string, string | string[] | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_DOCUMENT_CREATE, json.data, {}, json.files)
}

export const updateDocument = (id: number, data: Record<string, string | string[] | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_DOCUMENT_UPDATE, json.data, { id }, json.files)
}

export const deleteDocument = (id: number) => {
  return Api.delete(API_DOCUMENT_DELETE, {}, { id })
}

export const updateDocumentOrders = (data: Record<string, any>[]) => {
  return Api.put(API_DOCUMENT_CREATE, data)
}

export const openS3Document = async (fileKey: string, isNewWindow = false) => {
  const res = await downloadS3Documents(fileKey)
  if (res.content) {
    downloadFile(getFileName(fileKey), new Blob([res.content]))
    return
  }
  if (!isNewWindow) {
    var windowReference: any = window.open()
    windowReference.location = res.url
  } else {
    window.open(res.url, '_blank', 'resizable=yes')
  }
}

export const openAsyncS3Document = async (fileKey: string, popupWindow: Window) => {
  const res = await downloadS3Documents(fileKey)
  if (res.content) {
    downloadFile(getFileName(fileKey), new Blob([res.content]))
    return
  }
  popupWindow.location.href = res.url
}

export const downloadS3Document = async (fileKey: string, fileName: string) => {
  const res: Blob = await Api.post(
    API_DOCS_DOWNLOAD,
    { key: fileKey, fileName },
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(fileName, res)
}

export const downloadFile = (fileName: string, contentBlob: Blob) => {
  const aElement = document.createElement('a')
  aElement.setAttribute('download', fileName)
  const href = URL.createObjectURL(contentBlob)
  aElement.href = href
  aElement.setAttribute('target', '_blank')
  aElement.click()
  URL.revokeObjectURL(href)
}

export const openS3Documents = async (loanNumber: number, fileName: string, keys: string[], entity: string = '') => {
  const state = store.getState()
  const {
    auth: { token },
  } = state

  const url = `${baseUrl}${API_DOCS_MULTI_DOWNLOAD}/${loanNumber}?fileName=${fileName}&${keys
    .map((key) => `keys[]=${key}`)
    .join('&')}&token=${token}&entity=${entity}`
  const windowReference: any = window.open()
  windowReference.document.title = 'Downloading Documents...'
  windowReference.location = url
}

export const listAllRatesheet = (filters: Record<string, any>) => {
  return Api.get(API_RATESHEET, filters)
}

export const addRatesheet = (fileKey: string) => {
  return Api.post(API_RATESHEET, { fileKey })
}

export const updateRatesheet = (id: number, date: string) => {
  return Api.put(API_RATESHEET_UPDATE, { date }, { id })
}

export const deleteRatesheet = (id: number) => {
  return Api.delete(API_RATESHEET_UPDATE, {}, { id })
}

export const getResourceProgramRestrictions = (programs: string[]) => {
  return Api.get(API_RESOURCE_PROGRAM_RESTRICTION, { programs: programs.join(',') })
}

export const getGuidelineConditions = (id: number) => {
  return Api.get(API_GUIDELINE_CONDITION, {}, { id })
}

export const postGuidelineCondition = (id: number, data: Record<string, any>) => {
  return Api.post(API_GUIDELINE_CONDITION, data, { id })
}

export const deleteGuidelineCondition = (id: number) => {
  return Api.delete(API_GUIDELINE_CONDITION, {}, { id })
}

// resource part

export const addNewCompanyAppraisal = (data: Record<string, any>) => {
  return Api.post(API_RESOURCE_COMPANY_APPRAISAL, data)
}

export const getAllCompanyAppraisal = () => {
  return Api.get(API_RESOURCE_COMPANY_APPRAISAL)
}

export const updateCompanyAppraisal = (data: Record<string, any>, id: number) => {
  return Api.put(API_RESOURCE_COMPANY_APPRAISAL_BY_ID, data, { id })
}

export const deleteCompanyAppraisal = (id: number) => {
  return Api.delete(API_RESOURCE_COMPANY_APPRAISAL_BY_ID, {}, { id })
}

export const sortCompanyAppraisal = (data: number[]) => {
  return Api.post(API_RESOURCE_COMPANY_APPRAISAL_BY_ID, data, { id: 'sort' })
}
