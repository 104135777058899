import type { EmailValue } from 'components/EmailTo'
import {
  API_CONSTRUCTION_LEDGER,
  API_LOAN_CREATE_SERVICE_EXTENSION,
  API_LOAN_DOWNLOAD_SERVICING_DOC2,
  API_LOAN_GET_ONE_SERVICE_EXTENSION,
  API_LOAN_GET_SERVICE_EXTENSION,
  API_LOAN_GET_SERVICING_DEFAULT_TRACKING,
  API_LOAN_GET_SERVICING_DEFAULT_TRACKING_LOG,
  API_LOAN_GET_SERVICING_DOCUMENTS_DATA,
  API_LOAN_GET_SERVICING_NOTES,
  API_LOAN_INVOICE,
  API_LOAN_INVOICE_UPDATE,
  API_LOAN_SEND_SERVICING_DOC_EMAIL,
  API_LOAN_SERVICING_PARTICIPATION,
  API_LOAN_TRUST_LEDGER_DRAW_ISSUED_TASK,
  API_LOAN_TRUST_LEDGER_INTEREST_CREATE,
  API_LOAN_TRUST_LEDGER_INTEREST_DELETE,
  API_LOAN_TRUST_LEDGER_INTEREST_UPDATE,
  API_LOAN_TRUST_LEDGER_ORDER_HISTORY,
  API_LOAN_UPDATE_SERVICE_EXTENSION,
  API_NOTE,
  API_NOTE_UPDATE,
  API_TRUST_LEDGER,
  API_TRUST_LEDGER_ADDITIONAL,
  API_TRUST_LEDGER_ADDITIONAL_INFO,
  API_TRUST_LEDGER_NOTIFICATION,
  API_TRUST_LEDGER_STATUS_CHANGE_DRAW_AGREEMENT,
  API_TRUST_LEDGER_VALIDATE_SIGN_SIGNATURE,
  BaseFile,
} from 'config'
import Api from 'services/api'

import { downloadFile } from '../documents'

export const getServicingDefaultTrackingLog = (key: string) => {
  return Api.get(API_LOAN_GET_SERVICING_DEFAULT_TRACKING_LOG, {}, { key })
}

export const getServicingDefaultTracking = () => {
  return Api.get(API_LOAN_GET_SERVICING_DEFAULT_TRACKING)
}

export const postServicingDefaultTracking = (data: Record<string, any>) => {
  return Api.post(API_LOAN_GET_SERVICING_DEFAULT_TRACKING, data)
}

export const getServicingNotes = () => {
  return Api.get(API_LOAN_GET_SERVICING_NOTES)
}

export const postServicingNotes = (data: object) => {
  return Api.post(API_LOAN_GET_SERVICING_NOTES, data)
}

export const getServicingDocumentData = () => {
  return Api.get(API_LOAN_GET_SERVICING_DOCUMENTS_DATA)
}

export const downloadServicingDocs2 = async (name: string) => {
  const response: Blob = await Api.post(
    API_LOAN_DOWNLOAD_SERVICING_DOC2,
    { name },
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(`${name} - ${Api.getLoanNumber()}.docx`, response)
}

export const sendServicingDocumentOnEmail = (
  name: string,
  emailData: Record<string, string>,
  data: Record<string, any>,
) => {
  return Api.post(API_LOAN_SEND_SERVICING_DOC_EMAIL, { name, emailData, data })
}
export const getServicingParticipation = () => {
  return Api.get(API_LOAN_SERVICING_PARTICIPATION)
}

export const saveServicingParticipation = async (loanNumber: number, byteproFileName: number, data: any) => {
  const response: Blob = await Api.post(
    API_LOAN_SERVICING_PARTICIPATION,
    data,
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(`Participation Agreement - ${byteproFileName || loanNumber}.pdf`, response)
}

/**
 * Trust Ledger
 */

export const validateTrustLedgerSignature = (data: object) => {
  return Api.post(API_TRUST_LEDGER_VALIDATE_SIGN_SIGNATURE, data)
}

export const changeTrustLedgerAgreementStatus = (data: object) => {
  return Api.post(API_TRUST_LEDGER_STATUS_CHANGE_DRAW_AGREEMENT, data)
}

export const getTrustLedger = () => {
  return Api.get(API_TRUST_LEDGER)
}

export const getTrustLedgerAdditionalInfo = () => {
  return Api.get(API_TRUST_LEDGER_ADDITIONAL_INFO)
}

export const addTrustLedger = (data: Record<string, any>) => {
  return Api.post(API_TRUST_LEDGER, data)
}

export const updateTrustLedger = (id: number, data: Record<string, any>) => {
  return Api.put(`${API_TRUST_LEDGER}/${id}`, data)
}

export const updateTrustLedgerAdditional = (data: Record<string, any>) => {
  return Api.put(API_TRUST_LEDGER_ADDITIONAL, data)
}

export const getTrustLedgerAdditionalHistory = (key: string) => {
  return Api.get(API_TRUST_LEDGER_ADDITIONAL, { key })
}

export const deleteTrustLedger = (id: number) => {
  return Api.delete(`${API_TRUST_LEDGER}/${id}`)
}

export const getConstructionLedger = () => {
  return Api.get(API_CONSTRUCTION_LEDGER)
}

// Interest Trust Ledger
export const createTrustLedgerInterest = (data: Record<string, any>) => {
  return Api.post(API_LOAN_TRUST_LEDGER_INTEREST_CREATE, data)
}

export const updateTrustLedgerInterest = (id: string, data: Record<string, any>) => {
  return Api.put(API_LOAN_TRUST_LEDGER_INTEREST_UPDATE, data, { id })
}

export const deleteTrustLedgerInterest = (id: string) => {
  return Api.delete(API_LOAN_TRUST_LEDGER_INTEREST_DELETE, {}, { id })
}

export const trustLedgerDrawIssuedAccountingTask = (data: any) => {
  return Api.post(API_LOAN_TRUST_LEDGER_DRAW_ISSUED_TASK, data)
}

export const getTrustLedgerOrderHistory = (id: number) => {
  return Api.get(API_LOAN_TRUST_LEDGER_ORDER_HISTORY, {}, { id })
}

export const sendTrustLedgerNotification = (
  ID: number,
  emails: EmailValue,
  emailType: string,
  files: Record<string, string>[],
  data: any,
) => {
  return Api.post(API_TRUST_LEDGER_NOTIFICATION, { ID, emails, emailType, files, data })
}

/**
 * Loan Service Extension
 */
export const updateLoanServiceExtension = (data: any, id: number) => {
  return Api.post(API_LOAN_UPDATE_SERVICE_EXTENSION, { data }, { id })
}

export const createLoanServiceExtension = (data: any) => {
  return Api.post(API_LOAN_CREATE_SERVICE_EXTENSION, data)
}

export const getLoanServiceExtension = () => {
  return Api.get(API_LOAN_GET_SERVICE_EXTENSION)
}

export const getOneLoanServiceExtension = (id: string) => {
  return Api.get(API_LOAN_GET_ONE_SERVICE_EXTENSION, {}, { id })
}

export const sendEmailForLoanServiceExtension = (id: number) => {
  return Api.put(API_LOAN_UPDATE_SERVICE_EXTENSION, {}, { id })
}

/**
 * Notes
 */
export const getNotes = (filters: Record<string, any>) => {
  return Api.get(API_NOTE, filters)
}

export const addNewNote = (subject: string, content: string, files: BaseFile[] = [], notifiers: EmailValue = {}) => {
  return Api.post(API_NOTE, { subject, content, files, notifiers })
}

export const updateNote = (id: number, subject: string, content: string, files: BaseFile[], notifiers: EmailValue) => {
  return Api.put(API_NOTE_UPDATE, { subject, content, files, notifiers }, { id })
}

export const deleteNote = (id: number) => {
  return Api.delete(API_NOTE_UPDATE, {}, { id })
}

/**
 * Invoices
 */
export const getLoanInvoices = (filters: Record<string, any>) => {
  return Api.get(API_LOAN_INVOICE, filters)
}

export const addNewLoanInvoice = (data: Record<string, any>) => {
  return Api.post(API_LOAN_INVOICE, data)
}

export const updateLoanInvoice = (id: number, data: Record<string, any>) => {
  return Api.put(API_LOAN_INVOICE_UPDATE, data, { id })
}

export const deleteLoanInvoice = (id: number) => {
  return Api.delete(API_LOAN_INVOICE_UPDATE, {}, { id })
}
