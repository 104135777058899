import { DocumentDuplicateIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { noteColors, PaidByInitialMap } from 'config'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { convertNegative2Parentheses, getPrice2decimal } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { HUD1InputType } from '../HUD1Page1/constant'

export function HUD1_900({
  inputs,
  data,
  renderSectionHeader,
  renderHUD1Input,
  onChange,
  onCopyClick,
  calculateValue,
  showHistory,
  setShowMIPFundingModal,
  setShowInterimInterestModal,
  setOVData,
  handleContextMenu,
  notes,
}: {
  inputs: Record<string, any>
  data: Record<string, any>
  renderSectionHeader: Function
  renderHUD1Input: Function
  onChange: Function
  onCopyClick: Function
  calculateValue: Function
  showHistory: Function
  setShowMIPFundingModal: Function
  setShowInterimInterestModal: Function
  setOVData: Function
  handleContextMenu: Function
  notes: Record<string, any>
}) {
  return (
    <>
      {renderSectionHeader('900. Items Required by Lender to Be Paid in Advance')}
      <tr>
        <td className="flex gap-2 p-2">
          {(inputs['fundingDate'] || []).map((input: any) => {
            const { valueKey } = input
            input.value = data[valueKey]
            input.history = true
            input.bgColor = noteColors[notes[valueKey]?.flag]
            return (
              <div
                className={`input flex-1 md:col-span-${input.span || 1}`}
                key={valueKey}
                onContextMenu={(e) => handleContextMenu(e, valueKey, data[valueKey])}
              >
                <RenderInput
                  input={input}
                  Key={valueKey}
                  onChange={onChange}
                  showHistory={() => showHistory([valueKey])}
                />
              </div>
            )
          })}
        </td>
        <td></td>
      </tr>
      <tr>
        <td className="flex gap-2 p-2">
          {inputs['interimInterest']?.map((item: any) => {
            const { key, title, fromKey, toKey } = item
            return (
              <div className="flex flex-wrap justify-end gap-2 items-center">
                <span
                  className="cursor-pointer hover:underline"
                  onClick={() => showHistory([fromKey, toKey].filter((v) => !!v))}
                >
                  {key}.
                </span>
                <div className="flex flex-wrap flex-1 gap-2 items-center">
                  <span
                    className="cursor-pointer hover:text-shade-blue hover:underline"
                    onClick={() => setShowInterimInterestModal(true)}
                  >
                    {title}
                  </span>
                  <span className="ml-6">from</span>
                  <input
                    className={`px-0 border border-gray-200 text-[14px] w-[120px] py-0 placeholder-gray-500 bg-${
                      noteColors[notes[fromKey]?.flag]
                    }`}
                    value={data[fromKey]}
                    type="date"
                    onChange={(e) => onChange(fromKey, e.target.value)}
                    onContextMenu={(e) => handleContextMenu(e, fromKey, data[fromKey])}
                  />
                  {data['Status.SchedFundingDate'] && !data['HUD1.InterimInterestDateFrom'] && (
                    <span className="cursor-pointer hover:text-shade-blue" onClick={() => onCopyClick(fromKey)}>
                      <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
                    </span>
                  )}
                  <span>to</span>
                  <input
                    className={`px-0 border border-gray-200 text-[14px] w-[120px] py-0 placeholder-gray-500 bg-${
                      noteColors[notes[toKey]?.flag]
                    }`}
                    value={data[toKey]}
                    type="date"
                    onChange={(e) => onChange(toKey, e.target.value)}
                    onContextMenu={(e) => handleContextMenu(e, toKey, data[toKey])}
                  />
                  {data['Loan.FirstPaymentDate'] && !data['HUD1.InterimInterestDateTo'] && (
                    <span className="cursor-pointer hover:text-shade-blue" onClick={() => onCopyClick(toKey)}>
                      <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </td>
        <td></td>
      </tr>
      {inputs['interimInterestCalc'] && (
        <tr>
          <td className="flex justify-between items-center gap-2 p-2">
            <div className="flex items-center gap-2">
              {renderHUD1Input(inputs['interimInterestCalc'][0])}
              {data['HUD1.InterimInterestDateFrom'] && data['HUD1.InterimInterestDateTo'] && (
                <span
                  className="cursor-pointer hover:text-shade-blue"
                  onClick={() => onCopyClick('Loan.InterimInterestDays')}
                >
                  <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
                </span>
              )}
              <span>days ×</span>
              {renderHUD1Input(inputs['interimInterestCalc'][1])}
              <span
                className="cursor-pointer hover:text-shade-blue"
                onClick={() =>
                  setOVData({
                    title: 'Days Per Year',
                    overrideKey: 'Loan.InterimIntDaysPerYearOV',
                    calcValue: 360,
                    ovOptions: {
                      0: '',
                      1: 360,
                      2: 365,
                    },
                    ovValue: data['Loan.InterimIntDaysPerYearOV'] || '',
                  })
                }
              >
                <Tooltip message="Days in Year">
                  <InformationCircleIcon className="w-4 h-4"></InformationCircleIcon>
                </Tooltip>
              </span>
              <span>=</span>
              {renderHUD1Input(inputs['interimInterestCalc'][2])}
            </div>
            <div className="flex justify-end gap-1">
              <input
                className="px-0 border border-gray-200 text-[14px] w-[80px] py-0 placeholder-gray-500 text-end"
                value={getPrice2decimal(calculateValue('Loan.interimInterestBorrower'), false, true)}
                disabled={true}
              />
              <span>B</span>
              <div className="flex items-center">{renderHUD1Input(inputs['interimInterestCalc'][3])}</div>
              <span>S</span>
            </div>
          </td>
          <td className="p-2">
            {convertNegative2Parentheses(
              getPrice2decimal(data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest'), false, true),
            )}
          </td>
        </tr>
      )}
      <tr>
        {inputs['902']?.map((item: any) => {
          const { key, title } = item
          return (
            <>
              <td className="p-2">
                <span
                  className="cursor-pointer hover:underline"
                  onClick={() =>
                    showHistory([
                      inputs['902Calc'][0].overrideKey,
                      inputs['902Calc'][1].valueKey,
                      inputs['902Calc'][3].valueKey,
                    ])
                  }
                >
                  {key}
                </span>
                . {title}
              </td>
              <td className="p-2">
                {convertNegative2Parentheses(getPrice2decimal(+data['Loan.MIPFFPaidInCash'], false, true))}
              </td>
            </>
          )
        })}
      </tr>
      {inputs['902Calc'] && (
        <>
          <tr>
            <td className="flex justify-between gap-2 p-2">
              {renderHUD1Input(inputs['902Calc'][0])}
              <span>mo. to</span>
              {renderHUD1Input(inputs['902Calc'][1])}
              <div className="flex gap-1">
                <input className="w-[80px]" disabled />
                <span>B</span>
                {renderHUD1Input(inputs['902Calc'][2])}
                <span>S</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="flex justify-between gap-2 p-2">
              <span
                className="cursor-pointer hover:text-shade-blue hover:underline"
                onClick={() => setShowMIPFundingModal(true)}
              >
                UFMIP Financed
              </span>
              <span>0.00 + Paid in Cash</span>
              <div className="flex gap-1">
                <input
                  className="w-[80px] text-right pr-2"
                  disabled
                  value={getPrice2decimal(
                    data['Loan.MIPFFPaidInCash'] - +data['Loan.MIPFFPaidInCashPBSDesired'],
                    false,
                    true,
                  )}
                />
                <span>B</span>
                {renderHUD1Input(inputs['902Calc'][3])}
                <span>{PaidByInitialMap[data['Loan.MIPFFPaidInCashPaidByOtherType'] || 0]}</span>
              </div>
            </td>
          </tr>
        </>
      )}
      {inputs['903-904']?.map((item: any) => {
        const { key, PremiumOVKey, MonthsInAdvanceKey, feeMultipleKey, PremiumPOCKey } = item
        const input = {
          calculatedValue: getPrice2decimal(+data[MonthsInAdvanceKey] * +data[feeMultipleKey], false, true),
          overrideKey: PremiumOVKey,
          canOverride: true,
          type: HUD1InputType.calculatedInput,
        }
        return (
          <tr>
            <td className="p-2">{renderHUD1Input(item, key)}</td>
            <td>{data[PremiumPOCKey] ? '' : renderHUD1Input(input, PremiumOVKey)}</td>
          </tr>
        )
      })}
    </>
  )
}
