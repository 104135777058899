const moment = require('moment-timezone')
const timezones = moment.tz.names()

const timeZoneMap: Record<string, string> = {}

const timezonesWithOffset = timezones.map((tz: any) => {
  const currentTime = moment.tz(tz)
  const offsetInMinutes = currentTime.utcOffset()
  const offsetInHours = offsetInMinutes / 60

  return {
    timezone: tz,
    offset: offsetInHours,
  }
})

// Sort the array by offset (UTC offset)
timezonesWithOffset.sort((a: any, b: any) => {
  if (a.offset === b.offset) {
    return a.timezone.localeCompare(b.timezone) // Sort alphabetically if offsets are equal
  }
  return a.offset - b.offset // Sort by offset
})

timezonesWithOffset.map((tz: any) => {
  timeZoneMap[tz.timezone] = `${tz.timezone.replaceAll('_', ' ').replace('/', ', ')} (UTC${tz.offset >= 0 ? '+' : ''}${
    tz.offset
  }:00)`
})

export default timeZoneMap
