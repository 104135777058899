import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { loanStatusConstants } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdminConfig, setAdminConfig } from 'services'
import { InputConvert, InputValidate, isEmpty } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { deafultInputsV2 } from './constants'

export const GlobalSettingsV2 = () => {
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  const [inputs, setInputs] = useState(deafultInputsV2(loanGlobalStatus))
  const [action, setAction] = useState('')

  let defaultNonServicingStatus: Record<string, boolean> = {},
    defaultServicingStatus: Record<string, boolean> = {}
  Object.keys(loanGlobalStatus).map((v: string) => {
    defaultNonServicingStatus[v] = false
  })
  Object.keys(loanStatusConstants.servicingStatus).map((v: string) => {
    defaultServicingStatus[v] = false
  })

  const initData = async () => {
    const data = await getAdminConfig('globalSettingsV2')
    const temp = cloneDeep(inputs)
    Object.keys(temp).forEach((key) => {
      temp[key].value = data[key]
    })
    setInputs(temp)
  }
  useEffect(() => {
    initData()
  }, [])
  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)

    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return
    setAction('setting')
    await setAdminConfig('globalSettingsV2', data)
    setAction('')
  }
  return (
    <div className="relative mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
      <LayoutLoading show={!isEmpty(action)} />
      {Object.keys(inputs).map((key, index) => {
        let input = inputs[key]
        if (['nonServicingLoanStatus', 'borrowerLoanStatus'].includes(key))
          input.value = { ...defaultNonServicingStatus, ...input.value }
        if (key == 'servicingLoanStatus') input.value = { ...defaultServicingStatus, ...input.value }

        return (
          <div className={`input md:col-span-${input.span || 1} `} key={index}>
            <RenderInput input={input} Key={key} onChange={onChange} onBlur={() => onSubmit()} />
          </div>
        )
      })}
    </div>
  )
}
