import {
  API_GET_CLOSING_COST_SETUP,
  API_LOAN_ADD_CLOSING_COSTS,
  API_LOAN_CLOSING_SETTLEMENT,
  API_LOAN_DELETE_CLOSING_COST,
  API_LOAN_DOWNLOAD_DISBURSEMENT_ITEMS,
  API_LOAN_DOWNLOAD_FEE_WORKSHEET,
  API_LOAN_DOWNLOAD_HUD1_PDF,
  API_LOAN_DOWNLOAD_NET_FUNDING_SHEET,
  API_LOAN_GET_ELECTIVE_CLOSING_COSTS,
  API_LOAN_GET_HUD1_NOTES,
  API_LOAN_GET_HUD1_PAGE1,
  API_LOAN_GET_LOG_HUD1_PAGE1,
  API_LOAN_PROGRAM_HUD1,
} from 'config'
import Api from 'services/api'

export const gethud1PageData = (page: string) => {
  return Api.get(API_LOAN_GET_HUD1_PAGE1, {}, { page }, { timeout: 150000 })
}

export const posthud1Page1Data = (page: string, data: any) => {
  return Api.post(API_LOAN_GET_HUD1_PAGE1, data, { page })
}

export const getHUD1Logs = (key: string) => {
  return Api.get(API_LOAN_GET_LOG_HUD1_PAGE1, {}, { key })
}

export const posthud1Notes = (key: string, data: Record<string, any>) => {
  return Api.post(API_LOAN_GET_HUD1_NOTES, data, { key })
}

export const getClosingSettlement = () => {
  return Api.get(API_LOAN_CLOSING_SETTLEMENT)
}

export const postClosingSettlement = (data: Record<string, any>) => {
  return Api.post(API_LOAN_CLOSING_SETTLEMENT, data)
}

export const getElectiveClosingCosts = () => {
  return Api.get(API_LOAN_GET_ELECTIVE_CLOSING_COSTS)
}

export const addClosingCosts = (data: Array<any>) => {
  return Api.post(API_LOAN_ADD_CLOSING_COSTS, data)
}

export const getClosingCostSetup = (id: number) => {
  return Api.get(API_GET_CLOSING_COST_SETUP, {}, { id })
}

export const deleteClosingCost = (id: number) => {
  return Api.delete(API_LOAN_DELETE_CLOSING_COST, {}, { id })
}

export const downloadHUD1PDF = (page: string) => {
  return Api.get(API_LOAN_DOWNLOAD_HUD1_PDF, {}, { page }, { responseType: 'blob', timeout: 150000 })
}

export const downloadNetFundingSheet = () => {
  return Api.get(API_LOAN_DOWNLOAD_NET_FUNDING_SHEET, {}, {}, { responseType: 'blob', timeout: 150000 })
}

export const downloadHUD1APDF = (page: string) => {
  return Api.get(API_LOAN_DOWNLOAD_HUD1_PDF, {}, { page }, { responseType: 'blob', timeout: 150000 })
}

export const downloadFeeWorksheet = () => {
  return Api.get(API_LOAN_DOWNLOAD_FEE_WORKSHEET, {}, {}, { responseType: 'blob', timeout: 150000 })
}

export const getLoanProgram = () => {
  return Api.get(API_LOAN_PROGRAM_HUD1)
}

export const exportDisbursementItems = (liabilities: Array<any>) => {
  return Api.post(API_LOAN_DOWNLOAD_DISBURSEMENT_ITEMS, { liabilities }, {}, { responseType: 'blob', timeout: 150000 })
}
