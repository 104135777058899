import { InputType, productTypeOptions } from 'config'

export const condConstant = {
  classes: ['UW', 'PROCESSOR', 'FUNDING', 'CLOSING', 'POST', 'TRAIL', 'QA'].sort(),
  types: [
    'APPLICATION',
    'DISC',
    'CONDO',
    'CRED',
    'INC',
    'ASSET',
    'PROP',
    'MISC',
    'PTD',
    'PTF',
    'FHA',
    'PTD-Internal',
    'INVESTOR SUSPEND',
    'TRAILING DOCS',
    'QA',
    'PTU',
    'PPC',
    'VA',
    'NON QM REFI DEFAULT',
  ].sort(),
  respon: [
    'Closer',
    'COC',
    'Escrow Company',
    'Funder',
    'Loan Officer',
    'Loan Processor',
    'Post Closer',
    'Quality Control',
    'Settlement Company',
    'Title Company',
    'Underwriter',
    'Loan Setup',
  ].sort(),
  ines: ['Yes', 'No'],
}

export const taskPriority: Record<string, string> = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
}

export const categories = [
  'Post Closing Suspense Conditions',
  'Prior To Purchase',
  'PTD',
  'PTD - Internal',
  'PTF',
  'Trailing Docs',
  'Loan Intake',
].sort()

export const taskSecurity: Record<string, string> = {
  high: 'High - Only Task Creator can Modify',
  medium: 'Medium - Assignee can Modify due Date',
  low: 'Low - Anyone can Modify',
}

export const conditionInputs = (): Record<string, InputType> => {
  return {
    name: {
      inputType: 'text',
      title: 'Name',
      value: '',
      error: '',
      required: true,
      key: 'name',
    },
    category: {
      title: 'Category',
      inputType: 'Select',
      options: categories,
      value: '',
      error: '',
      key: 'category',
      required: true,
      hasDefaultOption: true,
    },
    class: {
      title: 'Class',
      inputType: 'Select',
      options: condConstant.classes,
      value: '',
      error: '',
      key: 'class',
      required: true,
      hasDefaultOption: true,
    },
    type: {
      title: 'Type',
      inputType: 'Select',
      options: condConstant.types,
      value: '',
      error: '',
      key: 'type',
      required: true,
      hasDefaultOption: true,
    },
    responsibility: {
      title: 'Responsibility',
      inputType: 'Select',
      options: condConstant.respon,
      value: '',
      error: '',
      key: 'responsibility',
      required: true,
      hasDefaultOption: true,
    },
    intext: {
      title: 'Int/Ext',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'intext',
      required: true,
    },
    description: {
      title: 'UW Description',
      inputType: 'TextArea',
      value: '',
      error: '',
      key: 'description',
      required: true,
      rows: 6,
      length: 1,
      span: 'full',
    },
    brokerDescription: {
      title: 'Broker Description',
      inputType: 'TextArea',
      value: '',
      error: '',
      key: 'description',
      required: true,
      rows: 4,
      length: 1,
      span: 'full',
    },
    borrowerName: {
      inputType: 'text',
      title: 'Name (Borrower Facing)',
      value: '',
      error: '',
      required: false,
      key: 'borrowerName',
      span: 'full',
    },
    borrowerDescription: {
      title: 'Description (Borrower Facing)',
      inputType: 'TextArea',
      value: '',
      error: '',
      key: 'borrowerDescription',
      required: false,
      rows: 4,
      length: 1,
      span: 'full',
    },
    requested: {
      title: 'Requested at Application',
      inputType: 'Check',
      value: false,
      error: '',
      key: 'requested',
      span: 'full',
    },
    products: {
      inputType: 'multiselect',
      title: 'Products',
      options: productTypeOptions,
      key: 'products',
      defaultSelected: true,
    },
    transactions: {
      inputType: 'multiselect',
      title: 'Transactions',
      options: ['Purchase', 'Refinance'],
      key: 'transactions',
      defaultSelected: true,
    },
  }
}

export const nonADHocConditionKeys = ['borrowerName', 'borrowerDescription', 'requested', 'products', 'transactions']

export const taskInputs = () => {
  return {
    description: {
      inputType: 'text',
      title: 'Description',
      value: '',
      error: '',
      required: true,
      key: 'description',
    },
    assignedTo: {
      inputType: 'multiselect',
      title: 'Assigned to',
      options: {},
      defaultSelected: false,
      required: true,
      value: [],
    },
    assignedToManager: {
      inputType: 'multiselect',
      title: 'Assigned to Manager',
      options: {},
      defaultSelected: false,
      value: [],
    },
    dueDays: {
      inputType: 'text',
      title: 'Due days',
      value: '',
      error: '',
      required: true,
      key: 'dueDays',
      type: 'number',
    },
    notes: {
      inputType: 'text',
      title: 'Notes',
      value: '',
      error: '',
      key: 'notes',
      required: true,
    },
  }
}

export const AmountCalculationMap = {
  0: 'Wire Amount',
  1: 'Loan Amount',
}

export const warehouseLenderInputs = () => {
  return {
    code: {
      title: 'Code',
      value: '',
      error: '',
      required: true,
      key: 'code',
    },
    fullName: {
      title: 'Full Name',
      value: '',
      error: '',
      required: false,
      key: 'fullName',
    },
    fannieMaePayeeID: {
      title: 'Fannie Mae Payee ID',
      value: '',
      error: '',
      required: false,
      key: 'fannieMaePayeeID',
    },
    fannieMaeWarehouseID: {
      title: 'Fannie Mae Warehouse ID',
      value: '',
      error: '',
      required: false,
      key: 'fannieMaeWarehouseID',
    },
    freddieMacWarehouseID: {
      title: 'Freddie Mac Warehouse ID',
      value: '',
      error: '',
      required: false,
      key: 'freddieMacWarehouseID',
    },
    advancedAmount: {
      title: 'Advance Amount',
      value: '',
      error: '',
      required: false,
      key: 'advancedAmount',
      type: 'number',
    },
    amountCalculation: {
      title: 'Adv. Amt Calculation',
      inputType: 'Select',
      options: AmountCalculationMap,
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
      key: 'amountCalculation',
    },
    lineBalance: {
      title: 'Line Balance',
      value: '',
      error: '',
      required: false,
      key: 'lineBalance',
      type: 'number',
    },
    accountNumber: {
      title: 'Account Number',
      value: '',
      error: '',
      required: false,
      key: 'accountNumber',
      type: 'number',
    },
    mersOrgID: {
      title: 'MERS Org ID',
      value: '',
      error: '',
      required: false,
      key: 'mersOrgID',
    },
    notes: {
      title: 'Notes',
      value: '',
      error: '',
      key: 'notes',
      inputType: 'textarea',
      row: 5,
      span: 2,
      required: false,
    },
  }
}
