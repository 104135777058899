import { RefundReason } from 'config'
import { StripeStatusLabels } from 'pages/Loan/OrderAppraisals/types'
import { StripeIntentStatus } from 'services'
import { Modal, PlainTable } from 'stories/components'
import { formatTime, getPrice2decimal } from 'utils'

export const StripeHistoryModal = ({
  history,
  email,
  amount,
  createdAt,
  onClose,
}: {
  history: Record<string, any>
  email: string
  amount: number
  createdAt: string
  onClose: Function
}) => {
  return (
    <Modal title="Payment History" isOpen={true} onClose={() => onClose()} titleOkay="" titleCancel="">
      <PlainTable
        header={['No', 'Status', 'Detail', 'Date', 'User']}
        data={[
          ['1', 'Created', '', formatTime(createdAt), email],
          ...(history
            ? history.map((item: any, index: number) => [
                index + 2,
                <span className={`text-${StripeStatusLabels[item.status][0]}-500`}>
                  {StripeStatusLabels[item.status][1]}
                </span>,
                [StripeIntentStatus.refunded, StripeIntentStatus.partially_refunded].includes(item.status) ? (
                  <div>
                    <p>Refund Amount: ${getPrice2decimal(item.amount, false, true)}</p>
                    <p>Refund Reason: {RefundReason[item.reason]}</p>
                    <p>{item.description}</p>
                  </div>
                ) : item.status === StripeIntentStatus.succeeded ? (
                  <p>Amount: ${getPrice2decimal(amount, false, true)}</p>
                ) : (
                  ''
                ),
                formatTime(item.date),
                item.email || email,
              ])
            : []),
        ]}
      />
    </Modal>
  )
}
