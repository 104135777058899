import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { convertNegative2Parentheses, getPrice2decimal } from 'utils'

import { HUD1InputType } from '../HUD1Page1/constant'

export function HUD1_1000({
  inputs,
  data,
  isUpdateMonthReserve,
  renderSectionHeader,
  renderHUD1Input,
  calculateValue,
  onUpdateMonthReserve,
}: {
  inputs: Record<string, any>
  data: Record<string, any>
  isUpdateMonthReserve: boolean
  renderSectionHeader: Function
  renderHUD1Input: Function
  calculateValue: Function
  onUpdateMonthReserve: () => {}
}) {
  return (
    <>
      {renderSectionHeader('1000. Reserves Deposited with Lender')}

      {inputs['1001']?.map((item: any) => {
        const { key, title, valueKey } = item
        return (
          <tr>
            <td className="p-2">
              {key}. {title}
              {isUpdateMonthReserve && (
                <button className="ml-4 bg-gray-100 p-1 shadow-md hover:drop-shadow-md" onClick={onUpdateMonthReserve}>
                  <div className="flex gap-1 items-center">
                    Update Month in Reserves
                    <ExclamationCircleIcon className="w-4 h-4 text-red-700" />
                  </div>
                </button>
              )}
            </td>
            <td className="p-2">
              {convertNegative2Parentheses(getPrice2decimal(calculateValue(valueKey), false, true))}
            </td>
          </tr>
        )
      })}

      {inputs['insurance']?.map((item: any) => {
        const { key, reserveOVKey, monthKey, feeMultipleKey, ReservesPOCKey, feeBKey, feeSKey } = item
        const input = {
          calculatedValue: getPrice2decimal(+data[monthKey] * +data[feeMultipleKey], false, true),
          overrideKey: reserveOVKey,
          ReservesPOCKey,
          feeBKey,
          feeSKey,
          monthKey,
          reserveOVKey,
          feeMultipleKey,
          canOverride: true,
          type: HUD1InputType.calculatedInput,
        }
        return (
          <tr>
            <td className="p-2 flex justify-between gap-1">
              <div className="flex-1">{renderHUD1Input(item, key)}</div>
              {renderHUD1Input(input, reserveOVKey)}
            </td>
          </tr>
        )
      })}

      {inputs['aggregateAdjustment']?.map((item: any) => {
        const { key } = item
        return (
          <tr>
            <td className="p-2">{renderHUD1Input(item, key)}</td>
          </tr>
        )
      })}
    </>
  )
}
