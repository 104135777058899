import { ClockIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { useMemo, useRef } from 'react'
import { toast } from 'react-toastify'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import type { Color } from 'stories/types'

interface SelectProps {
  /**
   * What background color to use
   */
  bgColor?: string
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is readOnly
   */
  readOnly?: boolean
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Id of Input
   */
  id: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: string | undefined
  /**
   * Error of Input
   */
  error?: string
  /**
   * Options of select
   */
  options?: Array<string> | Record<string, string | null> // (Title => key)
  /**
   * Custom class name
   */
  className?: string
  /**
   * Required
   */
  required?: boolean
  /**
   * Has default option - 'SELECT'
   */
  hasDefaultOption?: boolean
  /**
   * Default option text
   */
  defaultOptionText?: string
  /**
   * Optional history handler
   */
  /**
   * Prefix
   */
  prefix?: string
  /**
   * Show History
   */
  history?: boolean
  /**
   * Sort
   */
  sort?: boolean
  /**
   * Optional click handler
   */
  copyable?: boolean

  additionalElements?: JSX.Element | null

  onChange?: (value: any) => void
  onBlur?: () => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const Select2 = ({
  color = 'sky',
  bgColor = '',
  disabled = false,
  readOnly = false,
  tooltip = '',
  id = '',
  title = '',
  name = '',
  value = '',
  error = '',
  options = [],
  className = '',
  prefix = '',
  required = false,
  hasDefaultOption = false,
  defaultOptionText = '- Select -',
  history = false,
  sort = false,
  copyable = false,
  additionalElements = null,
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
  ...props
}: SelectProps) => {
  const classNames = [
    'block',
    'rounded-t',
    'px-2.5',
    'pb-[2px]',
    'pt-[27px]',
    'w-full',
    'text-[15px]',
    'text-gray-900',
    'disabled:opacity-100',
    bgColor ? `bg-${bgColor}` : disabled ? 'bg-gray-100' : 'bg-white',
    readOnly ? 'cursor-not-allowed' : '',
    'border',
    'border-gray-300',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-${color}-600`,
    'peer',
    prefix.length > 0 && 'pl-7',
    error && 'border-rose-700',
    disabled && 'cursor-not-allowed',
  ]
  if (value === null) value = ''

  const renderOptions = useMemo(() => {
    let rlt: any = []
    if (Array.isArray(options)) {
      let _options = options
      if (sort) {
        _options = options.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      }
      _options.map((option) => {
        rlt.push(
          <option key={`${title}-${option}`} value={option}>
            {option}
          </option>,
        )
      })
    }

    if (!Array.isArray(options)) {
      let _options: any = []
      Object.keys(options).map((key) => {
        if (options[key] === null) return
        _options.push({
          key: key,
          value: options[key],
        })
      })
      if (sort) {
        _options = _options.sort(function (a: any, b: any) {
          return a.value.toLowerCase().localeCompare(b.value.toLowerCase())
        })
      }
      _options.map((item: any) => {
        rlt.push(
          <option key={`${title}-${item.key}`} value={item.key}>
            {item.value}
          </option>,
        )
      })
    }
    return rlt
  }, [options])

  const changeSelect = (event: any) => {
    onChange(event.target.value)
    setTimeout(() => {
      if (document.activeElement === inputRef.current) {
        inputRef?.current?.blur()
      }
    }, 250)
  }

  const inputRef = useRef<HTMLSelectElement>(null)

  const copyValue = () => {
    toast('Copied to your Clipboard!', { type: 'info' })
    navigator.clipboard.writeText(value)
  }

  return (
    <div className="input-container">
      <div className={`group relative z-0 w-full group ${className}`}>
        <label
          htmlFor={name}
          className="absolute text-[12px] text-gray-700 top-1.5 border-b z-10 origin-[0] left-2.5 flex gap-2 items-center"
        >
          {title}
          {required && '*'}
          {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
          {additionalElements !== null && additionalElements}
          {history && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
          {copyable && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => copyValue()}>
              <DocumentDuplicateIcon className="h-[14px] w-[14px] text-gray-600 cursor-pointer" aria-hidden="true" />
            </span>
          )}
        </label>
        <select
          id={id}
          className={classNames.join(' ')}
          name={name}
          value={value}
          disabled={disabled || readOnly}
          onChange={changeSelect}
          ref={inputRef}
          onBlur={onBlur}
          required={required}
          {...props}
        >
          {hasDefaultOption && <option value="">{defaultOptionText}</option>}
          {renderOptions}
        </select>
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}
    </div>
  )
}
