import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import type { InputType } from 'config'
import type { FormTableHeader } from 'stories/components'

export const header: FormTableHeader[] = [
  { key: 'accountType', title: 'Account Type' },
  { key: 'institution', title: 'Institution' },
  { key: 'accountNumber', title: 'Account Number' },
  { key: 'balance', title: 'Balance' },
  { key: 'verified', title: 'Verified' },
]

export const header2: FormTableHeader[] = [
  { key: 'liability', title: 'Liability' },
  { key: 'balance', title: 'Unpaid Balance' },
  { key: 'accountType', title: 'Account Type' },
  { key: 'toBePaid', title: 'To Be Paid Off' },
  { key: 'isSubjectProperty', title: 'Is Subject Property?' },
]

export const defaultInputs = (): Record<string, InputType> => {
  return {
    accountType: {
      inputType: 'select',
      options: AssetAccountTypeOptions,
      hasDefaultOption: true,
      title: 'Account Type',
      sort: true,
      required: true,
    },
    institution: {
      inputType: 'text',
      title: 'Institution',
      required: true,
    },
    accountNumber: {
      inputType: 'text',
      title: 'Account Number',
      required: false,
    },
    balance: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Account Balance',
      required: true,
      prefix: '$',
    },
    override: {
      inputType: 'toggle',
      title: 'Override Validation',
    },
  }
}

export const requiredInputs = ['balance']

const accoutTypes = [
  'Revolving',
  'Installment',
  'Mortgage',
  'HELOC',
  'Collections Judgements and Liens',
  'Lease Payments',
  'Open 30-Day',
  'Taxes',
  'Tax Lien',
  'Other',
]

const subjectPropertyAccountTypes = ['Mortgage', 'HELOC']

export const defaultInputs2 = (): Record<string, InputType> => {
  return {
    liability: {
      inputType: 'text',
      title: 'Liability',
      required: true,
    },
    balance: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Unpaid Balance',
      required: true,
      prefix: '$',
    },
    accountType: {
      inputType: 'select',
      options: accoutTypes,
      hasDefaultOption: true,
      title: 'Account Type',
    },
    toBePaid: {
      inputType: 'check',
      title: 'To Be Paid Off?',
      required: true,
    },
    isSubjectProperty: {
      inputType: 'check',
      title: 'Is Subject Property?',
      visible: (inputs: Record<string, InputType>) => subjectPropertyAccountTypes.includes(inputs.accountType.value),
      required: true,
    },
  }
}

export interface IAssetData {
  id: string
  accountType?: number
  institution: string
  accountNumber: string
  balance: number

  mask: string
  name: string
  verified: boolean
  accessToken: string

  // Plaid
  assetReportId: string
  assetReportToken: string
  assetReportFileKey: string

  // Snappt Bank Statement
  bankStatementFileKey: string
  bankStatementData: {
    appSession: Record<string, any>
    applicantId: string | null
    document: Record<string, any> | null
    webhookDocumentData: Record<string, any> | null
    webhookReportData: Record<string, any> | null
  }
  bankStatementReportFileKey: string

  override: boolean

  createdAt: number
  creatorEmail: string
}

export const AssetAccountTypeForPlaid = [5, 2, 1, 6]

export const AssetAccountTypeOptions: Record<number, string> = {
  9: 'Bonds',
  11: 'Bridge Loan Not Deposited',
  5: 'Certificate of Deposit', // Plaid
  2: 'Checking', // Plaid
  21: 'Individual Development Account',
  22: 'Life Insurance Cash Value',
  6: 'Money Market Fund', // Plaid
  7: 'Mutual Funds',
  12: 'Retirement Funds',
  1: 'Savings', // Plaid
  25: 'Stock Options',
  8: 'Stocks',
  14: 'Trust Funds',
  20: 'Gift',
  23: 'Proceeds from Sale/Refi',
}

export const renderAccountName = (asset: IAssetData) => {
  return asset.name ? `${asset.name} (···· ${asset.mask})` : asset.accountNumber || ''
}

export const emailDefaultInputs = (): Record<string, InputType> => {
  return {
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      required: true,
    },
  }
}

const snapptVideoUrl = 'https://vimeo.com/645372425'

export const SnapptVideoLink = (
  <a className="btn-link flex gap-2 items-center !text-sm mt-4" href={snapptVideoUrl} target="_blank">
    How to properly download documents?
    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
  </a>
)

export const PurchaseCreditTypes: Record<string, string> = {
  employerAssistance: 'Employer Assistance',
  rentCredit: 'Rental Credit',
  rebate: 'Rebate',
  relocationFunds: 'Relocation Funds',
  sweatEquity: 'Sweat Equity',
  tradeEquity: 'Trade Equity',
  lotEquity: 'Lot Equity',
  closingCosts: 'Closing Costs',
}
