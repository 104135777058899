import { COMPANY_DNS, EmailValue, TimeZone } from 'config'
import moment from 'moment-timezone'

const parseAddress = require('parse-address-string')

interface HSL {
  h: number
  s: number
  l: number
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getCharFromIndex = (index: number, firstChar = 'A') => {
  return String.fromCharCode(firstChar.charCodeAt(0) + index)
}

export const formatTime = (time: Date | string | number = 'now', format: string = 'MM/DD/YYYY HH:mm') => {
  let rlt = ''
  if (time === 'now') rlt = moment().tz(TimeZone).format(format)
  else rlt = moment(time).tz(TimeZone).format(format)
  if (rlt === 'Invalid date') {
    if (typeof time === 'string' && time.length > 0) {
      rlt = moment(Number(time)).tz(TimeZone).format(format)
    }
    if (rlt === 'Invalid date') rlt = ''
  }
  return rlt
}

export const multiSelectRender = (obj: any) => {
  let rlt = ''
  Object.keys(obj).map((key, index) => {
    rlt += `${index !== 0 && obj[key] && rlt.length ? ', ' : ''}${obj[key] ? key : ''}`
  })
  return rlt
}

export const formatDate = (time: Date | string | number = 'now', dateFormat = 'MM/DD/YYYY') => {
  if (!time) return ''
  if (typeof time == 'number' || (!isNaN(Number(time)) && Number(time) > Math.pow(10, 10)))
    time = new Date(Number(time))

  let rlt = ''
  if (time === 'now') rlt = moment().tz(TimeZone).format(dateFormat)
  else rlt = moment(time).tz(TimeZone).format(dateFormat)
  if (rlt === 'Invalid date') {
    if (typeof time === 'string' && time.length > 0) {
      rlt = moment(Number(time)).tz(TimeZone).format(dateFormat)
    }
    if (rlt === 'Invalid date') rlt = ''
  }
  return rlt
}

export const formatDateYMD = (time: Date | string | number = 'now') => {
  return formatDate(time, 'YYYY-MM-DD')
}

// Return "true" if "Today" <= "date"
export const isBeforeOrEqualToday = (date: Date | string | number) => {
  return moment(formatDateYMD()).tz(TimeZone).isSameOrBefore(moment(date).tz(TimeZone))
}
export const getDaysDiff = (date1: any, date2: any) => {
  return moment(date1).tz(TimeZone).diff(moment(date2).tz(TimeZone), 'days')
}

const removePrefix0 = (val: string) => {
  let rlt = ''
  if (val === '0') return '0'
  for (let i = 0; i < val.length; i += 1) {
    if (val[i] >= '0' && val[i] <= '9') {
      if (rlt.length === 0 && val[i] === '0') {
      } else rlt += val[i]
    }
  }
  return rlt
}

const getOnlyNumber = (val: string) => {
  try {
    let rlt = ''
    let data = val.toString()
    for (let i = 0; i < data.length; i += 1) {
      if (data[i] >= '0' && data[i] <= '9') rlt += data[i]
    }
    return rlt
  } catch {
    return ''
  }
}

export const thousandSeperatorWithPrefix = (param: number, prefix = '$', withoutDecimal = false, decimalCount = 2) => {
  return `${param < 0 ? '-' : ''}${prefix}${thousandSeperator(Math.abs(param), withoutDecimal, decimalCount)}`
}

export const thousandSeperator = (param: any, withoutDecimal = false, decimalCount = 2) => {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value: any = no_decimal.replace(/,/g, '')
    let caret = value.length - 1
    while (caret - 3 > -1) {
      caret -= 3
      value = value.split('')
      value.splice(caret + 1, 0, ',')
      value = value.join('')
    }
    if (decimal !== undefined && !withoutDecimal) {
      decimal = getOnlyNumber(decimal).substring(0, decimalCount)
      value += '.' + decimal
    }
    if (sign === '-') value = '-' + value
    return value
  } catch {
    return ''
  }
}

export const phoneConvertor = (val: any) => {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 10) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length === 10) {
      rlt = `(${rlt.substring(0, 3)}) ${rlt.substring(3, 6)}-${rlt.substring(6, 10)}`
    }
    return rlt
  } catch {
    return ''
  }
}

export const creditScoreConvertor = (val: any) => {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 10) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length > 3) {
      rlt = rlt.substring(0, 3)
    }
    return rlt
  } catch {
    return ''
  }
}

export function ssnConvertor(val: any) {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 9) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length === 9) {
      rlt = `${rlt.substring(0, 3)}-${rlt.substring(3, 5)}-${rlt.substring(5, 9)}`
    }
    return rlt
  } catch {
    return ''
  }
}

function entityTaxIDConvertor(val: any) {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 9) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length === 9) {
      rlt = `${rlt.substring(0, 2)}-${rlt.substring(2, 9)}`
    }
    return rlt
  } catch {
    return ''
  }
}

function countDecimal(param: number | string, decimalStep: number) {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value = no_decimal.replace(/,/g, '')
    if (decimal !== undefined) {
      decimal = getOnlyNumber(decimal).substring(0, decimalStep)
      value += '.' + decimal
    }
    if (sign === '-') value = '-' + value
    return value
  } catch {
    return ''
  }
}

export const InputConvert = function (data: any, value: any) {
  try {
    if (data.type === 'phone') {
      value = phoneConvertor(value)
    }
    if (['number', 'thousandSep', 'thousandSepNoDecimal'].includes(data.type) && data.maxNumberRange !== undefined) {
      if (removeComma(value) > data.maxNumberRange) value = data.maxNumberRange
    }
    if (data.type === 'thousandSep') {
      value = thousandSeperator(value)
    }
    if (data.type === 'thousandSepNoDecimal') {
      value = thousandSeperator(value, true)
    }
    if (data.type === 'ssn') {
      value = ssnConvertor(value)
    }
    // if (data.length) {
    //   value = `${value}`.substring(0, data.length)
    // }
    if (data.maxLength) {
      value = `${value}`.substring(0, data.maxLength)
    }
    if (data.type === 'number' && data.decimalStep) {
      value = countDecimal(value, data.decimalStep)
    }
    if (data.type === 'creditScore') {
      value = creditScoreConvertor(value)
    }
    if (data.type === 'entityTaxID') {
      value = entityTaxIDConvertor(value)
    }
    if (data.type === 'email') {
      value = value.toLowerCase()
    }
    if (data.type === 'date') {
      if (value === undefined || value === null) value = ''
      else value = formatDateYMD(value)
    }
    if (data.inputType === 'text') {
      let { title = '' } = data
      title = title.replace(/\s/g, '').toLowerCase()
      const nameTitles = ['firstname', 'middlename', 'lastname', 'fullname']
      for (const nameTitle of nameTitles) {
        if (title.includes(nameTitle)) {
          value = capitalize(value)
          break
        }
      }
    }
  } catch {}
  return value
}

export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["([{])+\S/g, (match) => match.toUpperCase())

export const sortByKey = (arr: Array<any>, key: string, sortOrder: 1 | -1 = 1) => {
  return arr.sort((a: any, b: any) => {
    const valueA = (a[key] || '').toString().toUpperCase() // ignore upper and lowercase
    const valueB = (b[key] || '').toString().toUpperCase() // ignore upper and lowercase
    if (valueA < valueB) return -sortOrder
    if (valueA > valueB) return sortOrder

    return 0
  })
}

export function getPrice3decimal(param: string | number, withoutDecimal = false, mustdecimal = false) {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value = no_decimal.replace(/,/g, '')
    let caret = value.length - 1
    while (caret - 3 > -1) {
      caret -= 3
      const values = value.split('')
      values.splice(caret + 1, 0, ',')
      value = values.join('')
    }
    if (decimal !== undefined && !withoutDecimal) {
      decimal = getOnlyNumber(decimal).substring(0, 3)
      if (decimal.length === 1 && mustdecimal) decimal += '00'
      if (decimal.length === 2 && mustdecimal) decimal += '0'
      if (decimal !== '000' || mustdecimal) value += '.' + decimal
    }
    if (decimal === undefined && mustdecimal) {
      value += '.000'
    }
    if (sign === '-') value = '-' + value
    return value
  } catch {
    return ''
  }
}

export const removeComma = function (val: number | string) {
  try {
    val = val.toString()
    let sign = val[0]
    let rlt = ''
    for (let i = 0; i < val.length; i += 1) {
      if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      if (val[i] === '.') rlt += val[i]
    }
    let nRlt = Number(rlt)
    if (isNaN(nRlt)) nRlt = 0
    if (sign === '-') nRlt *= -1
    return nRlt
  } catch {
    return 0
  }
}

export function getPrice2decimal(
  param: number | string,
  withoutDecimal = false,
  mustdecimal = false,
  withDollar = false,
) {
  try {
    param = param.toString()
    if (param == '') return ''
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value = no_decimal.replace(/,/g, '')
    let caret = value.length - 1
    while (caret - 3 > -1) {
      caret -= 3
      const values = value.split('')
      values.splice(caret + 1, 0, ',')
      value = values.join('')
    }
    if (decimal !== undefined && !withoutDecimal) {
      decimal = getOnlyNumber(decimal).substring(0, 2)
      if (decimal.length === 1) decimal += '0'
      if (decimal !== '00' || mustdecimal) value += '.' + decimal
    }
    if (decimal === undefined && mustdecimal) {
      value += '.00'
    }
    if (withDollar) value = '$' + value
    if (sign === '-') value = '-' + value
    if (value == '.00') value = '0.00'
    return value
  } catch {
    return ''
  }
}

export function getPrice1or2decimal(
  param: number | string,
  withoutDecimal = false,
  mustdecimal = false,
  withDollar = false,
) {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value = no_decimal.replace(/,/g, '')
    let caret = value.length - 1
    while (caret - 3 > -1) {
      caret -= 3
      const values = value.split('')
      values.splice(caret + 1, 0, ',')
      value = values.join('')
    }
    if (decimal !== undefined && !withoutDecimal) {
      decimal = getOnlyNumber(decimal).substring(0, 2)
      if (decimal !== '00' || mustdecimal) value += '.' + decimal
    }
    if (decimal === undefined && mustdecimal) {
      value += '.00'
    }
    if (withDollar) value = '$' + value
    if (sign === '-') value = '-' + value
    if (value == '.00') value = '0.00'
    return value
  } catch {
    return ''
  }
}

export const fileTobase64 = function (file: File, withExtention = false) {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader()

    reader.onload = async () => {
      resolve({
        name: withExtention ? file.name : file.name.replace('.pdf', ''),
        base64: reader.result,
      })
    }

    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}

export const blobToBase64 = function (blob: any) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export function getItemsFromFullAddress(fullAddress: string): Promise<{
  street_address1: string
  city: string
  state: string
  postal_code: string
  country?: string
}> {
  return new Promise((resolve, reject) => {
    parseAddress(fullAddress, (err: any, addressObj: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(addressObj)
      }
    })
  })
}

export function validateGoogleAddress(fullAddress: string): Promise<any> {
  return new Promise(async (resolve) => {
    let rlt = {
      street_address1: '',
      city: '',
      state: '',
      postal_code: '',
    }
    try {
      const res = (await getItemsFromFullAddress(fullAddress)) as any
      Object.keys(rlt).map((key) => {
        if (res[key] !== undefined && res[key] !== null) (rlt as any)[key] = res[key]
        return true
      })
      resolve(rlt)
    } catch {
      resolve(rlt)
    }
  })
}

export function borrowerFullName(borrower: Record<string, string> | any) {
  return [borrower.firstName, borrower.middleName, borrower.lastName].join(' ').trim().replace('  ', ' ')
}

export const dayToMSec = (days: number) => {
  return days * 24 * 60 * 60 * 1000
}

export const getUniqueFileName = (fileName: string, fileNames: string[]) => {
  if (!fileNames.includes(fileName)) return fileName

  let newFilename = fileName
  if (!fileName.match(/ \((\d){1,}\)./)) newFilename = fileName.replace('.', ' (1).')

  let index = 1
  while (true) {
    newFilename = newFilename.replace(/ \((\d){1,}\)./, ` (${index}).`)
    if (!fileNames.includes(newFilename)) return newFilename
    index += 1
    if (index >= 1000) break
  }

  return fileName.replace('.', ` (${Date.now()}).`)
}

export const solveDecimalJavascriptSum = (list: Array<number>, positions: number = 3) => {
  const factor = Math.pow(10, positions)
  let rlt = 0
  list.map((item) => {
    rlt += Number(item.toFixed(positions)) * factor
  })
  return Number((rlt / factor).toFixed(positions))
}

export const filterObject = (values: Record<string, boolean>) => {
  return Object.keys(values).filter((key) => values[key])
}

export const serialize = (obj: Record<string, any>, prefix = ''): string => {
  var str = [],
    p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p]
      str.push(
        v !== null && typeof v === 'object' ? serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      )
    }
  }
  return str.join('&')
}

export const getFileName = (fileKey: string) => {
  let rlt = fileKey.replace(/^.*[\\\/]/, '')
  try {
    const params = rlt.split('-')
    const len = params.length
    if (len) {
      let val = params[len - 1]
      const exts = val.split('.')
      if (exts.length === 2) val = exts[0]
      rlt = rlt.replace(`-${val}`, '')
    }
  } catch {}
  return rlt
}

// Convert from HH:MM to HH:MM AM
export const convertTo12HoursMode = (time: string) => {
  const timeStr = time.split(':')

  let symbol = ' AM'

  if (Number(timeStr[0]) === 12) symbol = ' PM'
  else if (Number(timeStr[0]) > 12) {
    timeStr[0] = String(Number(timeStr[0]) - 12)
    symbol = ' PM'
  }

  return timeStr[0] + ':' + timeStr[1] + symbol
}

export const fromToNumberOptions = (start: number, end: number) => {
  let rlt: any = {}
  for (let i = start; i <= end; i += 1) {
    rlt[i] = i.toString()
  }
  return rlt
}

export const hasHtmlTag = (html: string) => {
  return html.match(/<\w*((\/\>)|>)/)
}

export const replaceNextLineToBr = (text: string) => {
  return text
    .replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0') // Replace tabs with 4 non-breaking spaces
    .replace(/\n/g, '<br/>') // Convert line breaks to HTML <br> tags
}

export const emailObj2Txt = (value: string | Record<string, string>, currentEmail: string) => {
  console.log('value', value)
  if (!value) value = {}

  if (typeof value == 'string' && value.startsWith('{')) value = JSON.parse(value)

  if (typeof value == 'string') return `to: ${value}`

  let from = ''

  const map: Record<string, string[]> = {
    to: [],
    cc: [],
    bcc: [],
  }

  Object.keys(value).forEach((email) => {
    const type = (value as any)[email]
    console.log('type', type)
    if (type === 'self') {
      from = `from: ${currentEmail}`
    } else if (map[type]) {
      map[type].push(email)
    }
  })

  const result = Object.keys(map)
    .map((type) => {
      if (!map[type].length) return ''
      return `${type}: ${map[type].join(', ')}`
    })
    .filter((v) => !!v)
    .join('  ')

  return from ? `${from}  ${result}` : result
}

export const getYearOptions = (years: number, needAll = true) => {
  const year = new Date().getFullYear() - years + 1
  const results: string[] = []
  if (needAll) results.push('All')
  for (let i = year + years - 1; i >= year; i--) results.push(`${i}`)
  return results
}

export const removeDuplicates = (values: string[]) => {
  const data: Record<string, boolean> = {}
  values.forEach((value) => (data[value] = true))

  return Object.keys(data)
}

export const generateOptions = (options: string[] | Record<string, any>, defaultValue = true) => {
  let keys: string[] = []
  if (Array.isArray(options)) keys = options
  else keys = Object.keys(options)

  const objOptions: Record<string, boolean> = {}
  keys.forEach((key) => (objOptions[key] = defaultValue))

  return objOptions
}

export const isBorrowerUrl = (pathname: string) => {
  return pathname.includes('customSignApplication') || pathname.includes('borrowerPipeline')
}

export function hasDuplicatedData(arr1: any[], arr2: any[]) {
  const duplicates = arr1.filter((str) => arr2.includes(str))

  return duplicates.length > 0
}

export function hasFileExt(str: string) {
  return !!str.match(/\.(\w){3}$/)
}

export const onSplitText = (data: string, step = 100) => {
  const arr = data.split('.')
  const rlt: string[] = []
  arr.forEach((item) => {
    rlt.push(insertBrEveryNChars(item, step))
  })
  return rlt.join('.<br/>')
}

export const insertBrEveryNChars = (str: string, n = 100) => {
  let words = str.split(' ') // Split the string into words
  let result = []
  let currentLength = 0 // Track the length of the current line

  for (let i = 0; i < words.length; i++) {
    let word = words[i]

    // If adding the next word exceeds the limit, insert a <br/> tag
    if (currentLength + word.length + 1 > n) {
      // +1 for the space
      result.push('<br/>')
      currentLength = 0 // Reset current length after inserting <br/>
    }

    // Add the word to the result and update the current length
    result.push(word)
    currentLength += word.length + 1 // +1 for the space
  }

  // Join the words with spaces and return the result
  return result.join(' ')
}

export const calculateDueDate = (closingDate: string, dueInMonths: number = 0): string => {
  // Parse the closing date string into a Date object
  const closing = new Date(closingDate)

  // Add the dueInMonths to the month component of the closing date
  const dueYear = closing.getFullYear() + Math.floor(dueInMonths / 12)
  let dueMonth = closing.getMonth() + (dueInMonths % 12)
  let dueDay = 1

  // Create the due date object
  const dueDate = new Date(dueYear, dueMonth, dueDay)

  // Since months are 0-based in JavaScript Date, adjust for month overflow

  return dueDate.toString()
}

export const capitalizeLettersSeparatedBySlash = (string: string) => {
  const params = string.split('_')
  return params.map((param) => param.charAt(0).toUpperCase() + param.slice(1)).join(' ')
}

export const multi2Text = (values: any, options: any) => {
  let rlt = ``,
    pos = 0
  try {
    Object.keys(options).map((key) => {
      if (values[key] && options[key]) {
        if (pos > 0) rlt += `, `
        rlt += options[key]
        pos += 1
      }
    })
  } catch {}
  return rlt
}

export const getFirstDayofNextMonth = (dateString: string) => {
  const date = new Date(dateString)

  // Get the year and month of the current date
  let year = date.getFullYear()
  let month = date.getMonth() + 1 // getMonth() is zero-indexed, so we add 1

  // If it's December (month 12), we need to move to January of the next year
  if (month === 12) {
    year += 1
    month = 1
  } else {
    month += 1
  }

  // Get the first day of the next month
  const firstDayOfNextMonth = new Date(year, month - 1, 1)
  return firstDayOfNextMonth.toISOString().split('T')[0]
}

export const convertNegative2Parentheses = (value: any) => {
  if (!value) return ''
  if (value?.toString().startsWith('-')) return `(${value?.toString().slice(1)})`
  return value
}

export const convertParentheses2Negative = (value: any) => {
  if (!value) return ''
  if (value?.toString().startsWith('(') && value?.toString().endsWith(')')) return `-${value?.toString().slice(1, -1)}`
  return value
}

export const getNewSelectionOptions = (
  inputOptions: string[] | Record<string, string>,
  configOptions: Record<string, string>,
  visibility: Record<string, boolean>,
) => {
  let newOptions: Record<string, string> = {}
  if (Array.isArray(inputOptions)) {
    inputOptions.forEach((option) => {
      newOptions[option] = option
    })
  } else newOptions = inputOptions

  Object.keys(configOptions).forEach((key) => {
    newOptions[key] = configOptions[key]
  })

  Object.keys(visibility || {}).forEach((key) => {
    if (visibility[key] === false) delete newOptions[key]
  })

  return newOptions
}

export const hexToHSL = (hex: string): HSL => {
  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16) / 255
  let g = parseInt(hex.slice(3, 5), 16) / 255
  let b = parseInt(hex.slice(5, 7), 16) / 255

  // Get max and min values for chroma
  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)
  let h = 0,
    s = 0,
    l = 0
  let d = max - min

  // Calculate lightness
  l = (max + min) / 2

  if (d === 0) {
    h = 0 // Achromatic
    s = 0
  } else {
    s = d / (1 - Math.abs(2 * l - 1))
    switch (max) {
      case r:
        h = ((g - b) / d) % 6
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h = Math.round(h * 60)
    if (h < 0) h += 360
  }

  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return { h, s, l }
}
// Function to convert HSL to hex
export const hslToHex = (h: number, s: number, l: number): string => {
  s /= 100
  l /= 100

  let c = (1 - Math.abs(2 * l - 1)) * s
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  let m = l - c / 2
  let r = 0,
    g = 0,
    b = 0

  if (0 <= h && h < 60) {
    r = c
    g = x
    b = 0
  } else if (60 <= h && h < 120) {
    r = x
    g = c
    b = 0
  } else if (120 <= h && h < 180) {
    r = 0
    g = c
    b = x
  } else if (180 <= h && h < 240) {
    r = 0
    g = x
    b = c
  } else if (240 <= h && h < 300) {
    r = x
    g = 0
    b = c
  } else if (300 <= h && h < 360) {
    r = c
    g = 0
    b = x
  }

  let R = Math.round((r + m) * 255)
    .toString(16)
    .padStart(2, '0')
  let G = Math.round((g + m) * 255)
    .toString(16)
    .padStart(2, '0')
  let B = Math.round((b + m) * 255)
    .toString(16)
    .padStart(2, '0')

  return `#${R}${G}${B}`
}
// Function to deepen color by reducing lightness
export const deepenColor = (hex: string, amount: number) => {
  let hsl = hexToHSL(hex)
  hsl.l = Math.max(0, hsl.l - amount) // Reduce lightness to darken
  return hslToHex(hsl.h, hsl.s, hsl.l)
}
export const convertToRoman = (num: number) => {
  const romanNumerals = [
    { value: 1000, numeral: 'M' },
    { value: 900, numeral: 'CM' },
    { value: 500, numeral: 'D' },
    { value: 400, numeral: 'CD' },
    { value: 100, numeral: 'C' },
    { value: 90, numeral: 'XC' },
    { value: 50, numeral: 'L' },
    { value: 40, numeral: 'XL' },
    { value: 10, numeral: 'X' },
    { value: 9, numeral: 'IX' },
    { value: 5, numeral: 'V' },
    { value: 4, numeral: 'IV' },
    { value: 1, numeral: 'I' },
  ]

  let result = ''

  for (let i = 0; i < romanNumerals.length; i++) {
    while (num >= romanNumerals[i].value) {
      result += romanNumerals[i].numeral
      num -= romanNumerals[i].value
    }
  }

  return result
}

export const convert2EmailMap = (
  value: string | EmailValue,
  selfEmail = '',
): Record<'to' | 'cc' | 'bcc', string[]> & { text: string; from: string } => {
  if (typeof value === 'string') {
    if (!value.startsWith('{')) return { from: '', to: [value], cc: [], bcc: [], text: '' }
    value = JSON.parse(value) as EmailValue
  }

  const isCompanyEmail = selfEmail.endsWith(`@${COMPANY_DNS}`)
  const isUseSelfEmail = isCompanyEmail && ((value as any).from === undefined || (value as any).from === 'self')
  const emailFrom = isUseSelfEmail ? selfEmail : ''
  const data: any = {
    from: emailFrom,
    to: [],
    cc: [],
    bcc: [],
    text: '',
  }
  Object.keys(value).forEach((email) => {
    if ((value as EmailValue)[email] == 'to') data.to.push(email)
    if ((value as EmailValue)[email] == 'cc') data.cc.push(email)
    if ((value as EmailValue)[email] == 'bcc') data.bcc.push(email)
  })
  data.text = `From: ${isUseSelfEmail ? selfEmail : 'Default'}, To: ${data.to.join(', ')}${
    data.cc.length ? `, Cc: ${data.cc.join(', ')}` : ''
  }${data.bcc.length ? `, Bcc: ${data.bcc.join(', ')}` : ''}`
  return data
}

export function parseFullName(fullName: string) {
  // Split the full name into an array of words (space separated)
  const nameParts = fullName.trim().split(' ')

  // Handle the case where the name might have more than two parts (e.g., middle name, etc.)
  const firstName = nameParts[0]
  const lastName = nameParts[nameParts.length - 1]

  // If there are more than two parts, consider the middle name(s)
  const middleName = nameParts.length > 2 ? nameParts.slice(1, -1).join(' ') : ''

  return {
    firstName: firstName,
    lastName: lastName,
    middleName: middleName,
  }
}
