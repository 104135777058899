import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getPermissionDetails, updatePermissions } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Button, Checkbox } from 'stories/components'

export function NewPermissions() {
  const [isLoading, setIsLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const [roles, setRoles] = useState<Record<number, string>>({})
  const [permissionRoles, setPermissionRoles] = useState<Record<number, number[]>>({})
  const [permissionNames, setPermissionNames] = useState<Record<string, string>>({})
  const [editID, setEditID] = useState(-1)
  const [editRoles, setEditRoles] = useState<any>([])
  const [action, setAction] = useState('')

  const initData = async () => {
    setIsLoading(true)
    const res = await getPermissionDetails()
    setIsLoading(false)
    if (res.success) {
      setPermissions(res.data.permissions)
      setRoles(res.data.roles)
      setPermissionRoles(res.data.permissionRoles)
      setPermissionNames(res.data.permissionNames)
    }
  }

  const mapRoles = useMemo(() => {
    let rlt: Record<string, number> = {}
    Object.keys(roles).map((id) => {
      rlt[roles[Number(id)]] = Number(id)
    })
    return rlt
  }, [roles])

  useEffect(() => {
    initData()
  }, [])

  const onChangePermission = (key: string, value: boolean) => {
    const roleID = mapRoles[key]
    let temp = cloneDeep(editRoles)
    if (value) {
      temp.push(roleID)
    } else {
      const index = editRoles.findIndex((id: number) => id === roleID)
      temp.splice(index, 1)
    }
    setEditRoles(temp)
  }

  const onSaveChanges = async () => {
    setIsLoading(true)
    setAction('save')
    const json = {
      permissionID: editID,
      roleIDs: editRoles,
    }
    await updatePermissions(json)
    let temp = cloneDeep(permissionRoles)
    temp[editID] = editRoles
    setPermissionRoles(temp)
    setIsLoading(false)
    setEditID(-1)
    setAction('')
  }

  const onSaveCancel = () => {
    setEditID(-1)
  }

  const renderAccountTypes = (permissionID: string) => {
    const data: any = permissionRoles[Number(permissionID)]
    if (!data) return null
    let accounts: any = []
    Object.keys(accountTypes).map((key) => {
      if (data.includes(mapRoles[key])) accounts.push(accountTypes[key])
    })
    if (Number(permissionID) === editID) {
      return (
        <div className="mb-6 bg-stone-100 p-4 rounded">
          <div className="flex flex-wrap gap-2">
            {Object.keys(accountTypes).map((key) => {
              const selected = editRoles.includes(mapRoles[key])
              return (
                <div className="w-[175px]" key={key}>
                  <Checkbox
                    id={key}
                    title={accountTypes[key]}
                    key={key}
                    value={selected}
                    onChange={(value) => onChangePermission(key, value)}
                  />
                </div>
              )
            })}
          </div>
          <div className="flex flex-wrap gap-6 justify-center mt-4">
            <Button onClick={onSaveChanges} className="py-[3px]" loading={action === 'save'}>
              Save Changes
            </Button>
            <Button link onClick={onSaveCancel} className="py-[3px]">
              <span className="text-red-500">Cancel</span>
            </Button>
          </div>
        </div>
      )
    }
    return (
      <div className="flex flex-wrap gap-2">
        {accounts.map((item: string, index: number) => {
          return (
            <div className="italic border p-1 rounded w-[175px] text-center text-[13.5px]" key={index}>
              {item}
            </div>
          )
        })}
      </div>
    )
  }

  const editPermission = (permissionID: string) => {
    if (editID !== -1) {
      return toast('You are editing the permission!', { type: 'error' })
    }
    setEditID(Number(permissionID))
    setEditRoles(permissionRoles[Number(permissionID)])
  }

  return (
    <div className="newPermissions-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold">
        Manage Permissions
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="mt-4">
        {Object.keys(permissions).map((key, index) => {
          const name = permissions[key]
          return (
            <div className="mb-5" key={index}>
              <div
                className="group border-b flex flex-wrap gap-2 cursor-pointer hover:text-shade-blue"
                onClick={() => editPermission(key)}
              >
                <div className="text-[14px] font-semibold">
                  <span>
                    {index + 1}. {permissionNames[name] || name}
                  </span>
                </div>
                <div className="hidden group-hover:inline">
                  <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                </div>
              </div>
              <div className="mt-2 px-2">{renderAccountTypes(key)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
