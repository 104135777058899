export const PaidByMap: Record<string, string> = {
  0: 'Seller',
  1: 'Lender',
  2: 'Third Party',
  3: 'Builder (Other than Seller)',
  4: 'Employer',
  5: 'Federal Agency',
  6: 'Local Agency',
  7: 'Non Parent Relative',
  8: 'Parent',
  9: 'Real Estate Agent',
  10: 'State Agency',
  11: 'Unrelated Friend',
}

export const TridBlockMap: Record<string, string> = {
  1: 'Origination charges',
  2: 'Services You Cannot Shop For',
  3: 'Services You Can Shop For',
  4: 'Recording Fees And Other Taxes',
  5: 'Transfer Taxes',
  6: 'Other',
  7: 'Post Closing',
  99: 'CD Only',
}

export const GFEBlockMap: Record<string, string> = {
  1: '1. Our origination charge',
  2: '2. Your credit or charge for the interest rate chosen',
  3: '3. Required services that we select',
  4: `4. Title services and lender's title insurance`,
  5: `5. Owner's title insurance`,
  6: '6. Required services that you can shop for',
  7: '7. Government recording charges',
  8: '8. Transfer taxes',
  99: 'None',
}

export const PaidToMap: Record<string, string> = {
  1: 'Lender',
  2: 'Broker',
  3: 'Investor',
  4: 'Other',
  5: 'Affiliate of Lender',
  6: 'Affiliate of Broker',
}

export const ResponsiblePartyMap: Record<string, string> = {
  0: 'Buyer',
  1: 'Seller',
}

export const ClosingCostsMap: Record<string, string> = {
  0: 'Not Assigned',
  1: '_203K Discount On Repairs',
  2: '_203K Permits',
  3: '_203K Architectural And Engineering Fee',
  4: '_203K Inspection Fee',
  5: '_203K Supplemental Origination Fee',
  6: '_203K Consultant Fee',
  7: '_203K Title Update',
  8: 'Abstract Or Title Search Fee',
  9: 'Amortization Fee',
  10: 'Application Fee',
  11: 'Appraisal Fee',
  12: 'Assignment Fee',
  13: 'Assignment Recording Fee',
  14: 'Assumption Fee',
  15: 'Attorney Fee',
  16: 'Bond Review Fee',
  17: 'City County Deed Tax Stamp Fee',
  18: 'City County Mortgage Tax Stamp Fee',
  19: 'CLO Access Fee',
  20: 'Commitment Fee',
  21: 'Copy Fax Fee',
  22: 'Courier Fee',
  23: 'Credit Report Fee',
  24: 'Deed Recording Fee',
  25: 'Document Preparation Fee',
  26: 'Documentary Stamp Fee',
  27: 'Escrow Waiver Fee',
  28: 'Flood Certification',
  29: 'General Counsel Fee',
  30: 'Inspection Fee',
  31: 'Loan Discount Points',
  32: 'Loan Origination Fee',
  33: 'Modification Fee',
  34: 'Mortgage Broker Fee',
  35: 'Mortgage Recording Fee',
  36: 'Municipal Lien Certificate Fee',
  37: 'Municipal Lien Certificate Recording Fee',
  38: 'New Loan Administration Fee',
  39: 'Notary Fee',
  40: 'Other',
  41: 'Pest Inspection Fee',
  42: 'Processing Fee',
  43: 'Redraw Fee',
  44: 'Real Estate Commission',
  45: 'Reinspection Fee',
  46: 'Release Recording Fee',
  47: 'Rural Housing Fee',
  48: 'Settlement Or Closing Fee',
  49: 'State Deed Tax Stamp Fee',
  50: 'State Mortgage Tax Stamp Fee',
  51: 'Survey Fee',
  52: 'Tax Related Service Fee',
  53: 'Title Examination Fee',
  54: 'Title Insurance Binder Fee',
  55: 'Title Insurance Fee',
  56: 'Underwriting Fee',
  57: 'Appraisal Desk Review Fee',
  58: 'Appraisal Field Review Fee',
  59: 'Assignment Endorsement Fee',
  60: 'Attorney Fee Other',
  61: 'Bankruptcy Monitoring Fee',
  62: 'Bond Fee',
  63: 'Certification Fee',
  64: 'Closing Protection Letter Fee',
  65: 'Compliance Audit Quality Control Fee',
  66: 'Debt Cancellation Fee',
  67: 'Electronic Document Delivery Fee',
  68: 'Escrow Service Fee',
  69: 'Flood Certification Initial Fee',
  70: 'Flood Certification Life Of Loan Fee',
  71: 'Funding Wire Or Disbursement Fee',
  72: 'HELOC Annual Fee',
  73: 'Inspection Fee Post Closing',
  74: 'Inspection Fee Prior To Closing',
  75: 'Intangible Tax',
  76: 'MERS Registration Fee',
  77: 'Mortgage Insurance Application Fee',
  78: 'Payoff Request Fee',
  79: 'Rate Lock Fee',
  80: 'Reconveyance Fee',
  81: 'Recording Service Fee',
  82: 'Signing Agent Fee',
  83: 'Subescrow Fee',
  84: 'Subordination Fee',
  85: 'Subordination Recording Fee',
  86: 'Tie In Fee',
  87: 'Title Courier Fee',
  88: 'Title Document Preparation Fee',
  89: 'Title Endorsement Fee',
  90: 'Warehousing Fee',
  91: 'Wire Transfer Fee',
  92: 'Accident Insurance Premium',
  93: 'Credit Life Insurance Premium',
  94: 'Credit Property Insurance Premium',
  95: 'Health Insurance Premium',
  96: 'High Cost Mortgage Pre Loan Counseling Fee',
  97: 'LLPA Fee',
  98: 'Loss Of Income Insurance Premium',
  99: 'Prepayment Penalty',
  100: 'Real Estate Commission Buyers Broker',
  101: 'Real Estate Commission Sellers Broker',
}

export const DisbursementSchedMap: Record<string, string> = {
  0: '',
  1: 'Not Escrowed',
  2: 'Monthly',
  3: 'Quarterly',
  4: 'Semi Annually',
  5: 'Annually',
  6: 'Other',
  7: 'List',
}

export const DisbursementSchedUnitMap: Record<string, string> = {
  0: '',
  1: 'N/E',
  2: 'Mthly',
  3: 'Qy',
  4: 'S',
  5: 'A',
  6: 'Other',
  7: 'List',
}

export const DisbursementStartPurMap: Record<string, string> = {
  0: '',
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
  13: 'Closing Date',
}

export const MISMOPrepaidItemType: Record<string, string> = {
  0: ' ',
  1: 'Assessment',
  2: 'City Property Tax',
  3: 'County Property Tax',
  4: 'Earthquake Insurance',
  5: 'Flood Insurance',
  6: 'Hazard Insurance',
  7: 'Mortgage Insurance',
  8: 'Other',
  9: 'School Property Tax',
  10: 'Town Property Tax',
  11: 'Village Property Tax',
  12: 'Windstorm Insurance',
}

export const PointsAndFeesMap: Record<string, string> = {
  0: 'Default',
  1: 'Include Borrower Amount',
  2: 'Include Borrower And Seller Amount',
  3: 'Include Total Amount',
  4: 'Exclude Total Amount',
}

export const ClosingCostStatus: Record<string, string> = {
  0: 'Active',
  1: 'Elective',
  2: 'Disabled',
}

export const ClosingCostApplyType: Record<string, string> = {
  0: 'New Files Only',
  1: 'Undelivered Files',
}

export const PrepaidItemDescMap: Record<number, string> = {
  0: 'Haz Ins',
  1: 'Mtg Ins',
  2: 'School Tax',
  3: 'Prop Tx',
  4: 'HOD Payment',
  5: 'Flood Ins',
  6: 'Other Tax',
}

export const PaidByInitialMap: any = {
  0: 'S',
  1: 'L',
  2: 'T',
  3: 'B',
  4: 'E',
  5: 'A',
  6: 'A',
  7: 'N',
  8: 'P',
  9: 'A',
  10: 'A',
  11: 'U',
}

export const PaidToInitialMap: Record<string, string> = {
  1: 'L',
  2: 'B',
  3: 'I',
  4: 'O',
  5: 'A',
  6: 'A',
}

export const FollowupFlagMap: any = {
  0: '',
  1: 'Red Flag',
  2: 'Blue Flag',
  3: 'Yellow Flag',
  4: 'Green Flag',
  5: 'Orange Flag',
  6: 'Purple Flag',
}

export const noteColors: any = {
  0: '',
  1: 'red-100',
  2: 'blue-100',
  3: 'yellow-100',
  4: 'green-100',
  5: 'orange-100',
  6: 'purple-100',
}
