import { ArrowRightOnRectangleIcon, ClockIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { RefundModal } from 'components/Modals'
import { StripeHistoryModal } from 'components/Modals/Payment/StripeHistoryModal'
import { Overview } from 'components/Overview'
import { AccountType } from 'config'
import { LoanProcessRushPaymentRequestTypeTitles } from 'pages/LoanSubmission/constants'
import { InvoicePaymentModal } from 'pages/LoanSubmission/InvoicePaymentModal'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getInvoices,
  IStripeIntent,
  PaymentOrderType,
  PaymentOrderTypeTitles,
  PaymentRefIdTitles,
  refundInvoice,
  StripeIntentStatus,
} from 'services'
import { Button } from 'stories/components'
import { confirm, formatTime, getPrice2decimal, renderHeader, thousandSeperator } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { StripeStatusLabels } from '../OrderAppraisals'

const getRemainAmount = (intentItem: Record<string, any>): number => {
  let { amount, history } = intentItem
  history.map((item: any) => {
    if (item.status === StripeIntentStatus.partially_refunded) amount -= item.amount
  })
  return amount
}
export default function Invoices() {
  const { borrower, auth } = useSelector((state: any) => ({
    borrower: state.borrower.borrower,
    auth: state.auth,
  }))
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<IStripeIntent[]>([])
  const [historyItem, setHistoryItem] = useState<Record<string, any>>({})
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [showRefund, setShowRefund] = useState(false)
  const [refundItem, setRefundItem] = useState<Record<string, any>>({})

  const isAdmin = auth.profile.accountType === AccountType.ADMIN
  useEffect(() => {
    setLoanNumber()
    refetch()
  }, [])

  const refetch = () => {
    setLoading(true)
    getInvoices()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  const onAdd = () => {
    setIsOpen(true)
  }

  const onCloseModal = () => {
    setIsOpen(false)
    refetch()
  }

  const sortableHeaders = [
    { title: 'Intent ID', key: 'intentId' },
    { title: 'Customer Email', key: 'email' },
    { title: 'Type', key: 'type' },
    { title: 'Amount', key: 'amount' },
    { title: 'Status', key: 'status' },
    { title: 'Detail', key: 'detail' },
    { title: 'Created At', key: 'createdAt' },
  ]

  const renderOrderType = (item: IStripeIntent) => {
    if (item.orderType == PaymentOrderType.LoanSubmissionRush)
      return LoanProcessRushPaymentRequestTypeTitles[item.data.requestType] || item.data.feeTitle
    return PaymentOrderTypeTitles[item.orderType]
  }

  const renderDetails = (item: IStripeIntent) => {
    const orderType = renderOrderType(item) // PaymentOrderTypeTitles[item.orderType]

    const refTitle = PaymentRefIdTitles[item.orderType]

    return (
      <div>
        <p>Order Type: {orderType}</p>
        {!!refTitle && (
          <p>
            {refTitle}: {item.refId}
          </p>
        )}
        {item.data.requestedBy ? <p>By: {item.data.requestedBy}</p> : null}
      </div>
    )
  }

  const refund = async (intentId: string, refundData: Record<string, any>) => {
    const res: any = await confirm('Are you sure you want to refund this payment?')
    if (res === false) return
    setShowRefund(false)
    await refundInvoice(intentId, refundData)
    refetch()
  }

  return (
    <div className={`invoices-container px-2 py-6`}>
      <Overview title="Invoices" />
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white shadow1 rounded mb-4 p-4`}>
          <LayoutLoading show={loading} />
          <div className="flex gap-4 my-2 justify-between items-center">
            <div className="ml-2 text-[18px] font-variation-settings-600">Invoices</div>
            <div className="">
              <Button onClick={onAdd} className="px-[36px]">
                Add
              </Button>
            </div>
          </div>
          <div className="parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  {sortableHeaders.map(({ title, key }, index) =>
                    renderHeader({
                      title,
                      sortable: false,
                      key,
                      sortOrder: 0,
                      index,
                      onSort: () => {},
                    }),
                  )}
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-[14.5px] text-gray-900">
                {data.map((item, index) => {
                  const status = StripeStatusLabels[item.status] || []
                  return (
                    <tr key={index} className={`border-b ${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-6 py-3">{index + 1}</td>
                      <td className="px-2">
                        <a
                          className="font-bold text-shade-blue hover:underline cursor-pointer"
                          href={`https://dashboard.stripe.com/payments/${item.intentId}`}
                          target="_blank"
                        >
                          <span>{item.intentId}</span>
                        </a>
                      </td>
                      <td className="p-2">{item.email}</td>
                      <td className="p-2">{renderOrderType(item)}</td>
                      <td className="p-2">
                        ${thousandSeperator(item.amount)}
                        {item.status === StripeIntentStatus.partially_refunded ? (
                          <p className="border-t-[1px] border-gray-200">
                            ${getPrice2decimal(getRemainAmount(item), false, true)}
                          </p>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className={`p-2 text-${status[0]}-500`}>{status[1] || item.status}</td>
                      <td className="p-2">{renderDetails(item)}</td>
                      <td className="p-2">{formatTime(item.createdAt)}</td>
                      <td className="p-2 text-center">
                        <div className="flex items-center justify-center gap-2">
                          {isAdmin &&
                            [StripeIntentStatus.succeeded, StripeIntentStatus.partially_refunded].includes(
                              item.status,
                            ) && (
                              <span
                                className="text-red-700 cursor-pointer hover-shadow1 p-1 rounded"
                                onClick={() => {
                                  setShowRefund(true)
                                  setRefundItem(item)
                                }}
                              >
                                <ArrowRightOnRectangleIcon className="w-4 h-4" />
                              </span>
                            )}
                          {item.intentId && (
                            <span
                              className="text-gray-500 cursor-pointer hover-shadow1 p-1 rounded"
                              onClick={() => {
                                setHistoryItem(item)
                                setShowHistory(true)
                              }}
                            >
                              <ClockIcon className="w-4 h-4" />
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showHistory && (
        <StripeHistoryModal
          history={historyItem.history}
          createdAt={historyItem.createdAt}
          email={historyItem.email}
          amount={historyItem.amount}
          onClose={() => setShowHistory(false)}
        />
      )}
      {showRefund && (
        <RefundModal
          intentId={refundItem.intentId}
          amount={refundItem.amount}
          history={refundItem.history}
          onClose={() => setShowRefund(false)}
          onOk={refund}
        />
      )}
      {isOpen && (
        <InvoicePaymentModal
          payerEmail={borrower.email}
          refId={setLoanNumber()}
          showDescription={false}
          onClose={onCloseModal}
        />
      )}
    </div>
  )
}
