import {
  API_PDF_APPRAISAL_REVIEW,
  API_PDF_CLOSING_CALENDAR,
  API_PDF_CREDIT_AUTHORIZATION,
  API_PDF_DOCUMENT_GENIE,
  API_PDF_LOAN_SIGN,
  API_PDF_LOAN_STRUCTURE,
  API_PDF_LOAN_SUBMISSION,
  API_PDF_LOAN_TERM_SHEET,
  API_PDF_POST_CLOSING,
  API_PDF_PURCHASE_ADVICE,
  API_PDF_SSA,
} from 'config'
import Api from 'services/api'

export const getSSAPdf = (borrowerSeperator: string) => {
  return Api.get(API_PDF_SSA, {}, { borrowerSeperator }, { responseType: 'blob' })
}

export const getDocumentGeniePdf = (data: Record<string, any>) => {
  return Api.post(API_PDF_DOCUMENT_GENIE, data, {}, { responseType: 'blob' })
}

export const getAppraisalReviewPdf = (data: Record<string, any>) => {
  return Api.post(API_PDF_APPRAISAL_REVIEW, data, {}, { responseType: 'blob' })
}

export const loanTermSheetPdf = (data: Record<string, any>) => {
  return Api.post(API_PDF_LOAN_TERM_SHEET, data, {}, { timeout: 150000 })
}

export const getPurchaseAdvicePdf = (data: Record<string, any>) => {
  return Api.post(API_PDF_PURCHASE_ADVICE, data, {}, { responseType: 'blob' })
}

export const getLoanSubmissionPdf = (data: any) => {
  return Api.post(API_PDF_LOAN_SUBMISSION, data, {}, { responseType: 'blob' })
}

export const getLoanSignAppPdf = (commercialJson: Record<string, any> | null = null) => {
  return Api.post(API_PDF_LOAN_SIGN, { commercialJson }, {}, { responseType: 'blob' })
}

export const getLoanStructurePdf = (data: Record<string, any> | null = null) => {
  return Api.post(API_PDF_LOAN_STRUCTURE, data, {}, { responseType: 'blob' })
}

export const getCreditAuthorizationPdf = (memberId: number) => {
  return Api.post(API_PDF_CREDIT_AUTHORIZATION, { memberId }, {}, { responseType: 'blob' })
}

export const submitPostClosingPDF = (menu: string, values: Record<string, any>) => {
  return Api.post(API_PDF_POST_CLOSING, values, { menu }, { responseType: 'blob' })
}

export const signClosingCalendarPDF = (data: Record<string, any>) => {
  return Api.post(API_PDF_CLOSING_CALENDAR, data, {}, { responseType: 'blob', timeout: 150000 })
}
