import cloneDeep from 'clone-deep'
import { FollowupFlagMap } from 'config'
import { useState } from 'react'
import { RenderInput } from 'utils/RenderInput'

import { Modal } from '../Modal/Modal'

interface INote {
  flag: number
  notes: string
}
export const FieldNote = ({
  valueKey,
  currentValue,
  noteData,
  onClose,
  onOk,
}: {
  valueKey: string
  currentValue: string
  noteData: INote
  onClose: () => void
  onOk: Function
}) => {
  const [data, setData] = useState<INote>(noteData || { flag: 0, notes: '' })

  const onChange = (key: 'flag' | 'notes', value: string) => {
    const temp: any = cloneDeep(data)
    temp[key] = value
    setData(temp)
  }
  return (
    <Modal title="Field Note" isOpen childLevel={1} onClose={onClose} onOk={() => onOk(valueKey, data)}>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
        <div className="col-span-1">
          <RenderInput
            input={{ inputType: 'text', title: 'Field', value: valueKey, disabled: true }}
            Key="key"
            onChange={() => {}}
          />
        </div>
        <div className="col-span-1">
          <RenderInput
            input={{ inputType: 'text', title: 'Current Value', value: currentValue, disabled: true }}
            Key="currentValue"
            onChange={() => {}}
          />
        </div>
        <div className="col-span-1">
          <RenderInput
            input={{ inputType: 'select', title: 'Follow Up flag', options: FollowupFlagMap, value: data.flag }}
            Key="flag"
            onChange={onChange}
          />
        </div>
        <div className="col-span-2">
          <RenderInput
            input={{ inputType: 'textarea', title: 'Notes', value: data.notes }}
            Key="notes"
            onChange={onChange}
          />
        </div>
      </div>
    </Modal>
  )
}
