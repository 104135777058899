import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config'
import { PaymentType } from 'pages/Admin'
import { StripeWithMethod } from 'pages/Payment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  findStripeIntents,
  getAllPaymentType,
  getParties,
  getUserById,
  IStripeIntent,
  isVendorEnabled,
  PaymentOrderType,
  sendCounterApprovalPaymentData,
  sendLoanProcRushPaymentData,
  StripeIntentPaidStatus,
  StripeIntentStatus,
  StripeMethod,
} from 'services'
import { Button, Modal, PlainTable, RadioGroup } from 'stories/components'
import { formatTime, getPrice2decimal, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { LoanProcessRushPaymentRequestTypeTitles } from './constants'

export const InvoicePaymentModal = ({
  title = 'Invoice',
  payerTitle = 'Borrower',
  payerEmail = '',
  paymentOrderType = PaymentOrderType.LoanSubmissionRush,
  refId,
  showDescription = true,
  onClose,
}: {
  title?: string
  payerTitle?: string
  payerEmail?: string
  paymentOrderType?: PaymentOrderType.LoanSubmissionRush | PaymentOrderType.CounterPartyApproval
  refId: number
  showDescription?: boolean
  onClose: Function
}) => {
  const { profile } = useSelector((state: any) => ({
    profile: state.auth.profile,
  }))

  const [loading, setLoading] = useState(false)
  const [requestType, setRequestType] = useState('')
  const [payerEmailInput, setPayerEmailInput] = useState<InputType>({
    inputType: 'text',
    type: 'email',
    title: `${payerTitle} Email`,
    value: payerEmail,
    required: true,
    visible: true,
  })
  const [customFeeInput, setCustomFeeInput] = useState<InputType>({
    inputType: 'text',
    type: 'thousandSep',
    title: 'Fee Amount',
    prefix: '$',
    value: '',
    required: true,
    visible: false,
  })
  const [feeTitleInput, setFeeTitleInput] = useState<InputType>({
    inputType: 'text',
    title: 'Title of Fee',
    value: '',
    required: true,
    visible: false,
  })
  const [showPayForm, setShowPayForm] = useState(false)
  const [status, setStatus] = useState<StripeIntentStatus>()
  const [isCompleted, setCompleted] = useState(false)
  const [uniqueId, setUniqueId] = useState(0)
  const [pendingPayments, setPendingPayments] = useState<IStripeIntent[]>([])
  const [paymentType, setPaymentType] = useState<PaymentType[] | null>(null)
  const [enabled, setEnabled] = useState(false)
  const [lenderName, setLenderName] = useState('')

  useEffect(() => {
    getAllPaymentType(paymentOrderType).then((data) => setPaymentType(data))
    setLoading(true)
    setUniqueId(Date.now())

    isVendorEnabled('Stripe').then((data) => setEnabled(data))

    findStripeIntents({
      status: StripeIntentStatus.processing,
      orderType: paymentOrderType,
      refId,
    })
      .then((data) => setPendingPayments(data))
      .finally(() => setLoading(false))
    if (paymentOrderType === PaymentOrderType.LoanSubmissionRush) {
      getParties(refId).then((data: Array<Record<string, any>>) => {
        data.map((item: any) => {
          if (item.Type === 'Lender') {
            const { FirstName, LastName, Company } = item
            if (FirstName && LastName) setLenderName(`${FirstName} ${LastName}`)
            else setLenderName(Company)
          }
        })
      })
    } else if (paymentOrderType === PaymentOrderType.CounterPartyApproval) {
      getUserById(refId).then((data: Record<string, any>) => {
        setLenderName(data.name)
      })
    }
  }, [])

  const isPayable = useMemo(() => {
    return requestType != 'custom' || (!!removeComma(customFeeInput.value) && !!feeTitleInput.value)
  }, [requestType, customFeeInput, feeTitleInput])

  const paymentTypeItem = useMemo(
    () => paymentType && paymentType.find((item) => item.id.toString() === requestType),
    [paymentType, requestType],
  )

  const requestTypeOptions = useMemo(() => {
    if (!paymentType) return {}

    return Object.fromEntries(
      paymentType
        .map((item) => [item.id.toString(), `${item.title} - $${getPrice2decimal(item.cost, false, true)}`])
        .concat([['custom', `Custom`]]),
    )
  }, [paymentType])

  const onChangeRequestType = (value: string) => {
    setRequestType(value)

    const newInput = cloneDeep(customFeeInput)
    newInput.visible = value == 'custom'
    setCustomFeeInput(newInput)

    const newTitleInput = cloneDeep(feeTitleInput)
    newTitleInput.visible = value == 'custom'
    setFeeTitleInput(newTitleInput)

    if (value == 'none') onClose(false)
  }

  const onChangeCustomFee = (key: string, value: string) => {
    const newInput = cloneDeep(customFeeInput)
    newInput.value = value
    newInput.error = InputValidate(newInput)
    setCustomFeeInput(newInput)
  }

  const onChangeFeeTitle = (key: string, value: string) => {
    const newTitleInput = cloneDeep(feeTitleInput)
    newTitleInput.value = value
    newTitleInput.error = InputValidate(newTitleInput)
    setFeeTitleInput(newTitleInput)
  }

  const onChangeEmail = (key: string, value: string) => {
    const newInput = cloneDeep(payerEmailInput)
    newInput.value = value
    newInput.error = InputValidate(newInput)
    setPayerEmailInput(newInput)
  }

  const getFeeTitleAmount = () => {
    let feeAmount: number | string = 0
    let feeTitle: string = ''

    if (paymentType?.length && requestType !== 'custom') {
      const paymentTypeItem = paymentType.find((item) => item.id.toString() === requestType)
      if (paymentTypeItem) {
        feeAmount = paymentTypeItem.cost
        feeTitle = paymentTypeItem.title
      }
    } else if (requestType === 'custom') {
      feeAmount = removeComma(customFeeInput.value)
      feeTitle = feeTitleInput.value || 'Custom Fee'
    }

    return { feeAmount, feeTitle }
  }

  const onSendLinkToPayer = () => {
    setLoading(true)

    let sentPromise: Promise<any>
    const { feeAmount, feeTitle } = getFeeTitleAmount()

    if (paymentOrderType == PaymentOrderType.LoanSubmissionRush)
      sentPromise = sendLoanProcRushPaymentData({
        id: uniqueId,
        customFee: feeAmount,
        feeTitle,
        requestType,
        method: 'link',
        borrowerEmail: payerEmailInput.value,
        lenderName,
      })
    else
      sentPromise = sendCounterApprovalPaymentData(refId, {
        id: uniqueId,
        customFee: feeAmount,
        feeTitle,
        requestType,
        method: 'link',
        payerEmail: payerEmailInput.value,
        lenderName,
      })

    sentPromise
      .then(() => {
        toast('Email with payment link is sent successfully.', { type: 'info' })
      })
      .finally(() => {
        setLoading(false)
        setUniqueId(Date.now())
      })
  }

  const onPayNow = () => {
    setShowPayForm(true)
  }

  const onPayCompleted = async (intentId: string, status: any) => {
    setStatus(status)
    setCompleted(StripeIntentPaidStatus.includes(status))

    const { feeAmount, feeTitle } = getFeeTitleAmount()

    if (!feeAmount) return

    setLoading(true)

    let sentPromise: Promise<any>
    if (paymentOrderType == PaymentOrderType.LoanSubmissionRush)
      sentPromise = sendLoanProcRushPaymentData({
        id: uniqueId,
        customFee: feeAmount,
        feeTitle,
        method: 'pay',
        intentId,
        requestType,
      })
    else
      sentPromise = sendCounterApprovalPaymentData(refId, {
        id: uniqueId,
        customFee: feeAmount,
        feeTitle,
        method: 'pay',
        intentId,
        requestType,
        payerEmail: profile.email,
      })

    sentPromise.finally(() => setLoading(false))
  }

  return (
    <Modal
      title={title}
      titleOkay=""
      onClose={() =>
        onClose({
          status,
          isRush: status == StripeIntentStatus.succeeded && paymentTypeItem && paymentTypeItem.isRush,
        })
      }
      isOpen={true}
      disabled={!requestType}
      hiddenX
    >
      <div className="w-196">
        <LayoutLoading show={loading} />
        {!enabled ? (
          <p className="mb-1">Please contact to Administrator to enable Payment</p>
        ) : !showPayForm ? (
          <>
            {showDescription && (
              <p
                className="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded text-[15px] mb-6"
                role="alert"
              >
                Once you submit this request, our team will prioritize the processing of this file to ensure a swift
                turnaround.
              </p>
            )}

            {!!pendingPayments.length && (
              <>
                <RenderInput
                  input={{
                    inputType: 'section',
                    title: 'Pending Payments',
                  }}
                  Key="section2"
                  onChange={() => {}}
                />
                <PlainTable
                  thClass="!px-2"
                  tdClass="!px-2 py-3"
                  widthClass="my-4 w-full"
                  header={['No', 'Request Type', 'Amount', 'Status', 'Email', 'Created At']}
                  data={pendingPayments.map((intent, index) => [
                    index + 1,
                    <p className="max-w-[10rem]">
                      {LoanProcessRushPaymentRequestTypeTitles[intent.data.requestType] || intent.data.feeTitle}
                    </p>,
                    `$${getPrice2decimal(intent.amount, false, true)}`,
                    <p className="capitalize">{intent.status}</p>,
                    intent.email,
                    formatTime(intent.createdAt),
                  ])}
                />
              </>
            )}

            <RadioGroup
              id="requestType"
              title="Request Type"
              options={requestTypeOptions}
              value={requestType}
              onChange={onChangeRequestType}
            />
            {customFeeInput.visible && (
              <div className="grid grid-cols-2 my-4 gap-4 items-center">
                <RenderInput Key="customFeeInput" input={customFeeInput} onChange={onChangeCustomFee} />
                <RenderInput Key="feeTitleInput" input={feeTitleInput} onChange={onChangeFeeTitle} />
              </div>
            )}

            {!!requestType && (
              <>
                <RenderInput
                  input={{
                    inputType: 'section',
                    title: `${payerTitle} will pay`,
                  }}
                  Key="section1"
                  onChange={() => {}}
                />
                <div className="grid grid-cols-2 my-4 gap-4 items-center">
                  <RenderInput Key="payerEmail" input={payerEmailInput} onChange={onChangeEmail} />
                  <Button
                    disabled={!payerEmailInput.value || !!payerEmailInput.error || !isPayable}
                    link
                    color="blue"
                    onClick={onSendLinkToPayer}
                  >
                    <span className="flex gap-2 items-center justify-center">
                      Send Payment Link
                      <ChevronRightIcon className="w-4 h-4" />
                    </span>
                  </Button>
                </div>

                <RenderInput
                  input={{
                    inputType: 'section',
                    title: 'Pay Now',
                  }}
                  Key="section2"
                  onChange={() => {}}
                />
                <div className="grid grid-cols-2 my-4 gap-4 items-center">
                  <Button onClick={onPayNow} disabled={!isPayable}>
                    Pay Now
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {!isCompleted ? (
              <div className="text-shade-blue flex items-center mb-2 font-bold">
                <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
                <span className="hover:underline cursor-pointer" onClick={() => setShowPayForm(false)}>
                  Back
                </span>
              </div>
            ) : (
              <></>
            )}

            <StripeWithMethod
              availableMethods={[StripeMethod.card]}
              hasAdditionalBankMethod
              paymentDetails={{ status }}
              onCompleted={onPayCompleted}
              paymentData={{
                uniqueId,
                email: profile.email,
                amount:
                  requestType === 'custom'
                    ? removeComma(customFeeInput.value)
                    : paymentType?.find((pt) => pt.id.toString() === requestType)?.cost || 0,
                feeTitle:
                  requestType === 'custom'
                    ? feeTitleInput.value || 'Custom Fee'
                    : paymentType?.find((pt) => pt.id.toString() === requestType)?.title || '',
                refId: `${refId}-${uniqueId}`,
                orderType: paymentOrderType,
                loanNumber: refId,
                requestType,
                method: 'pay',
                lenderName,
              }}
            />
          </>
        )}
      </div>
    </Modal>
  )
}
