import { ClockIcon } from '@heroicons/react/24/outline'
import { usePermissions } from 'hooks/usePermissions'
import { CopyableText } from 'stories/components'
import { openAuditLog } from 'utils'

interface InputProps {
  loanNumber: any
  program: string
  byteNumber: number
  entityTitle: string
  borrowerName: string
  propertyAddress: string
}

export function LoanDetail({
  loanNumber,
  program,
  byteNumber,
  entityTitle,
  borrowerName,
  propertyAddress,
}: InputProps) {
  const { hasPermission } = usePermissions()
  const seeLog = hasPermission('CAN_VIEW_AUDIT_LOG')

  const isNewLoan = loanNumber === 'New'

  const showHistory = (table: string, field: string, keys: any, map?: any) => {
    if (isNewLoan) return
    const options = {
      table,
      field,
      keys,
      map,
    }
    openAuditLog(options)
  }

  const sizeClass = 'w-4 h-4'
  return (
    <div className="text-[14px] text-left">
      <div className="flex mb-1">
        <div className="w-36">Loan Number:</div>
        <div className="font-bold ml-2">
          <CopyableText>{byteNumber || loanNumber}</CopyableText>
        </div>
      </div>

      <div className="flex mb-1">
        <div className="w-36">Entity Name:</div>
        <div className="flex items-center ml-2 group">
          <CopyableText>{entityTitle}</CopyableText>
          {seeLog && (
            <span className="text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover-shadow1 rounded p-1">
              <ClockIcon
                className={sizeClass}
                onClick={() =>
                  showHistory('Borrower', 'entityTitle', { field: 'entityTitle', borrowerSeperator: 'borrower' })
                }
              />
            </span>
          )}
        </div>
      </div>

      <div className="flex mb-1">
        <div className="w-36">Borrower Name:</div>
        <div className="flex items-center ml-2 group">
          <CopyableText>{borrowerName}</CopyableText>
          {seeLog && (
            <span className="text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover-shadow1 rounded p-1">
              <ClockIcon
                className={sizeClass}
                onClick={() =>
                  showHistory('Borrower', 'firstName', { field: 'firstName', borrowerSeperator: 'borrower' })
                }
              />
            </span>
          )}
        </div>
      </div>

      <div className="flex mb-1">
        <div className="w-36">Property Address:</div>
        <div className="flex items-center ml-2 group">
          <CopyableText>{propertyAddress}</CopyableText>

          {seeLog && (
            <span className="text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover-shadow1 rounded p-1">
              <ClockIcon
                className={sizeClass}
                onClick={() => showHistory('Loan', 'subjectPropertyAddress', { field: 'subjectPropertyAddress' })}
              />
            </span>
          )}
        </div>
      </div>
      <div className="flex mb-1">
        <div className="w-36">Loan Program:</div>
        <div className="flex items-center ml-2 group">
          <CopyableText>{program}</CopyableText>
        </div>
      </div>
    </div>
  )
}
