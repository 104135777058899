import { MagnifyingGlassIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { productTypeOptions, transactionTypeOptions } from 'config'
import { useEffect, useState } from 'react'
import { getConditionBrokerTemplates, getConditions } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Input2 } from 'stories/components'

import type { ICondition } from '../Conditions/types'
import { BrokerTemplateDetails } from './details'
import type { IConditionBrokerTemplate } from './types'

export function BrokerTemplates() {
  const [query, setQuery] = useState('')
  const [conditions, setConditions] = useState<Array<ICondition>>([])
  const [templates, setTemplates] = useState<Array<IConditionBrokerTemplate>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [selectedIndex, setSelectedIndex] = useState(-1)

  useEffect(() => {
    refresh()
  }, [])

  const refresh = async () => {
    setIsLoading(true)
    const [{ data }, templates] = await Promise.all([getConditions(), getConditionBrokerTemplates()])
    setConditions(data)
    setTemplates(templates)
    setIsLoading(false)
  }

  if (isEditing)
    return (
      <BrokerTemplateDetails
        lastUpdatedAt={lastUpdatedAt}
        conditions={conditions}
        template={templates[selectedIndex]}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
      />
    )

  const onEdit = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
  }

  let index = 0

  return (
    <div className="Templates-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Loan Setup Condition Templates
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              className="w-96"
              onChange={(value) => setQuery(value)}
            />
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="px-3 py-3">
                Name (Product / Transaction)
              </th>
              <th scope="col" className="px-3">
                Conditions
              </th>
              <th scope="col" className="px-3">
                Required
              </th>
              <th scope="col" className="px-3">
                Application
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {productTypeOptions.map((productType, index1) =>
              transactionTypeOptions.map((transactionType, index2) => {
                if (!index1 && !index2) index = 0

                if (
                  query &&
                  !productType.toLowerCase().includes(query.trim().toLowerCase()) &&
                  !transactionType.toLowerCase().includes(query.trim().toLowerCase())
                )
                  return null
                const no = index1 * transactionTypeOptions.length + index2
                const templateIndex = templates.findIndex(
                  (t) => t.productType == productType && t.transactionType == transactionType,
                )
                if (templateIndex == -1) return null

                index += 1
                return (
                  <tr className={`border-b ${index % 2 == 0 ? 'bg-slate-50' : 'bg-white'}`} key={`${no}`}>
                    <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {index}
                    </td>
                    <td className="px-3">
                      {productType} / {transactionType}
                    </td>
                    <td className="px-3">{templates[templateIndex].conditions.length}</td>
                    <td className="px-3">{templates[templateIndex].conditions.filter((c) => c.required).length}</td>
                    <td className="px-3">{templates[templateIndex].conditions.filter((c) => c.application).length}</td>

                    <td className="px-3">
                      <span className="flex gap-1">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => onEdit(templateIndex)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              }),
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
