import { ArrowDownTrayIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Sortable } from 'components/Sortable'
import { channelTypeOptions, loanStatusConstants, lockTypeOptions, productTypeOptions } from 'config'
import { states } from 'config/states.constants'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  downloadReports,
  downloadReportsCSV,
  generateReportsPreview,
  getLoanReport,
  getLoanReportsPlaceholders,
  saveReports,
} from 'services'
import { svgLoading, svgSearch } from 'stories/assets'
import { Button, HorzScrollContainer, Input2, InputDateRange, MultiSelect } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { filterObject, generateOptions } from 'utils'

import { ReportPlaceholders } from './ReportPlaceholders'
import type { IReportHeaders } from './types'

const ReportDetails = () => {
  const { id } = useParams() as any
  const navigate = useHistory()

  const isNew = Number(id) == 0
  const [loading, setLoading] = useState(false)
  const [placeholders, setPlaceholders] = useState<Record<string, IReportHeaders[]>>({ Loan: [] })
  const [type, setType] = useState('Loan')
  const [selectedPlaces, setSelectedPlaces] = useState<string[]>(['Loan #'])
  const [title, setTitle] = useState('')

  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  const fullLoanStatusOptions = { ...loanGlobalStatus, ...loanStatusConstants.servicingStatus }
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    status: generateOptions(fullLoanStatusOptions),
    lock: generateOptions(lockTypeOptions),
    state: generateOptions(states),
    type: generateOptions(channelTypeOptions), // Channel
    productType: generateOptions(productTypeOptions), // Product Type
    asOf: {},
    orderBy: 'asOf',
    orderDir: '-1',
    skip: 0,
    count: 20,
  })
  const [preview, setPreview] = useState<string[][]>([])
  const [total, setTotal] = useState(0)
  const [queryTimes, setQueryTimes] = useState(0)

  useEffect(() => {
    setLoading(true)
    getLoanReportsPlaceholders()
      .then((data) => {
        setPlaceholders(data)
      })
      .finally(() => {
        if (isNew) setLoading(false)
      })

    if (isNew) return
    getLoanReport(id)
      .then(({ title, headers, filters }) => {
        setTitle(title)
        setSelectedPlaces(headers)
        setFilters(filters)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const onSave = () => {
    setLoading(true)
    saveReports(id, { type, title, headers: selectedPlaces, filters })
      .then(() => navigate.push('/reports'))
      .finally(() => setLoading(false))
  }

  const makeEmptyIfAll = (options: Record<string, boolean>, fullOptions: string[] | Record<string, any>) => {
    const arrOptions = filterObject(options)
    const isSelectedAll = arrOptions.length == Object.keys(generateOptions(fullOptions)).length
    return isSelectedAll ? [] : arrOptions
  }

  const getFilters = () => {
    return {
      ...filters,
      status: makeEmptyIfAll(filters.status, fullLoanStatusOptions),
      lock: makeEmptyIfAll(filters.lock, lockTypeOptions),
      type: makeEmptyIfAll(filters.type, channelTypeOptions),
      productType: makeEmptyIfAll(filters.productType, productTypeOptions),
      state: makeEmptyIfAll(filters.state, states),
    }
  }

  const onGenerate = () => {
    if (selectedPlaces.length == 0) return

    setLoading(true)

    generateReportsPreview({
      type,
      headers: selectedPlaces,
      filters: getFilters(),
    })
      .then(({ data, total }) => {
        setPreview(data)
        setTotal(total)
        setQueryTimes(queryTimes + 1)
      })
      .finally(() => setLoading(false))
  }

  const onDownload = () => {
    if (selectedPlaces.length == 0) return

    setLoading(true)

    downloadReports({
      type,
      title,
      headers: selectedPlaces,
      filters: getFilters(),
    }).finally(() => setLoading(false))
  }

  const onDownloadCSV = () => {
    if (selectedPlaces.length == 0) return

    setLoading(true)

    downloadReportsCSV({
      type,
      title,
      headers: selectedPlaces,
      filters: getFilters(),
    }).finally(() => setLoading(false))
  }

  return (
    <div className="Reports-container py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full">
        <LayoutLoading show={loading} />
        <div className="relative">
          <div className="text-shade-blue flex items-center mb-2 font-bold">
            <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
            <Link to={`/reports`} className="hover:underline">
              Return to Reports
            </Link>
          </div>

          <div className="flex flex-wrap justify-between">
            <h1 className="text-2xl font-bold flex items-center mb-2">
              <span>{isNew ? 'Create Report' : title}</span>{' '}
              <span className="text-base ml-3">
                {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
              </span>
            </h1>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <ReportPlaceholders
            placeholders={placeholders}
            selectedPlaces={selectedPlaces}
            setSelectedPlaces={setSelectedPlaces}
            type={type}
            setType={setType}
          />

          <div className="md:col-span-2">
            <div className="border-b border-gray-100 pb-4 mb-4">
              <div className="grid items-center grid-cols-2 md:grid-cols-3 gap-4">
                <Input2 type="text" title="Title" required value={title} onChange={setTitle} />

                <div>
                  <Button disabled={!title} onClick={onSave}>
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className="border-b border-gray-100 pb-4 mb-4">
              <p className="text-lg font-bold mb-2">Filters</p>

              <div className="grid items-center grid-cols-2 md:grid-cols-3 gap-4">
                <Input2
                  type="search"
                  title="Search Loans"
                  hasIcon
                  icon={svgSearch}
                  value={filters.query}
                  onChange={(value) => onChangeFilter('query', value)}
                />
                <MultiSelect
                  id="loan-status"
                  title="Loan Status"
                  options={fullLoanStatusOptions}
                  value={filters.status}
                  defaultSelected={false}
                  onChange={(value) => onChangeFilter('status', value)}
                />
                <MultiSelect
                  id="loan-type"
                  title="Channel"
                  options={channelTypeOptions}
                  value={filters.type}
                  defaultSelected={false}
                  onChange={(value) => onChangeFilter('type', value)}
                />
                <MultiSelect
                  id="lock-status"
                  title="Lock"
                  options={lockTypeOptions}
                  value={filters.lock}
                  defaultSelected={false}
                  onChange={(value) => onChangeFilter('lock', value)}
                />
                <MultiSelect
                  id="product-type"
                  title="Product Type"
                  options={productTypeOptions}
                  value={filters.productType}
                  defaultSelected={false}
                  onChange={(value) => onChangeFilter('productType', value)}
                />
                <MultiSelect
                  id="state"
                  title="State"
                  options={states}
                  value={filters.state}
                  defaultSelected={false}
                  onChange={(value) => onChangeFilter('state', value)}
                />
                <InputDateRange
                  title="As of Date"
                  value={filters.asOf}
                  onChange={(value) => onChangeFilter('asOf', value)}
                />
              </div>
            </div>

            <div className="border-b border-gray-100 pb-4 mb-4">
              <p className="text-lg font-bold mb-2">Headers</p>
              <div className="flex flex-wrap gap-1">
                <Sortable
                  data={selectedPlaces.map((v) => ({ id: v, data: v }))}
                  setData={(values: any[]) => setSelectedPlaces(values.map(({ data }) => data))}
                  renderItem={(data: any, index: number) => (
                    <p className="px-2 py-1 border border-gray-200 rounded-md text-sm">
                      {index + 1}. {data}
                    </p>
                  )}
                />
              </div>
            </div>

            <div className="flex items-center gap-4 justify-between mb-2">
              <p className="text-lg font-bold">Preview ({total})</p>
              <div className="flex items-center gap-4">
                <p className="text-sm cursor-pointer text-shade-blue hover:underline" onClick={onGenerate}>
                  Preview
                </p>
                <span className="p-1 text-sm cursor-pointer text-shade-blue hover-shadow1 rounded" onClick={onDownload}>
                  <Tooltip message="Download Excel">
                    <ArrowDownTrayIcon className="w-4 h-4" />
                  </Tooltip>
                </span>
                <span
                  className="p-1 text-sm cursor-pointer text-shade-blue hover-shadow1 rounded"
                  onClick={onDownloadCSV}
                >
                  <Tooltip message="Download CSV">
                    <ArrowDownTrayIcon className="w-4 h-4" />
                  </Tooltip>
                </span>
              </div>
            </div>

            {!!preview.length && (
              <HorzScrollContainer size={queryTimes}>
                <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 overflow-auto">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th className="px-2 py-1">No</th>
                      {preview[0].map((key) => (
                        <th className="px-2 py-1">{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-[14px] text-gray-900">
                    {preview.map((item: string[], index: number) => {
                      if (index == 0) return null
                      return (
                        <tr key={index} className={`border ${index % 2 == 0 ? 'bg-gray-50' : ''}`}>
                          <td className="px-2 py-2">{index}</td>
                          {item.map((v) => (
                            <td className="px-2 py-2 whitespace-nowrap">
                              <span>{v}</span>
                            </td>
                          ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </HorzScrollContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportDetails
