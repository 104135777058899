import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { TaskAssigneeOptions } from 'pages/Tasks/constants'
import { useEffect, useState } from 'react'
import { getAdminEmails } from 'services'
import { deleteTaskTemplate, getTasks } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, Input2 } from 'stories/components'
import { confirm } from 'utils'

import { TaskDetails } from './details'
import type { ITaskTemplate } from './types'

export function Tasks() {
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [tasks, setTasks] = useState<Array<ITaskTemplate>>([])
  const [isEditing, setIsEditing] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [adminEmails, setAdminEmails] = useState<string[]>([])

  useEffect(() => {
    refresh()
    getAdminEmails().then((emails: string[]) => setAdminEmails(emails))
  }, [])

  const refresh = () => {
    setIsLoading(true)
    getTasks()
      .then(({ data }) => {
        setTotal(data.length)
        setTasks(data)
      })
      .finally(() => setIsLoading(false))
  }

  if (isEditing)
    return (
      <TaskDetails
        lastUpdatedAt={lastUpdatedAt}
        defaults={selectedIndex == -1 ? null : tasks[selectedIndex]}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
        adminEmails={adminEmails}
      />
    )

  const onAdd = () => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(-1)
    setIsEditing(true)
  }

  const onEdit = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
  }

  const onTrash = async (index: number) => {
    const { id, no } = tasks[index]
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this task?
        <br />
        <span className="text-gray-600">Task No: {no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deleteTaskTemplate(id)
      .then(refresh)
      .catch(() => setIsLoading(false))
  }

  return (
    <div className="Tasks-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Tasks
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              onChange={(value) => setQuery(value)}
            />
          </div>
          <p className="ml-5">- {total} tasks</p>
        </div>
        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="py-3 px-3">
                Description
              </th>
              <th scope="col" className="px-3">
                Note
              </th>
              <th scope="col" className="px-3">
                Assigned to
              </th>
              <th scope="col" className="px-3">
                Due days
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {tasks.map((item: any, index) => {
              if (query && !item.description.toLowerCase().includes(query.toLowerCase())) return null
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {item.no}
                  </td>
                  <td className="px-3">{item.description}</td>
                  <td className="px-3">{item.notes}</td>
                  <td className="px-3">
                    {item.assignedTo.map((to: string) => TaskAssigneeOptions[to] || to).join(', ')}
                  </td>
                  <td className="px-3">{item.dueDays}</td>
                  <td className="px-3">
                    <span className="flex">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(index)}>
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(index)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
