import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { TaskAssigneeOptions } from 'pages/Tasks/constants'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdminConfig, getAdminEmails, getTasks, setAdminConfig } from 'services'

import { LoanSubmissionDetails } from './details'
import { LoanSubmissionConfig } from './types'

export const LoanSubmission = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Record<string, LoanSubmissionConfig>>({})
  const [actions, setActions] = useState<Record<string, string>>({})
  const [currentItem, setCurrentItem] = useState<string | null>(null)
  const [adminEmails, setAdminEmails] = useState<string[]>([])
  const [sortKeys, setSortKeys] = useState<string[]>([])
  const [tempTasks, setTempTasks] = useState<Array<any>>([])

  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])
  useEffect(() => {
    load()
    getAdminEmails().then((emails: string[]) => setAdminEmails(emails))
    getTasks().then(({ data }) => {
      setTempTasks(data)
    })
  }, [])

  const load = () => {
    setLoading(true)
    getAdminConfig('loanSubmission')
      .then(({ values, actions }) => {
        setData(values)
        setActions(actions)
        setSortKeys(Object.keys(Array(Object.keys(values).length).fill(1)))
      })
      .finally(() => setLoading(false))
  }

  const onBack = (values: LoanSubmissionConfig | null) => {
    if (values && currentItem) {
      setLoading(true)
      const newData = cloneDeep(data)
      newData[currentItem] = values
      setData(newData)
      setAdminConfig('loanSubmission', newData).finally(() => setLoading(false))
    }
    setCurrentItem(null)
  }

  const onChangeOrder = async (toIndex: number, fromIndex: number) => {
    const keys = Object.keys(data)

    const newKeys = [...keys]
    const [movedKey] = newKeys.splice(fromIndex, 1)
    newKeys.splice(toIndex, 0, movedKey)

    const reorder: any = {}
    newKeys.forEach((key) => {
      reorder[key] = data[key]
    })
    setLoading(true)
    await setAdminConfig('loanSubmission', reorder)
    setLoading(false)
    setData(reorder)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      <div className="w-full">
        {!currentItem ? (
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  No
                </th>
                <th scope="col" className="px-3">
                  Action
                </th>
                <th scope="col" className="px-3">
                  Loan Status To
                </th>
                <th scope="col" className="px-3">
                  Actions
                </th>
                <th className="px-3 text-right">Order</th>
              </tr>
            </thead>
            <tbody className="">
              {Object.keys(data).map((key, index) => {
                const item = data[key]
                const cn = `${index % 2 ? 'bg-slate-50' : 'bg-white'}`
                return [
                  <tr className={cn} key={`${index}`}>
                    <td className="px-3 py-3" rowSpan={2}>
                      {index + 1}
                    </td>
                    <td className="px-3 py-2" rowSpan={2}>
                      {actions[key] || ''}
                    </td>
                    <td className="px-3">{item.statusTo ? loanGlobalStatus[item.statusTo] : '- No Change -'}</td>
                    <td className="">
                      <span className="flex px-3">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => setCurrentItem(key)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                      </span>
                    </td>
                    <td className="px-1 text-right">
                      <select
                        value={index}
                        className="text-sm py-1 border-gray-200 pr-2 w-16"
                        onChange={(e) => onChangeOrder(parseInt(e.target.value), index)}
                      >
                        {[...sortKeys].map((value) => (
                          <option value={value} key={`option-${value}`}>
                            {parseInt(value) + 1}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>,
                  <tr className={`${cn} border-b italic`} key={`2-${index}`}>
                    <td colSpan={4} className="p-2">
                      {item.tasks?.map((task, index) => {
                        return (
                          <div key={`task-${index} mb-2`} className="flex">
                            <div className="w-[20px]">{index + 1}. </div>
                            <div>
                              <div>Description: {task.description}</div>
                              <div>
                                Assigned to:{' '}
                                {task.assignedTo.map((to: string) => TaskAssigneeOptions[to] || to).join(', ')}
                              </div>
                              <div>Due Days: {task.dueDays}</div>
                            </div>
                          </div>
                        )
                      })}
                    </td>
                  </tr>,
                ]
              })}
            </tbody>
          </table>
        ) : (
          <LoanSubmissionDetails
            action={currentItem}
            label={actions[currentItem]}
            data={data[currentItem]}
            adminEmails={adminEmails}
            tempTasks={tempTasks}
            onBack={onBack}
          />
        )}
      </div>
    </div>
  )
}
