import { ArrowDownTrayIcon, ClockIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { StripeHistoryModal } from 'components/Modals/Payment/StripeHistoryModal'
import { API_STRIPE_INTENT, appApiUrl, itemCountPerPage } from 'config'
import { StripeStatusLabels } from 'pages/Loan/OrderAppraisals'
import { LoanProcessRushPaymentRequestTypeTitles } from 'pages/LoanSubmission/constants'
import { DownloadRangeModal } from 'pages/Tasks/Modals/downloadRange'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  type IStripeIntent,
  getStripeIntents,
  PaymentOrderType,
  PaymentOrderTypeTitles,
  PaymentRefIdTitles,
} from 'services'
import { svgLoading } from 'stories/assets'
import { Input2, Pagination } from 'stories/components'
import { formatTime, serialize, thousandSeperator } from 'utils'

export const StripeManagements = () => {
  const auth = useSelector((state: any) => state.auth)

  const [isLoading, setLoading] = useState(true)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<IStripeIntent[]>([])
  const [isDownloadRangeModal, setDownloadRangeModal] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [historyItem, setHistoryItem] = useState<Record<string, any>>({})

  useEffect(() => {
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  useEffect(() => {
    filterData(filters)
  }, [])

  const filterData = async (filters: Record<string, any>) => {
    setLoading(true)
    const { data, total } = await getStripeIntents({
      query: filters.query.trim(),
      count: itemCountPerPage,
      skip: filters.pageNum * itemCountPerPage,
      hasData: true,
    })
    setData(data)
    setTotal(total)
    setLoading(false)
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterData(newFilters)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onDownloadStart = ({ from, to }: { from: string; to: string }) => {
    const link = `${appApiUrl}${API_STRIPE_INTENT}/download?token=${auth.token}&${serialize({
      query: filters.query,
      from,
      to,
    })}`
    window.open(link, '_blank')
    setDownloadRangeModal(false)
  }

  const renderDetails = (item: IStripeIntent) => {
    let orderType = PaymentOrderTypeTitles[item.orderType]

    if (item.orderType == PaymentOrderType.LoanSubmissionRush && item.data)
      orderType = LoanProcessRushPaymentRequestTypeTitles[item.data.requestType || ''] || item.data.feeTitle

    let content
    const refTitle = PaymentRefIdTitles[item.orderType]

    if (item.orderType == PaymentOrderType.CounterPartyApproval && item.data) {
      content = `${item.loan?.userEmail} - ${item.data.feeTitle}`
    } else content = `${refTitle}: ${item.refId}`

    return (
      <div>
        <p>Order Type: {orderType}</p>
        {!!refTitle && <p>{content}</p>}
      </div>
    )
  }

  return (
    <div className="StripeManage-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Stripe Management
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>

      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={filters.query}
              onChange={(value) => onChangeFilter('query', value)}
            />
          </div>
          <p className="ml-5 text-[15px]">- {total} Stripe Intents</p>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3">
                No
              </th>
              <th scope="col" className="px-2 py-3">
                Intent Id
              </th>
              <th scope="col" className="px-2 py-3">
                Status
              </th>
              <th scope="col" className="px-2 py-3">
                Created At
              </th>
              <th scope="col" className="px-2 py-3">
                Customer Email
              </th>
              <th scope="col" className="px-2 py-3">
                Amount
              </th>
              <th scope="col" className="px-2 py-3">
                Loan Number
              </th>
              <th scope="col" className="px-2 py-3">
                Detail
              </th>
              <th scope="col" className="px-2 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((item, index: number) => {
              const status = StripeStatusLabels[item.status] || []
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-2 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {filters.pageNum * itemCountPerPage + index + 1}
                  </td>
                  <td className="px-2">
                    <a
                      className="font-bold text-shade-blue hover:underline cursor-pointer"
                      href={`https://dashboard.stripe.com/payments/${item.intentId}`}
                      target="_blank"
                    >
                      <span>{item.intentId}</span>
                    </a>
                  </td>
                  <td className={`px-2 text-${status[0]}-500`}>{status[1] || item.status}</td>
                  <td className="px-2">{formatTime(item.createdAt)}</td>
                  <td className="px-2">{item.email}</td>
                  <td className="px-2">${thousandSeperator(item.amount)}</td>
                  <td className="px-2">{item.loan?.byteproFileName || item.loan?.loanNumber}</td>
                  <td className="px-2 py-2">{renderDetails(item)}</td>
                  <td className="px-2">
                    <div className="flex items-center justify-center gap-2">
                      <span
                        className="text-gray-500 cursor-pointer hover-shadow1 p-1 rounded"
                        onClick={() => {
                          setHistoryItem(item)
                          setShowHistory(true)
                        }}
                      >
                        <ClockIcon className="w-4 h-4" />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="footer flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
        <span className="my-2 mr-5 px-2 py-1 rounded hover-shadow1" onClick={() => setDownloadRangeModal(true)}>
          <ArrowDownTrayIcon className="h-5 w-5 text-blue-500" />
        </span>
      </div>
      {isDownloadRangeModal && (
        <DownloadRangeModal
          title="Select range to download stripe"
          onClose={() => setDownloadRangeModal(false)}
          onDownload={onDownloadStart}
        />
      )}
      {showHistory && (
        <StripeHistoryModal
          history={historyItem.history}
          createdAt={historyItem.createdAt}
          email={historyItem.email}
          amount={historyItem.amount}
          onClose={() => setShowHistory(false)}
        />
      )}
    </div>
  )
}
