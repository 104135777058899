import { FieldOrigin } from './types'

export const requiredFields = [
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'firstName' },
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'lastName' },
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'email' },
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'phone' },
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'presentAddress' },
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'subjectPropertyAddress' },
]

export const ignoredFields = [
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'borrowerFirstName' },
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'borrowerLastName' },
]
