import { InputType } from 'config'

export const getTextConfigurationFields = () => {
  const rlt: Record<string, InputType> = {
    section1: {
      inputType: 'section',
      title: 'Closing Calendar',
      span: 'full',
    },
    closingCalendarDesc: {
      title: 'Description',
      inputType: 'textarea',
      type: 'text',
      error: '',
      span: 2,
      rows: 2,
      required: true,
    },
    section2: {
      inputType: 'section',
      title: 'Loan Application',
      span: 'full',
    },
    additionalEntityDesc: {
      title: 'Additional Entity Members',
      inputType: 'textarea',
      type: 'text',
      error: '',
      span: 2,
      rows: 2,
      required: true,
    },
  }

  return rlt
}
