import cloneDeep from 'clone-deep'
import { InputType, RefundReason } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { StripeIntentStatus } from 'services'
import { Modal } from 'stories/components'
import { getPrice2decimal, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const defaultInputs = (): Record<string, InputType> => {
  return {
    amount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Refund Amount',
      required: true,
      prefix: '$',
    },
    reason: {
      inputType: 'select',
      title: 'Refund Reason',
      options: RefundReason,
      required: true,
      hasDefaultOption: true,
    },
    description: {
      inputType: 'textarea',
      title: 'Description',
      required: true,
      rows: 3,
    },
  }
}
export const RefundModal = ({
  intentId,
  amount,
  history,
  onOk,
  onClose,
}: {
  intentId: string
  amount: number
  history: Record<string, any>
  onOk: Function
  onClose: Function
}) => {
  const [inputs, setInputs] = useState(defaultInputs())
  const [remainAmount, setRemainAmount] = useState(0)

  useEffect(() => {
    let temp = amount
    history.map((item: any) => {
      if (item.status === StripeIntentStatus.partially_refunded) temp -= item.amount
    })
    setRemainAmount(temp)
  }, [])
  const onModalOk = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}

    Object.keys(inputs).map((key) => {
      newInputs[key].error = InputValidate({ ...newInputs[key], value: newInputs[key].value })
      if (newInputs[key].error?.length) hasError = true
      data[key] = newInputs[key].value
    })
    setInputs(newInputs)
    if (hasError) return
    if (data.amount > remainAmount) {
      toast('Refund amount should not exceed the payment amount.', { type: 'error' })
      return
    }
    onOk(intentId, data)
  }
  const onChange = (key: string, value: any) => {
    const temp = cloneDeep(inputs)
    temp[key].value = value
    setInputs(temp)
  }
  return (
    <Modal title="Refund Payment" isOpen={true} onOk={onModalOk} onClose={() => onClose()}>
      <>
        <p className="mb-4 mx-4">
          <span className="italic text-red-800">${getPrice2decimal(remainAmount, false, true)}</span> is available of $
          {getPrice2decimal(amount, false, true)}
        </p>
        {Object.keys(inputs).map((key: string) => {
          const input = inputs[key]
          return (
            <div className="mb-4 w-[400px] mx-4">
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </>
    </Modal>
  )
}
