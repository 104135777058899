import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button, FormTable, FormTableHeader } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

interface ExceptionConfig {
  types: Array<string>
  factors: Array<string>
  submissionMessage: string
}

const typeListHeader: FormTableHeader[] = [{ key: 'type', title: 'Type' }]
const factorListHeader: FormTableHeader[] = [{ key: 'factor', title: 'Factor' }]

const typeListInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'text',
      title: 'Type',
      required: true,
    },
  }
}

const factorListInputs = (): Record<string, InputType> => {
  return {
    factor: {
      inputType: 'text',
      title: 'Factor',
      required: true,
    },
  }
}

export const ExceptionRequestConfig = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [types, setTypes] = useState<Array<Record<string, any>>>([])
  const [factors, setFactors] = useState<Array<Record<string, any>>>([])
  const [message, setMessage] = useState('')

  const initData = async () => {
    setIsLoading(true)
    const data = await getAdminConfig('exceptionRequest')
    const newTypes = data.types.map((v: string, index: number) => {
      return { type: v, id: index + 1 }
    })
    setTypes(newTypes)

    const newFactors = data.factors.map((v: string, index: number) => {
      return { factor: v, id: index + 1 }
    })
    setFactors(newFactors)
    setMessage(data.submissionMessage)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onTypeListSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      const temp = cloneDeep(types)
      if (!currentId) {
        if (types.length === 0) values.id = 1
        else values.id = types[types.length - 1].id + 1
        temp.push(values)
      } else {
        temp.forEach((item: any, index: number) => {
          if (item.id == currentId) {
            values.id = temp[index].id
            temp[index] = values
          }
        })
      }
      setTypes(temp)
      resolve(values)
    })
  }

  const onTypeListRemove = (id: any) => {
    return new Promise((resolve) => {
      if (id) {
        const temp = types.filter((item) => item.id != id)
        setTypes(temp)
      }
      resolve(true)
    })
  }

  const onFactorListSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      const temp = cloneDeep(factors)
      if (!currentId) {
        if (factors.length === 0) values.id = 1
        else values.id = factors[factors.length - 1].id + 1
        temp.push(values)
      } else {
        temp.forEach((item: any, index: number) => {
          if (item.id == currentId) {
            values.id = temp[index].id
            temp[index] = values
          }
        })
      }
      setFactors(temp)
      resolve(values)
    })
  }

  const onFactorListRemove = (id: any) => {
    return new Promise((resolve) => {
      if (id) {
        const temp = factors.filter((item) => item.id != id)
        setFactors(temp)
      }
      resolve(true)
    })
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const json: ExceptionConfig = { types: [], factors: [], submissionMessage: message }
    types?.map((item: any) => json.types.push(item.type))
    factors?.map((item: any) => json.factors.push(item.factor))
    await setAdminConfig('exceptionRequest', json)
    toast('Successfully submitted', { type: 'success' })
    setIsLoading(false)
  }
  return (
    <div>
      <LayoutLoading show={isLoading} />
      <RenderInput input={{ inputType: 'section', title: 'Exception Types' }} Key="typeSection" onChange={() => {}} />
      <div className="mt-4">
        <FormTable
          header={typeListHeader}
          permission={1}
          inputs={typeListInputs}
          defaultData={types}
          submitText="Add"
          onSubmit={onTypeListSubmit}
          onRemove={onTypeListRemove}
        />
      </div>
      <RenderInput input={{ inputType: 'section', title: 'Exception Factors' }} Key="typeSection" onChange={() => {}} />
      <div className="mt-4">
        <FormTable
          header={factorListHeader}
          permission={1}
          inputs={factorListInputs}
          defaultData={factors}
          submitText="Add"
          onSubmit={onFactorListSubmit}
          onRemove={onFactorListRemove}
        />
      </div>
      <RenderInput
        input={{ inputType: 'section', title: 'Exception Submittion message' }}
        Key="typeSection"
        onChange={() => {}}
      />
      <div className="mt-4">
        <RenderInput
          input={{
            title: 'Submission Message',
            inputType: 'textarea',
            type: 'text',
            value: message,
            span: 2,
            rows: 2,
          }}
          Key="submissionMessage"
          onChange={(key: string, value: any) => setMessage(value)}
        />
      </div>
      <div className="flex justify-center mt-4">
        <Button onClick={onSubmit} loading={isLoading}>
          Save changes
        </Button>
      </div>
    </div>
  )
}
