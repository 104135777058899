import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'
import { PromptModal } from 'stories/components'

const PromptDialog = ({ show, proceed, promptation, options }: ReactConfirmProps | any) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  return (
    <PromptModal
      isOpen={show}
      onOk={(result: string) => proceed(result as any)}
      onCancel={() => proceed(false as any)}
      lastUpdatedAt={lastUpdatedAt}
      content={promptation}
      options={options}
    />
  )
}

const promptDlg = confirmable(PromptDialog)

const createPrompt = createConfirmation(promptDlg)

export const prompt = (content: string | JSX.Element, options: Record<string, any> = {}) =>
  createPrompt({ promptation: content, options })
