import type { InputType } from 'config'

export const EmailGroup: any = {
  loanSubmission: 'Loan Submission',
  loanAction: 'Loan Actions',
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'text',
      title: 'Email Subject',
      required: true,
    },
    group: {
      title: 'Email Group',
      inputType: 'Select',
      required: true,
      options: EmailGroup,
      hasDefaultOption: true,
    },
    content: {
      inputType: 'textarea',
      title: 'Email Content',
      required: true,
      rows: 15,
      span: 'full',
    },
  }
}
