import { SET_HEADER, SET_MENU } from 'actions/types'
import cloneDeep from 'clone-deep'

import { createReducer } from '../utils'

const getInitialState = () => {
  return {
    activeChangeFlag: true,
    menu: {},
  }
}

export const header = createReducer(getInitialState(), {
  [SET_HEADER]: (state: any, payload: any) => {
    let newState = cloneDeep(state)
    if (payload.data.activeChangeFlag) newState.activeChangeFlag = !newState.activeChangeFlag
    return newState
  },
  [SET_MENU]: (state: any, payload: any) => {
    let newState = cloneDeep(state)
    newState.menu = payload.data
    return newState
  },
})
