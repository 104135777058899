import { ListBulletIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { InputConvert, InputValidate, isEmpty, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, ILoanGlobalSettings } from './constants'
import { GlobalSettingsV2 } from './GlobalSettingsV2'
import { AppraisalStatusOrder, MinCommitmentFeeRequiredTypes } from './Modal'

export const GlobalSettings = () => {
  const [action, setAction] = useState('')
  const [inputs, setInputs] = useState(defaultInputs())
  const [modal, setModal] = useState('')
  const [globalSetting, setGlobalSetting] = useState<ILoanGlobalSettings>()

  useEffect(() => {
    setAction('setting')
    getSetting(SettingKey.GLOBAL_SETTINGS)
      .then(({ value }) => {
        const content = JSON.parse(value || '{}')
        setGlobalSetting(content)
        let newInputs = cloneDeep(inputs)
        Object.keys(inputs).map((key) => {
          if (newInputs[key].inputType === 'multiselect') newInputs[key].value = content[key] || {}
          else newInputs[key].value = content[key] || ''
        })
        setInputs(newInputs)
      })
      .finally(() => setAction(''))
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    if (!globalSetting) return
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      if (['appraisalStatisticsVisible', 'priceDisplay'].includes(key)) data[key] = newInputs[key].value
      else data[key] = removeComma(newInputs[key].value)
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    const newSettings = cloneDeep(globalSetting)
    Object.keys(newSettings).forEach((key) => {
      if (data[key] !== undefined) (newSettings as any)[key] = data[key]
    })

    setAction('setting')
    await updateSetting(SettingKey.GLOBAL_SETTINGS, JSON.stringify(newSettings))
    setAction('')
  }

  const onChangeAppraisalStatusOrder = async (newData: Record<string, boolean>) => {
    if (!globalSetting) return

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (key === 'appraisalStatisticsVisible') {
        data[key] = newData
        newInputs[key].value = newData
      }
    }
    setInputs(newInputs)

    const newSettings = cloneDeep(globalSetting)
    newSettings.appraisalStatisticsVisible = newData

    setAction('setting')
    await updateSetting(SettingKey.GLOBAL_SETTINGS, JSON.stringify(newSettings))
    setAction('')
    setModal('')
  }

  const onUpdateMinCommitmentFeeRequiredTypes = async (newTypes: any[]) => {
    if (!globalSetting) return

    const newSetting = cloneDeep(globalSetting)
    newSetting.minCommitmentFeeRequiredTypes = newTypes

    setAction('setting')
    await updateSetting(SettingKey.GLOBAL_SETTINGS, JSON.stringify(newSetting))
    setGlobalSetting(newSetting)
    setAction('')
    setModal('')
  }

  return (
    <div className="relative">
      <LayoutLoading show={!isEmpty(action)} />

      <div className="relative mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          let additionalElements = null

          if (key === 'appraisalStatisticsVisible')
            additionalElements = (
              <Tooltip message="Change Order">
                <ListBulletIcon
                  className="w-5 h-5 text-shade-blue cursor-pointer"
                  onClick={() => setModal('appraisalStatusOrder')}
                />
              </Tooltip>
            )
          if (key === 'minCommitmentFeeAmount')
            additionalElements = (
              <Tooltip message="Edit">
                <PencilSquareIcon
                  className="w-4 h-4 text-shade-blue cursor-pointer"
                  onClick={() => setModal('minCommitmentRequiredType')}
                />
              </Tooltip>
            )

          return (
            <div className={`input md:col-span-${input.span || 1} `} key={index}>
              <RenderInput
                input={{ ...input, additionalElements }}
                Key={key}
                onChange={onChange}
                onBlur={() => onSubmit()}
              />
            </div>
          )
        })}
      </div>

      <GlobalSettingsV2 />

      {modal === 'appraisalStatusOrder' && (
        <AppraisalStatusOrder
          data={inputs.appraisalStatisticsVisible.value}
          isOpen={modal === 'appraisalStatusOrder'}
          onClose={() => setModal('')}
          onSubmit={onChangeAppraisalStatusOrder}
          loading={action}
        />
      )}
      {modal === 'minCommitmentRequiredType' && globalSetting && (
        <MinCommitmentFeeRequiredTypes
          data={globalSetting.minCommitmentFeeRequiredTypes || []}
          isOpen={modal === 'minCommitmentRequiredType'}
          onClose={() => setModal('')}
          loading={action}
          onSubmit={onUpdateMinCommitmentFeeRequiredTypes}
        />
      )}
    </div>
  )
}
