import { Overview } from 'components/Overview'

import { FeeWorkSheet } from '../ClosingScreen/FeeWorkSheet'

export const FeeWorkSheetPage = () => {
  return (
    <div className="closingScreen-container px-2 py-6">
      <Overview title="Fee Worksheet" />
      <div className="closingScreen-wrapper max-w-screen-2xl m-auto shadow1 p-6">
        <FeeWorkSheet />
      </div>
    </div>
  )
}
