import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { TaskAssigneeOptions } from 'pages/Tasks/constants'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { submitTask } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { taskInputs } from '../constant'
import type { ITaskTemplate } from './types'

export function TaskDetails(props: {
  defaults: ITaskTemplate | null
  lastUpdatedAt: number
  onBack: Function
  onComplete: Function
  adminEmails: string[]
}) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, any>>({})
  const { defaults = null, lastUpdatedAt, adminEmails } = props

  const assigneeOptions = useMemo(() => {
    const options = { ...TaskAssigneeOptions }
    adminEmails.forEach((email) => (options[email] = email))
    return options
  }, [adminEmails])

  useEffect(() => {
    if (!defaults) {
      setInputs(taskInputs())
      return
    }
    const newStats = cloneDeep(taskInputs()) as Record<string, any>
    for (const key in newStats) {
      newStats[key].value = (defaults as any)[key]
    }
    setInputs(newStats)
  }, [defaults, lastUpdatedAt])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (!disabled && value !== undefined) data[key] = value
      if (inputs[key].inputType === 'multiselect') {
        if (Array.isArray(value)) {
        } else {
          data[key] = Object.keys(value).filter((to: string) => value[to] && to)
        }
      }
    }

    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)
    const id = defaults ? defaults.id : 0
    submitTask(id, data)
      .then(() => {
        if (id == 0) toast('New task is added.', { type: 'success' })
        else toast(`Task No ${defaults?.no} is updated.`, { type: 'success' })
        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="task-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Tasks - {!defaults ? 'Add' : `Update No ${defaults.no}`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-1" /> <p>Return to Tasks</p>
        </div>
      </Button>

      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          if (input.inputType === 'multiselect') {
            input.options = assigneeOptions
          }
          return (
            <div className="input" key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'} Task</>
        </Button>
      </div>
    </div>
  )
}
