import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { AccountType, LoanStatusType } from 'config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdminConfig, setAdminConfig } from 'services'
import { Toggle, ToggleButton } from 'stories/components'
import { capitalizeLettersSeparatedBySlash, getCharFromIndex } from 'utils'
import { RenderInput } from 'utils/RenderInput'

type VisualAccountTypes = AccountType | 'self_creator'

export interface IWorkflowConfig {
  visibleAccountTypes?: VisualAccountTypes[]
  isActive: boolean
  disabledAtStatus?: LoanStatusType[]
  [taskName: string]:
    | {
        title: string
        enabled: boolean
      }
    | any
}

export type TWorkflowConfig = Record<string, Record<string, IWorkflowConfig>>

export const Workflows = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, Record<string, IWorkflowConfig>>>({})
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])
  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('workflows')
    setData(res)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onChange = async (titleKey: string, key: string, subKey: string, value: any) => {
    const temp = cloneDeep(data)
    temp[titleKey][key][subKey] = value
    if (subKey == 'enabled' && value == false && Object.keys(temp[titleKey][key]).includes('isActive'))
      temp[titleKey][key]['isActive'] = false
    setData(temp)
    setIsLoading(true)
    await setAdminConfig('workflows', temp)
    setIsLoading(false)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      {Object.keys(data).map((titleKey, titleIndex: number) => {
        const item = data[titleKey]
        return (
          <React.Fragment key={titleKey}>
            <div className="text-xl font-bold mb-4">
              {titleIndex + 2}. {capitalizeLettersSeparatedBySlash(titleKey)}
            </div>
            {Object.keys(item).map((key) => (
              <div className="mb-4" key={key}>
                <RenderInput
                  Key={key}
                  key={key}
                  input={{
                    inputType: 'section',
                    title: capitalizeLettersSeparatedBySlash(key),
                    additionalElements: (
                      <Toggle
                        id={key}
                        value={item[key].enabled}
                        onChange={(value) => onChange(titleKey, key, 'enabled', value)}
                        className="pr-4"
                      />
                    ),
                  }}
                  onChange={() => {}}
                />
                <div className="flex flex-wrap gap-2 items-center justify-between mt-4 p-2">
                  {item[key].visibleAccountTypes !== undefined && (
                    <div className="max-w-[400px] w-full">
                      <RenderInput
                        Key={key}
                        key={key}
                        input={{
                          inputType: 'multiselect',
                          title: 'Visible For',
                          options: { self_creator: '[Loan] Creator', ...accountTypes },
                          value: item[key].visibleAccountTypes,
                          defaultSelected: false,
                        }}
                        onChange={(key: string, value: any) => onChange(titleKey, key, 'visibleAccountTypes', value)}
                      />
                    </div>
                  )}
                  {item[key].disabledAtStatus !== undefined && (
                    <div className="max-w-[400px] w-full">
                      <RenderInput
                        Key={key}
                        key={key}
                        input={{
                          inputType: 'multiselect',
                          title: 'Disabled At',
                          options: loanGlobalStatus,
                          value: item[key].disabledAtStatus,
                          sort: true,
                          defaultSelected: false,
                        }}
                        onChange={(key: string, value: any) => onChange(titleKey, key, 'disabledAtStatus', value)}
                      />
                    </div>
                  )}
                  {Object.keys(item[key]).includes('isActive') && (
                    <div className="flex items-center gap-2">
                      <p>Is Active</p>
                      <ToggleButton
                        id={`${key}-isActive`}
                        value={item[key]['isActive']}
                        onChange={(value) => onChange(titleKey, key, 'isActive', value)}
                        disabled={!item[key]['enabled']}
                      />
                    </div>
                  )}
                </div>
                {Object.keys(item[key])
                  ?.filter(
                    (value) => !['visibleAccountTypes', 'isActive', 'disabledAtStatus', 'enabled'].includes(value),
                  )
                  ?.map((subKey: any, index: number) => (
                    <div
                      className={`flex justify-between items-center p-2 pl-4 ${index % 2 && 'bg-slate-100'}`}
                      key={`${key}-${index}`}
                    >
                      <div className="flex-1 flex justify-between items-center">
                        <p className="text-sm">
                          {getCharFromIndex(index)}. {item[key][subKey].title}
                        </p>
                        <ToggleButton
                          id={`${key}-${index}`}
                          value={item[key][subKey].enabled}
                          onChange={(value) =>
                            onChange(titleKey, key, subKey, { ...item[key][subKey], enabled: value })
                          }
                          disabled={!item[key]['isActive']}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </React.Fragment>
        )
      })}
    </div>
  )
}
