export enum LoanStatusType {
  PREQUAL = 'prequal',
  APPSIGNED = 'appSigned',
  RESTRUCTURERELEASE = 'restructureRelease',
  LOAN_INTAKE_REVIEW = 'inTakeReview',
  LOAN_SETUP_ON_HOLD = 'loanSetupOnHold',
  APPLICATION = 'application',
  SUBMITTED = 'submitted',
  SUSPENDED = 'suspended',
  APPROVED = 'approved',
  RE_SUBMITTED = 'reSubmitted',
  UNDERWRITER_REVIEW = 'underwriterReview',
  DECLINED = 'declined',
  CLEAR_TO_CLOSE = 'clearToClose',
  READY_FOR_DOCS = 'readyForDocs',
  DOCS_OUT = 'docsOut',
  CLOSED = 'closed',
  CLOSEDLOANREVIEW = 'closedLoanReview',
  READYFORPURCHASE = 'readyForPurchase',
  FUNDED = 'funded',
  WITHDRAWN = 'withdrawn',
}

export const loanStatusConstants: any = {
  loanSetupUnHoldStatus: ['prequal', 'appSigned', 'restructureRelease', 'loanSetupOnHold'],
  closeStatus: ['clearToClose', 'readyForDocs', 'docsOut', 'closed', 'funded', 'withdrawn'],
  archivedStatus: ['funded', 'withdrawn', 'declined'],
  activeStatus: [
    'prequal',
    'appSigned',
    'restructureRelease',
    'inTakeReview',
    'loanSetupOnHold',
    'application',
    'submitted',
    'suspended',
    'approved',
    'reSubmitted',
    'underwriterReview',
    'clearToClose',
    'readyForDocs',
    'docsOut',
    'closed',
    'closedLoanReview',
    'readyForPurchase',
  ],
  suspendedStatus: [
    'suspended',
    'approved',
    'reSubmitted',
    'underwriterReview',
    'declined',
    'clearToClose',
    'readyForDocs',
    'docsOut',
    'closed',
    'closedLoanReview',
    'readyForPurchase',
    'funded',
    'withdrawn',
  ],
  brokerCanResubmitConditions: [
    'approved',
    'suspended',
    'declined',
    'clearToClose',
    'readyForDocs',
    'docsOut',
    'closed',
    'closedLoanReview',
    'readyForPurchase',
    'funded',
    'withdrawn',
  ],
  servicingStatus: {
    servicing: 'Servicing',
    reo: 'REO',
    purchased: 'Purchased',
    paidInFull: 'Paid in Full',
  },
}
