export const CustomContextMenu = ({
  x,
  y,
  onClose,
  valueKey,
  value,
  showHistory,
}: {
  x: number
  y: number
  onClose: Function
  valueKey: string
  value: any
  showHistory: Function
}) => {
  return (
    <ul
      className="absolute list-none bg-sky-50 py-2 text-sm min-w-[200px] shadow1 rounded-md"
      style={{
        top: y,
        left: x,
      }}
    >
      <li className="px-4 hover:bg-slate-300 cursor-pointer py-1" onClick={() => onClose({ key: valueKey, value })}>
        Edit Notes...
      </li>
      <li className="px-4 hover:bg-slate-300 cursor-pointer py-1" onClick={() => showHistory([valueKey])}>
        Show Field History...
      </li>
    </ul>
  )
}
