import {
  API_APPRAISAL_INFORMATION,
  API_APPRAISAL_INFORMATION_UPDATE,
  API_LOAN_APPRAISAL_INFORMATION_FEED_V2,
  API_LOAN_APPRAISAL_ORDER_DETAILS,
  // API_LOAN_APPRAISAL_PAY_INVOICE,
  API_LOAN_APPRAISAL2_LOG,
  API_LOAN_APPRAISAL2_STATUS_CHANGE,
  API_LOAN_CANCEL_APPRAISAL_INFO_V2,
  API_LOAN_CREATE_APPRAISAL_INFO_V2,
  API_LOAN_CREATE_APPRAISAL_V2_CREATE_LOG,
  API_LOAN_DELETE_APPRAISAL_INFO_V2,
  API_LOAN_ELITE_PROGRESS_STATUS,
  API_LOAN_GET_APPRAISAL_COMMENTS,
  API_LOAN_GET_BORROWER_ELITE_VALUATION_KEY,
  API_LOAN_GET_ELITE_VALUATION_COMMENTS,
  API_LOAN_GET_ELITE_VALUATION_DETAILS,
  API_LOAN_GET_ELITE_VALUATION_ORDER_PROGRESS,
  API_LOAN_GET_NATIONWIDE_COMMENTS,
  API_LOAN_GET_TRINITY_COMMENTS_LIENS,
  API_LOAN_ORDER_VALUATION,
  API_LOAN_ORDER_VALUATION_FEASIBILITY_SIGN_REQUEST,
  API_LOAN_POST_APPRAISAL_COMMENTS,
  // API_LOAN_POST_APPRAISAL_ORDER,
  API_LOAN_POST_APPRAISAL2_DOCUMENTS,
  API_LOAN_POST_APPRAISAL2_PAY_OR_INVOICE,
  API_LOAN_POST_ELITE_VALUATION,
  API_LOAN_POST_ELITE_VALUATION_COMMENTS,
  API_LOAN_POST_ELITE_VALUATION_DOCUMENTS,
  API_LOAN_POST_NATIONWIDE_COMMENTS,
  API_LOAN_POST_TRINITY_COMMENTS,
  API_LOAN_POST_TRINITY_DOCUMENTS,
  API_LOAN_POST_TRINITY_REPORT_TO_DOCSTORAGE,
  API_LOAN_SYNC_ORDER_APPRAISAL_INFO,
  API_LOAN_TRINITY_PROGRESS_STATUS,
  API_LOAN_UPDATE_APPRAISAL_INFO_V2,
  API_LOAN_USER_CHECK_APPRAISAL_NATION,
  API_LOAN_USER_CHECK_ELITE_VALUATION,
  API_LOAN_USER_CHECK_NATIONWIDE,
  API_LOAN_USER_GET_APPRAISAL_NATION_CREDENTIAL,
  API_LOAN_USER_GET_ELITE_VALUATION_CREDENTIAL,
  API_LOAN_USER_GET_NATION_WIDE_CREDENTIAL,
} from 'config'
import { IntegrationType } from 'pages/Loan'
import Api from 'services/api'

export const deleteAppraisalInfoV2 = (id: string) => {
  return Api.delete(API_LOAN_DELETE_APPRAISAL_INFO_V2, {}, { id })
}

export const cancelAppraisalInfoV2 = (data: any) => {
  return Api.post(API_LOAN_CANCEL_APPRAISAL_INFO_V2, data)
}

export const updateAppraisalInfoV2 = (id: string, data: Record<string, any>) => {
  return Api.put(API_LOAN_UPDATE_APPRAISAL_INFO_V2, data, { id }, { timeout: 150000 })
}

export const getAppraisalInformationFeedV2 = () => {
  return Api.get(API_LOAN_APPRAISAL_INFORMATION_FEED_V2)
}

export const sendOrderAppraisalFeasibilitySignRequest = (id: number, data: Record<string, any>) => {
  return Api.post(API_LOAN_ORDER_VALUATION_FEASIBILITY_SIGN_REQUEST, data, { id })
}

export const getOrderValuation = (id: number) => {
  return Api.get(API_LOAN_ORDER_VALUATION, {}, { id })
}

export const createAppraisalInfoV2 = (data: any) => {
  return Api.post(API_LOAN_CREATE_APPRAISAL_INFO_V2, data)
}

export const changeAppraisal2Status = (data: any) => {
  return Api.post(API_LOAN_APPRAISAL2_STATUS_CHANGE, data)
}

export const appraisalV2CreateLog = (data: any) => {
  return Api.post(API_LOAN_CREATE_APPRAISAL_V2_CREATE_LOG, data)
}

export const getAppraisalOrderDetails = () => {
  return Api.get(API_LOAN_APPRAISAL_ORDER_DETAILS)
}

// export const postAppraisalNation = (data: object) => {
//   return Api.post(API_LOAN_POST_APPRAISAL_ORDER, data)
// }

export const syncOrderAppraisalsInfo = (data: object) => {
  return Api.post(API_LOAN_SYNC_ORDER_APPRAISAL_INFO, data)
}

export const getAppraisalComments = (appraisalID: number) => {
  return Api.get(API_LOAN_GET_APPRAISAL_COMMENTS, {}, { appraisalID })
}

export const postAppraisalComments = (data: object) => {
  return Api.post(API_LOAN_POST_APPRAISAL_COMMENTS, data)
}

export const postAppraisal2Documents = (data: object) => {
  return Api.post(API_LOAN_POST_APPRAISAL2_DOCUMENTS, data, {}, { timeout: 150000 })
}

export const postAppraisal2PayOrInvoice = (data: object) => {
  return Api.post(API_LOAN_POST_APPRAISAL2_PAY_OR_INVOICE, data)
}

// export const appraisalPayOrInvoice = (data: object) => {
//   return Api.post(API_LOAN_APPRAISAL_PAY_INVOICE, data)
// }

export const getNewAppraisalInformation = () => {
  return Api.get(API_APPRAISAL_INFORMATION)
}

export const addNewAppraisalInformation = (data: Record<string, any>) => {
  return Api.post(API_APPRAISAL_INFORMATION, data)
}

export const updateAppraisalInformation = (id: number, data: Record<string, any>) => {
  return Api.put(API_APPRAISAL_INFORMATION_UPDATE, data, { id })
}

export const deleteAppraisalInformation = (id: number) => {
  return Api.delete(API_APPRAISAL_INFORMATION_UPDATE, {}, { id })
}

export const getAppraisal2Logs = (key: string, id: number) => {
  return Api.get(API_LOAN_APPRAISAL2_LOG, {}, { key, id })
}

/** Trinity */

export const getTrinityProgressStatus = (TrinityOrderId: number, OrderId: number) => {
  return Api.get(API_LOAN_TRINITY_PROGRESS_STATUS, {}, { TrinityOrderId, OrderId })
}

export const sendTrinityReportToDocStorage = (OrderId: number, data: any) => {
  return Api.post(API_LOAN_POST_TRINITY_REPORT_TO_DOCSTORAGE, data, { OrderId })
}

export const getTrinityCommentsLiens = (TrinityOrderId: number, OrderId: number) => {
  return Api.get(API_LOAN_GET_TRINITY_COMMENTS_LIENS, {}, { TrinityOrderId, OrderId })
}

export const postTrinityComments = (data: object) => {
  return Api.post(API_LOAN_POST_TRINITY_COMMENTS, data)
}

export const postTrinityDocuments = (data: object) => {
  return Api.post(API_LOAN_POST_TRINITY_DOCUMENTS, data, {}, { timeout: 150000 })
}

/** Elite */

export const getEliteProgressStatus = (OrderId: number) => {
  return Api.get(API_LOAN_ELITE_PROGRESS_STATUS, {}, { OrderId })
}

export const getEliteValuationComments = (OrderId: number) => {
  return Api.get(API_LOAN_GET_ELITE_VALUATION_COMMENTS, {}, { OrderId })
}

export const postEliteValuationComments = (data: object) => {
  return Api.post(API_LOAN_POST_ELITE_VALUATION_COMMENTS, data)
}

export const postEliteValuationDocuments = (data: object) => {
  return Api.post(API_LOAN_POST_ELITE_VALUATION_DOCUMENTS, data, {}, { timeout: 150000 })
}

export const checkEliteValuation = (data: object) => {
  return Api.post(API_LOAN_USER_CHECK_ELITE_VALUATION, data)
}

export const getEliteValuationCredential = () => {
  return Api.get(API_LOAN_USER_GET_ELITE_VALUATION_CREDENTIAL)
}

export const getEliteValuationDetails = () => {
  return Api.get(API_LOAN_GET_ELITE_VALUATION_DETAILS)
}

export const postEliteValuation = (data: object) => {
  return Api.post(API_LOAN_POST_ELITE_VALUATION, data)
}

export const getEliteValuationOrderProgress = (orderID: number) => {
  return Api.get(API_LOAN_GET_ELITE_VALUATION_ORDER_PROGRESS, {}, { orderID })
}

export const getAppraisalCredential = () => {
  return Api.get(API_LOAN_USER_GET_APPRAISAL_NATION_CREDENTIAL)
}

export const checkAppraisalValuation = (data: object) => {
  return Api.post(API_LOAN_USER_CHECK_APPRAISAL_NATION, data)
}

export const getBorrowerEliteCredential = (loanNumber: number, integrationType: IntegrationType) => {
  return Api.get(API_LOAN_GET_BORROWER_ELITE_VALUATION_KEY, { loanNumber, integrationType }, { timeout: 150000 })
}

export const getNationWideCredential = () => {
  return Api.get(API_LOAN_USER_GET_NATION_WIDE_CREDENTIAL)
}

export const checkNationWide = (data: object) => {
  return Api.post(API_LOAN_USER_CHECK_NATIONWIDE, data)
}

export const getNationWideComments = (appraisalID: number) => {
  return Api.get(API_LOAN_GET_NATIONWIDE_COMMENTS, {}, { appraisalID })
}

export const postNationWideComments = (data: object) => {
  return Api.post(API_LOAN_POST_NATIONWIDE_COMMENTS, data)
}
