import { LOAN_SET_GLOBAL_STATUS } from 'actions'

import { createReducer } from '../utils'

const initialState = {
  prequal: 'Prequal',
  appSigned: 'Application Signed',
  restructureRelease: 'Restructure Release',
  inTakeReview: 'Loan Intake Review',
  loanSetupOnHold: 'Loan Setup on Hold',
  application: 'Loan Setup',
  submitted: 'Submitted',
  suspended: 'Suspended',
  approved: 'Approved',
  reSubmitted: 'Resubmitted',
  underwriterReview: 'Underwriter Review',
  clearToClose: 'Clear to Close',
  readyForDocs: 'Ready for Docs',
  docsOut: 'Docs Out',
  closed: 'Closed',
  closedLoanReview: 'Closed Loan Review',
  readyForPurchase: 'Ready for Purchase',
  funded: 'Funded',
  declined: 'Declined',
  withdrawn: 'Withdrawn',
}

export const loanGlobalStatus = createReducer(initialState, {
  [LOAN_SET_GLOBAL_STATUS]: (state: any, payload: any) => {
    return payload.data
  },
})
