import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAdminConfig, getAdminEmails, setAdminConfig } from 'services'
import { InputConvert } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { getRulesConfigurationFields } from './constants'

export const RulesConfiguration = () => {
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])

  const [inputs, setInputs] = useState<Record<string, any>>(getRulesConfigurationFields(loanGlobalStatus))
  const [isLoading, setLoading] = useState(false)
  const [adminEmails, setAdminEmails] = useState<string[]>([])

  useEffect(() => {
    setLoading(true)
    getAdminConfig('rules')
      .then((value) => {
        let newInputs = cloneDeep(inputs)
        Object.keys(inputs).forEach((key) => {
          newInputs[key].value = value[key] || ''
        })
        setInputs(newInputs)
      })
      .finally(() => setLoading(false))

    getAdminEmails().then((emails: string[]) => setAdminEmails(emails))
  }, [])

  const assigneeOptions = useMemo(() => {
    const options = { ...accountTypes }
    adminEmails.forEach((email) => (options[email] = email))
    return options
  }, [adminEmails])

  const onChange = (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const data: Record<string, any> = {}
    Object.keys(inputs).map((key) => {
      if (inputs[key].inputType === 'section') return
      data[key] = inputs[key].value
    })

    setLoading(true)
    await setAdminConfig('rules', data)
    setLoading(false)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />

      <div className="grid grid-cols-1 md:grid-cols-2 sm:gird-cols-2 gap-2">
        {Object.keys(inputs).map((key, index) => {
          const item = inputs[key]

          if (key === 'emailWithdrawnLoans') {
            ;(item as any).options = assigneeOptions
          }
          return (
            <div className={`input md:col-span-${item.span || 1} `} key={index}>
              <RenderInput input={item} Key={key} onChange={onChange} onBlur={onSubmit} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
