import type { InputType } from 'config'

export const DownloadOptions = {
  zip: 'PDF in Zip',
  pdf: 'Single PDF',
  docx: 'Docx in Zip',
}

export const defaultInputs = (): Record<string, InputType> => ({
  nameAuthorizedSignatory: {
    inputType: 'text',
    title: 'Name (Authorized Signatory)',
    required: true,
  },
  // titleAuthorizedSignatory: {
  //   inputType: 'text',
  //   title: 'Title (Authorized Signatory)',
  //   required: true,
  // },
  // customerPosition: {
  //   inputType: 'textarea',
  //   title: 'Customer position in a company (for business package only)',
  //   rows: 1,
  //   required: true,
  // },
  interestType: {
    title: 'Interest Type',
    inputType: 'select',
    hasDefaultOption: true,
    options: ['Dutch', 'Non Dutch'],
    required: true,
    history: false,
  },
  escrowType: {
    title: 'Escrow Type',
    inputType: 'select',
    hasDefaultOption: true,
    options: ['Not Waived', 'Waive All', 'Waive Taxes', 'Waive Insurance', 'Waive Taxes and Insurance'],
    required: true,
  },
  apnNumber: {
    inputType: 'text',
    type: 'number',
    title: 'APN number',
    required: true,
  },
  maturityDate: {
    inputType: 'text',
    type: 'date',
    title: 'Maturity date',
    required: true,
  },
  mortgageRecordingDate: {
    inputType: 'text',
    type: 'date',
    title: 'Mortgage Recording Date',
    disabled: true,
  },
  mortgageRecordingDocumentNumber: {
    inputType: 'text',
    type: 'number',
    prefix: '#',
    title: 'Mortgage Recording document number',
    disabled: true,
  },
  mortgageClause: {
    inputType: 'select',
    title: 'Mortgage clause',
    options: ['Table Funder Account', 'Lender Account'],
    hasDefaultOption: true,
    required: true,
  },
  fundingDate: {
    inputType: 'text',
    type: 'date',
    title: 'Funding date',
    required: true,
  },
  latePercentage: {
    inputType: 'text',
    type: 'number',
    prefix: '%',
    title: 'Late percentage',
    required: true,
  },
  LateChargeMin: {
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    value: '100',
    title: 'Minimum Late Charge',
    required: true,
  },
  gracePeriod: {
    inputType: 'text',
    type: 'number',
    value: 10,
    title: 'Grace Period',
    required: true,
  },
  dailyLateCharge: {
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    title: 'Daily Late Charge',
    disabled: true,
    required: true,
  },
  prepayment: {
    inputType: 'select',
    title: 'Prepayment',
    required: true,
    hasDefaultOption: true,
    options: {},
  },
  lienPosition: {
    inputType: 'select',
    title: 'Lien Position',
    options: ['First', 'Second', 'Third'],
    hasDefaultOption: true,
    required: true,
  },
  titleOrderNumber: {
    inputType: 'text',
    title: 'Escrow Account Number',
    required: true,
  },
  titleDate: {
    inputType: 'text',
    type: 'date',
    title: 'Title date',
    required: true,
  },
  acquisitionStatus: {
    inputType: 'select',
    title: 'Acquisition status',
    options: ['Property you own', 'New acquisition', 'Construction'],
    hasDefaultOption: true,
    required: true,
  },
  closingDocumentDate: {
    inputType: 'text',
    type: 'date',
    title: 'Closing / Document Date',
    required: true,
  },
  FirstPaymentDate: {
    title: 'First Payment Date',
    inputType: 'text',
    type: 'date',
    required: true,
  },
  numberOfExtensions: {
    inputType: 'text',
    type: 'number',
    title: 'Number of Allowable Extensions',
    value: 2,
    required: true,
  },
  extensionFeePerc: {
    inputType: 'text',
    type: 'text',
    title: 'Extension Fee Percent',
    value: '',
    prefix: '%',
    required: true,
  },
  titleAccountNumber: {
    inputType: 'text',
    type: 'text',
    title: 'Title Account Number',
    value: '',
    required: true,
  },
  IncrementOfExtensions: {
    inputType: 'text',
    type: 'number',
    title: 'Increment of Extensions',
    value: 3,
    required: true,
  },
  personalGuaranty: {
    inputType: 'select',
    title: 'Personal Guaranty',
    options: ['Full Recourse', 'Non Recourse', 'Non Recourse with Bad Boy carveouts'],
    hasDefaultOption: true,
    required: true,
  },
  defaultRate: {
    inputType: 'text',
    type: 'number',
    prefix: '%',
    title: 'Default Rate',
    value: 3,
    required: true,
  },
  drawStructure: {
    title: 'Draw Structure',
    inputType: 'select',
    hasDefaultOption: true,
    options: ['Single', 'Multiple'],
    required: true,
    history: false,
  },
  downloadOption: {
    inputType: 'radio',
    title: 'Download Option',
    options: DownloadOptions,
    span: 2,
    required: true,
  },
})
