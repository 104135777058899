import cloneDeep from 'clone-deep'

export interface IBorrowerLoanWorkflowBase {
  title: string
  visibleLogic?: IVisibilityOption[][]
}

export enum FieldOrigin {
  LoanStructure = 'LoanStructure',
  BorrowerInformation = 'BorrowerInformation',
  PropertyInformation = 'PropertyInformation',
  AssetLiability = 'AssetLiability',
  TrackRecord = 'TrackRecord',
  DeclarationsHMDA = 'DeclarationsHMDA',
  Custom = 'Custom',
}

export enum CustomFieldType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Select = 'select',
  Toggle = 'toggle',
}

export const CustomFieldTypeOptions: Record<CustomFieldType, string> = {
  [CustomFieldType.Text]: 'Text',
  [CustomFieldType.Number]: 'Number',
  [CustomFieldType.Date]: 'Date',
  [CustomFieldType.Select]: 'Select',
  [CustomFieldType.Toggle]: 'Toggle',
}

export const fieldOriginOptions: Record<FieldOrigin, string> = {
  [FieldOrigin.LoanStructure]: 'Loan Structure',
  [FieldOrigin.BorrowerInformation]: 'Borrower Information',
  [FieldOrigin.PropertyInformation]: 'Property Information',
  [FieldOrigin.AssetLiability]: 'Asset & Liability',
  [FieldOrigin.TrackRecord]: 'Track Record',
  [FieldOrigin.DeclarationsHMDA]: 'Declarations & HMDA',
  [FieldOrigin.Custom]: 'Custom',
}

export const fieldOriginOptionsExceptCustom = cloneDeep(fieldOriginOptions)
delete (fieldOriginOptionsExceptCustom as any)[FieldOrigin.Custom]

export interface IBorrowerLoanWorkflowSelect extends IBorrowerLoanWorkflowInput {
  type: 'select'
  options: Record<string, string>
  visibility: Record<string, boolean> // Visibility for each options
}

export interface IVisibilityOption {
  fieldOrigin: FieldOrigin
  fieldKey: string
  value: string | number | boolean
}

export interface IBorrowerLoanWorkflowInput extends IBorrowerLoanWorkflowBase {
  fieldOrigin: FieldOrigin
  fieldKey: string
}

export interface IBorrowerLoanWorkflowCustomInput extends IBorrowerLoanWorkflowInput {
  fieldOrigin: FieldOrigin.Custom
  fieldType: CustomFieldType
  linkedFieldOrigin?: FieldOrigin
  linkedFieldKey?: string
}

export interface IBorrowerLoanWorkflowCustomInputText extends IBorrowerLoanWorkflowCustomInput {
  fieldType: CustomFieldType.Text | CustomFieldType.Date | CustomFieldType.Number
}

export interface IBorrowerLoanWorkflowCustomInputSelect extends IBorrowerLoanWorkflowCustomInput {
  fieldType: CustomFieldType.Select
  options: string[]
  visibility: Record<string, boolean> // Visibility for each options
}

export interface IBorrowerLoanWorkflowCustomInputToggle extends IBorrowerLoanWorkflowCustomInput {
  fieldType: CustomFieldType.Toggle
}

export type IBorrowerLoanWorkflowCustomInputs =
  | IBorrowerLoanWorkflowCustomInputText
  | IBorrowerLoanWorkflowCustomInputSelect
  | IBorrowerLoanWorkflowCustomInputToggle

export type IBorrowerLoanWorkflowControl =
  | IBorrowerLoanWorkflowSelect
  | IBorrowerLoanWorkflowInput
  | IBorrowerLoanWorkflowCustomInputs

export interface IBorrowerLoanWorkflowForm extends IBorrowerLoanWorkflowBase {
  type: 'form'
  inputs: IBorrowerLoanWorkflowControl[]
}

export type IBorrowerLoanWorkflowStep = IBorrowerLoanWorkflowSelect | IBorrowerLoanWorkflowForm
