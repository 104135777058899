import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import cloneDeep from 'clone-deep'
import { PaymentTermsConditions } from 'components/PaymentTermsConditions'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { getItemsFromFullAddress, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const defaultInputs = (): Record<string, InputType> => {
  return {
    name: {
      inputType: 'text',
      type: 'text',
      title: 'Holder Name',
      required: true,
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Mobile Phone Number',
      required: true,
    },
    address: {
      inputType: 'map',
      title: 'Billing Address',
      required: true,
      span: 'full',
      allowForeign: true,
    },
  }
}

export default function CheckoutForm({
  email,
  onReady,
  amount,
  lenderName,
  onCompleted,
}: {
  email: string
  onReady: Function
  amount?: string | number
  lenderName?: string
  onCompleted: Function
}) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | null | undefined>(null)
  const [messageColor, setMessageColor] = useState('gray')
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setCompleted] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [isTermsAgreed, setIsTermsAgreed] = useState(false)

  useEffect(() => {
    if (isCompleted) onCompleted()
  }, [isCompleted])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    let hasError = false
    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (newInputs[key].value && newInputs[key].value.trim) newInputs[key].value = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    if (hasError) {
      setInputs(newInputs)
      return
    }

    setMessage('')
    setMessageColor('blue')
    setIsLoading(true)

    const addressObj = await getItemsFromFullAddress(data.address)
    if (addressObj.country == 'USA') addressObj.country = 'US'

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email,
            name: data.name,
            phone: data.phone,
            address: {
              line1: addressObj.street_address1,
              city: addressObj.city,
              postal_code: addressObj.postal_code,
              state: addressObj.state,
              country: addressObj.country,
            },
          },
        },
      },
    })
    console.log('paymentIntent', error, paymentIntent)
    if (!error && paymentIntent) {
      console.log('ID', paymentIntent.id)
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          setMessageColor('green')
          toast('Payment is succeeded!', { type: 'success' })
          setCompleted(true)
          break
        case 'processing':
          setMessage('Your payment is processing.')
          setMessageColor('blue')
          toast('Your payment is processing. We will send the confirmation email.', { type: 'info' })
          setCompleted(true)
          break
        case 'requires_payment_method':
          setMessageColor('yellow')
          setMessage('Your payment was not successful, please try again.')
          setCompleted(true)
          break
        case 'requires_action':
          console.log('paymentIntent.next_action', paymentIntent.next_action)
          if (paymentIntent.next_action && paymentIntent.next_action.type == 'verify_with_microdeposits') {
            window.open(
              (paymentIntent.next_action as any).verify_with_microdeposits.hosted_verification_url as any,
              '_blank',
            )
            toast('Your payment is processing. Please complete the payment transaction.', { type: 'info' })
          }
          setCompleted(true)
          break
        default:
          setMessageColor('red')
          setMessage('Something went wrong.')
          break
      }
      setIsLoading(false)
      return
    }

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
      setMessageColor('red')
    } else {
      setMessageColor('red')
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="grid md:grid-cols-2 gap-x-4">
        {Object.keys(inputs).map((key, index) => {
          return (
            <div className={`input mb-4 ${`col-span-${inputs[key].span || 1}`}`} key={index}>
              <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>

      <PaymentElement
        id="payment-element"
        options={{
          layout: 'tabs',
          defaultValues: {
            billingDetails: { email, address: { country: 'United States' } },
          },
        }}
        onReady={() => onReady()}
      />

      <div className="mt-8">
        <PaymentTermsConditions onChangeTerms={setIsTermsAgreed} amount={amount} lenderName={lenderName} />
      </div>

      {!isTermsAgreed && (
        <p className="text-gray-500 mt-4 text-[15px] font-light">
          Please confirm Payment Terms and Conditions before you pay.
        </p>
      )}

      <Button
        id="submit"
        type="submit"
        disabled={isLoading || !stripe || !elements || isCompleted || !isTermsAgreed}
        loading={isLoading}
        className="mt-6 mb-2"
      >
        Pay Now
      </Button>

      {message && (
        <div className={`input mb-4`}>
          <div
            className={`bg-${messageColor}-100 border border-${messageColor}-400 text-${messageColor}-700 px-4 py-3 rounded relative text-[15px] mt-4`}
            role="alert"
          >
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>
      )}
    </form>
  )
}
