import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef } from 'react'

export const HorzScrollContainer = ({
  children,
  size = 0,
  width = 14,
}: {
  children: JSX.Element
  size?: number
  width?: number
}) => {
  const refContent = useRef<any>()
  const refScrollLeft = useRef<any>()
  const refScrollRight = useRef<any>()
  const scrollStepWidth = 250

  useEffect(() => {
    const tableContent: HTMLDivElement = refContent.current

    tableContent.addEventListener('scroll', onScrollListener)
    tableContent.addEventListener('resize', onScrollListener)
    window.addEventListener('resize', onScrollListener)

    return () => {
      tableContent.removeEventListener('scroll', onScrollListener)
      tableContent.removeEventListener('resize', onScrollListener)
      window.removeEventListener('resize', onScrollListener)
    }
  }, [])

  useEffect(() => onScrollListener(), [size])

  const onScrollListener = () => {
    const tableContent: HTMLDivElement = refContent.current
    const leftScroller: HTMLDivElement = refScrollLeft.current
    const rightScroller: HTMLDivElement = refScrollRight.current

    const { clientWidth, scrollWidth, scrollLeft } = tableContent
    if (clientWidth >= scrollWidth) {
      leftScroller.style.display = 'none'
      rightScroller.style.display = 'none'
      return
    }
    if (scrollLeft == 0) leftScroller.style.display = 'none'
    else leftScroller.style.display = 'block'

    if (scrollLeft >= scrollWidth - clientWidth - 2) rightScroller.style.display = 'none'
    else rightScroller.style.display = 'block'
  }

  const onScroll = (offset: number) => {
    const tableContent: HTMLDivElement = refContent.current
    tableContent.scrollBy({ left: offset, behavior: 'smooth' })
  }

  return (
    <div className="relative">
      <div
        className={`absolute w-${width} left-0 top-0 bottom-0 px-${
          (width - 6) / 2
        } bg-gradient-to-r from-white to-[#e5e7eb80] hover:to-[#ffffff7f] cursor-pointer z-40`}
        onClick={() => onScroll(-1 * scrollStepWidth)}
        ref={refScrollLeft}
      >
        <ChevronLeftIcon className="w-6 h-6 absolute top-[10%]" />
        <ChevronLeftIcon className="w-6 h-6 absolute top-[50%]" />
        <ChevronLeftIcon className="w-6 h-6 absolute top-[90%]" />
      </div>
      <div
        className={`absolute w-${width} right-0 top-0 bottom-0 px-${
          (width - 6) / 2
        } bg-gradient-to-l from-white to-[#e5e7eb80] hover:to-[#ffffff7f] cursor-pointer z-40`}
        onClick={() => onScroll(scrollStepWidth)}
        ref={refScrollRight}
      >
        <ChevronRightIcon className="w-6 h-6 absolute top-[10%]" />
        <ChevronRightIcon className="w-6 h-6 absolute top-[50%]" />
        <ChevronRightIcon className="w-6 h-6 absolute top-[90%]" />
      </div>

      <div className="overflow-auto" ref={refContent}>
        {children}
      </div>
    </div>
  )
}
