import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services'
import { Checkbox } from 'stories/components/Checkbox/Checkbox'

export const LoanStatusLabel = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<any>({})
  useEffect(() => {
    setLoading(true)
    getSetting(SettingKey.LOAN_STATUS_LABEL)
      .then(({ value }) => {
        const content = JSON.parse(value || '{}')
        setData(content)
      })
      .finally(() => setLoading(false))
  }, [])

  const onSync = async (_data: any) => {
    setLoading(true)
    await updateSetting(SettingKey.LOAN_STATUS_LABEL, JSON.stringify(_data))
    setLoading(false)
  }

  const onChange = (key: string, value: any, map: string) => {
    let temp = cloneDeep(data)
    if (temp[key] === undefined) temp[key] = {}
    temp[key][map] = value
    if (map === 'new' && value.length === 0) temp[key]['use'] = false
    setData(temp)
    onSync(temp)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <div className="w-fit mt-4">
        <table className="w-full text-sm">
          <thead className="uppercase text-xs bg-gray-50 dark:bg-gray-700">
            <tr>
              {['No', 'Origin Status', 'New Status', 'In Use'].map((v) => (
                <th scope="col" className="p-3 border" key={`header-${v}`}>
                  {v}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((key, index) => {
              const item = data[key] || {}
              return (
                <tr key={index}>
                  <td className="p-2 border">{index + 1}</td>
                  <td className="px-3 border">{item.origin}</td>
                  <td className="px-3 border">
                    <PlainInput
                      value={item.new}
                      content={item.new}
                      onChange={(newValue: string) => onChange(key, newValue, 'new')}
                    />
                  </td>
                  <td className="px-3 border text-center">
                    <Checkbox
                      color="gray"
                      id={key}
                      title={''}
                      key={key}
                      value={item.use}
                      onChange={(value) => onChange(key, value, 'use')}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
