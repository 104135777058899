import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { feePercent, renderStripeAmountDetails, Stripe } from 'components/Stripe'
import { LoanProcessRushPaymentRequestTypeTitles } from 'pages/LoanSubmission/constants'
import { useMemo, useState } from 'react'
import {
  type IMakePaymentData,
  PaymentOrderType,
  StripeIntentPaidStatus,
  StripeIntentStatus,
  StripeMethod,
  updatePaymentStatus,
} from 'services'
import { ButtonGroup } from 'stories/components'

const methodTitles = {
  [StripeMethod.card]: 'Credit Card',
  [StripeMethod.us_bank_account]: 'ACH',
}

export const StripeWithMethod = ({
  availableMethods = [StripeMethod.card, StripeMethod.us_bank_account],
  hasAdditionalBankMethod = false,
  paymentData,
  paymentDetails,
  onCompleted,
}: {
  availableMethods?: StripeMethod[]
  hasAdditionalBankMethod?: boolean
  paymentData: IMakePaymentData
  paymentDetails?: Record<string, any>
  onCompleted: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [method, setMethod] = useState<StripeMethod | undefined>(
    availableMethods.length == 1 ? availableMethods[0] : undefined,
  )
  const [status, setStatus] = useState(paymentDetails ? paymentDetails.status : '')

  const strTitle = useMemo(() => {
    if (![PaymentOrderType.LoanSubmissionRush, PaymentOrderType.CounterPartyApproval].includes(paymentData.orderType))
      return ''
    return LoanProcessRushPaymentRequestTypeTitles[paymentData.requestType] || paymentData.feeTitle
  }, [paymentData])

  const isShowBankLink = useMemo(() => {
    if (
      availableMethods.includes(StripeMethod.us_bank_account) ||
      method != StripeMethod.card ||
      !hasAdditionalBankMethod
    )
      return false
    return true
  }, [availableMethods, method, hasAdditionalBankMethod])

  const isShowCardLink = useMemo(() => {
    if (method != StripeMethod.us_bank_account || !hasAdditionalBankMethod) return false
    return true
  }, [method, hasAdditionalBankMethod])

  const onPayCompleted = async (intentId: string) => {
    setLoading(true)

    updatePaymentStatus(intentId)
      .then(({ status, message }) => {
        setStatus(status)
        onCompleted(intentId, status, message)
      })
      .finally(() => setLoading(false))
  }

  const statusTxt = useMemo(() => {
    if (!paymentDetails) return ''
    switch (paymentDetails.status) {
      case StripeIntentStatus.payment_failed:
      case StripeIntentStatus.requires_payment_method:
        return 'Failed'
      case StripeIntentStatus.succeeded:
        return 'Success'
    }
    return 'Processing'
  }, [paymentDetails])

  const isPaid = status && StripeIntentPaidStatus.includes(status)

  return (
    <div>
      <LayoutLoading show={isLoading} />

      {isPaid && (
        <div
          className="my-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px] flex items-center"
          role="alert"
        >
          <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
          <span className="ml-1">
            {status == StripeIntentStatus.succeeded
              ? 'Successfully Paid.'
              : `We've received your payment request. This will be completed in 1~2 days.`}
          </span>
        </div>
      )}

      {paymentDetails &&
        paymentDetails.intentId &&
        [StripeIntentStatus.payment_failed, StripeIntentStatus.requires_payment_method].includes(status) &&
        !!paymentDetails.message && (
          <div
            className="my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] flex items-center"
            role="alert"
          >
            <XCircleIcon className="w-6 h-6"></XCircleIcon>
            <span className="ml-1">{paymentDetails.message}</span>
          </div>
        )}

      {paymentData && (
        <div className="flex items-center gap-2 mb-4">
          <p>Email:</p>
          <p className="text-lg text-black font-semibold">{paymentData?.email}</p>
        </div>
      )}

      {paymentData && strTitle && (
        <div className="flex items-center gap-2 mb-4">
          <p>Type:</p>
          <p className="text-lg text-black font-semibold">{strTitle}</p>
        </div>
      )}

      {!!status &&
        paymentDetails &&
        paymentDetails.intentId &&
        !!paymentData.email &&
        paymentData.email == paymentData?.email && (
          <>
            <div className="flex items-center gap-2 mb-4">
              <p>Payment Status:</p>
              <p className="text-lg text-black font-semibold">{statusTxt}</p>
            </div>
            <div className="flex items-center gap-2 mb-4">
              <p>Payment Intent:</p>
              <p className="text-lg text-black font-semibold">{paymentDetails.intentId}</p>
            </div>
          </>
        )}

      {/* <div className="flex items-center gap-2 mb-4">
        <p>Payment Amount:</p>
        <p className="text-lg text-black font-bold">$ {getPrice2decimal(paymentData.amount, false, true)}</p>
      </div> */}

      {renderStripeAmountDetails(paymentData.amount, method)}

      {!isPaid && (
        <>
          {availableMethods.length > 1 && (
            <div className="flex items-center gap-2 mb-4">
              <p className="mb-4">Payment Type:</p>
              <ButtonGroup title={methodTitles} value={method} className="mb-4" onChange={(v) => setMethod(v)} />
            </div>
          )}

          <Stripe
            key={method}
            orderType={paymentData.orderType as any}
            refId={paymentData.refId}
            email={paymentData.email}
            amount={Number(Number(paymentData.amount * (1 + (method ? feePercent[method] : 0))).toFixed(2))}
            method={method as any}
            data={paymentData}
            setLoading={setLoading}
            onCompleted={onPayCompleted}
            lenderName={paymentData.lenderName}
          />

          {isShowBankLink && (
            <p className="mt-2">
              - If you want to pay via ACH from your bank account,{' '}
              <span
                className="font-semibold text-shade-blue cursor-pointer hover:underline inline text-base"
                onClick={() => setMethod(StripeMethod.us_bank_account)}
              >
                Click Here
              </span>
              !
            </p>
          )}

          {isShowCardLink && (
            <p className="mt-2">
              - If you want to pay with Credit Card,{' '}
              <span
                className="font-semibold text-shade-blue cursor-pointer hover:underline inline text-base"
                onClick={() => setMethod(StripeMethod.card)}
              >
                Click Here
              </span>
              !
            </p>
          )}
        </>
      )}
    </div>
  )
}
