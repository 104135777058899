import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { type IMakePaymentData, getPaymentData, PaymentOrderType, PaymentOrderTypeTitles, StripeMethod } from 'services'

import { StripeWithMethod } from './StripeWithMethod'

export default function MakePaymentPage() {
  const urlParams: any = useParams()
  const { loanNumber, token } = urlParams
  const [isLoading, setLoading] = useState(true)
  const [paymentData, setPaymentData] = useState<IMakePaymentData>()
  const [paymentDetails, setPaymentDetails] = useState<Record<string, any>>()

  useEffect(() => {
    const payload: IMakePaymentData = jwtDecode(token)
    setPaymentData(payload)

    getPaymentData({ orderType: payload.orderType, refId: payload.refId })
      .then((data) => setPaymentDetails(data))
      .finally(() => setLoading(false))
  }, [])

  const title = useMemo(() => {
    if (!paymentData) return ''
    switch (paymentData.orderType) {
      case PaymentOrderType.LoanSubmissionRush:
        return 'Invoice'
      case PaymentOrderType.PullCreditReport:
        return 'Payment for Pull Credit Report'
      case PaymentOrderType.CounterPartyApproval:
        return PaymentOrderTypeTitles[paymentData.orderType]
    }
  }, [loanNumber, paymentData])

  const onPayCompleted = async (intentId: string, status: any) => {
    const newData = cloneDeep(paymentDetails || {})
    newData.intentId = intentId
    newData.status = status
    setPaymentDetails(newData)
  }

  return (
    <TemporaryLayout title={title}>
      <LayoutLoading show={isLoading} />
      {!isLoading && paymentData && (
        <div className="max-w-4xl">
          <StripeWithMethod
            availableMethods={
              [
                PaymentOrderType.LoanSubmissionRush,
                PaymentOrderType.PullCreditReport,
                PaymentOrderType.CounterPartyApproval,
              ].includes(paymentData.orderType)
                ? [StripeMethod.card]
                : undefined
            }
            hasAdditionalBankMethod={[PaymentOrderType.LoanSubmissionRush].includes(paymentData.orderType)}
            paymentDetails={paymentDetails}
            paymentData={paymentData}
            onCompleted={onPayCompleted}
          />
        </div>
      )}
    </TemporaryLayout>
  )
}
