import { logout } from 'actions'
import { IS_MAIN_COMPANY } from 'config'
import jwt from 'jwt-decode'
import { FeeWorkSheetPage } from 'pages/Loan/FeeWorkSheet'
import { lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { PermissionGate } from 'utils/PermissionGate'

const AddAppraisalInformationV2 = lazy(() => import('pages/Loan/OrderAppraisals/AddAppraisalInformationV2'))
const AppraisalInformation = lazy(() => import('pages/Loan/Underwriting/AppraisalInformation/index'))
const BudgetScopeOfWork = lazy(() => import('pages/Loan/Budget'))
const ByteproLogs = lazy(() => import('pages/Loan/ByteproLogs'))
const ClosingCalendar = lazy(() => import('pages/Loan/ClosingCalendar'))
const ClosingScreen = lazy(() => import('pages/Loan/ClosingScreen'))
const ContractorApplication = lazy(() => import('pages/Loan/ContractorApplication'))
const ContractorApplications = lazy(() => import('pages/Loan/ContractorApplications'))
const Dataverify = lazy(() => import('pages/Loan/Underwriting/Dataverify/Dataverify'))
const DocumentGenie = lazy(() => import('pages/Loan/DocumentGenie/DocumentGenie'))
const ExpirationDates = lazy(() => import('pages/Loan/Underwriting/ExpirationDates'))
const Funding = lazy(() => import('pages/Loan/Funding'))
const Invoices = lazy(() => import('pages/Loan/Invoice/Invoices'))
const LoanChangeRequest = lazy(() => import('pages/Loan/LoanChangeRequest'))
const LoanInTake = lazy(() => import('pages/Loan/LoanInTake'))
const Notes = lazy(() => import('pages/Loan/Servicing/Notes/Notes'))
const Parties = lazy(() => import('pages/Loan/Parties'))
const PostClosing = lazy(() => import('pages/Loan/PostClosing'))
const PurchaseAdvice = lazy(() => import('pages/Loan/PostClosing/PurchaseAdvice'))
const PurchaseAdviceSeller = lazy(() => import('pages/Loan/PostClosing/PurchaseAdviceSeller'))
const ReportDetails = lazy(() => import('pages/Loan/Reports/ReportDetails'))
const Reports = lazy(() => import('pages/Loan/Reports/Reports'))
const ReviewForm = lazy(() => import('pages/Loan/Underwriting/ReviewForm/ReviewForm'))
const SecondaryMarketingScreen = lazy(() => import('pages/Loan/SecondaryMarketing/SecondaryMarketingScreen'))
const Servicing = lazy(() => import('pages/Loan/Servicing/Servicing'))
const Underwriting = lazy(() => import('pages/Loan/Underwriting/Underwriting'))
const UnderwritingSummary = lazy(() => import('pages/Loan/Underwriting/UnderwritingSummary'))
const Tasks = lazy(() => import('pages/Tasks/tasks'))
const Appraisals = lazy(() => import('pages/Appraisals'))
const LoanOverview = lazy(() => import('pages/LoanOverview'))
const Pipeline = lazy(() => import('pages/Pipeline'))
const Ratesheet = lazy(() => import('pages/Ratesheet'))
const AdminTools = lazy(() => import('pages/Admin/AdminTools/AdminTools'))
const ConditionsAndTemplates = lazy(() => import('pages/Admin/ConditionsAndTemplates/ConditionsAndTemplates'))
const ManageAccountings = lazy(() => import('pages/Admin/Accounting/ManageAccountings'))
const NationalFlood = lazy(() => import('pages/Admin/Vendors/NationalFlood/NationalFlood'))
const SecondaryMarketing = lazy(() => import('pages/Admin/SecondaryMarketing/SecondaryMarketing'))
const UserActivity = lazy(() => import('pages/Admin/UserActivity'))
const Vendors = lazy(() => import('pages/Admin/Vendors'))
const BorrowerDetails = lazy(() => import('pages/Borrower/ManageBorrowers/BorrowerDetails'))
const ManageBorrowers = lazy(() => import('pages/Borrower/ManageBorrowers/ManageBorrowers'))
const EditProfile = lazy(() => import('pages/Auth/EditProfile'))
const AccountDetails = lazy(() => import('pages/ManageAccounts/AccountDetails/AccountDetails'))
const ManageAccounts = lazy(() => import('pages/ManageAccounts/ManageAccounts'))
const ManageInvestors = lazy(() => import('pages/Investor/ManageInvestors/ManageInvestors'))
const ExportHistory = lazy(() => import('pages/ManageAccounts/ExportHistory'))
const AmortizationSchedule = lazy(() => import('pages/Loan/AmortizationSchedule/AmortizationSchedule'))
const GuidelineOverlay = lazy(() => import('pages/Resources/GuidelineOverlay'))
const ManageParticipants = lazy(() => import('pages/Participants/Manage/ManageParticipants'))

const routes = (
  <Switch>
    <Route exact path="/registrations">
      <PermissionGate permission={'MANAGE_ACCOUNTS'}>
        <ManageAccounts />
      </PermissionGate>
    </Route>

    <Route exact path="/registrations/exportHistory">
      <PermissionGate permission={'MANAGE_ACCOUNTS'}>
        <ExportHistory />
      </PermissionGate>
    </Route>

    <Route exact path="/registrations/:userId">
      <PermissionGate permission={'MANAGE_ACCOUNTS'}>
        <AccountDetails />
      </PermissionGate>
    </Route>

    <Route exact path="/user_activity">
      <PermissionGate permission={'MANAGE_USER_ACTIVITY'}>
        <UserActivity />
      </PermissionGate>
    </Route>
    <Route exact path="/admin_tool/:menu">
      <PermissionGate permission={['MANAGE_ADMIN_TOOLS', 'MANAGE_APPRAISER_SETTLEMENT_CONTRACTOR', 'MANAGE_REVIEWS']}>
        <AdminTools />
      </PermissionGate>
    </Route>
    <Route exact path="/accountings/:menu">
      <PermissionGate permission={['MANAGE_ACCOUNTING']}>
        <ManageAccountings />
      </PermissionGate>
    </Route>
    <Route exact path="/secondary_marketing/:menu">
      <PermissionGate permission={'MANAGE_ADMIN_TOOLS'}>
        <SecondaryMarketing />
      </PermissionGate>
    </Route>
    <Route exact path="/vendors/:menu">
      <PermissionGate permission={'MANAGE_VENDORS'}>
        <Vendors />
      </PermissionGate>
    </Route>
    <Route exact path="/condition_template/:menu">
      <PermissionGate permission={'MANAGE_CONDITIONS_TEMPLATES'}>
        <ConditionsAndTemplates />
      </PermissionGate>
    </Route>
    <Route exact path="/servicing/:loan_number">
      <Servicing />
    </Route>
    <Route exact path="/budget/:loanNumber">
      <PermissionGate permission={'BUDGET_SCOPE_OF_WORK'}>
        <BudgetScopeOfWork />
      </PermissionGate>
    </Route>
    <Route exact path="/underwriting/:loan_number">
      <Underwriting />
    </Route>
    <Route exact path="/loanInTake/:loan_number">
      <LoanInTake />
    </Route>
    <Route exact path="/bytepro_logs/:loan_number">
      <ByteproLogs />
    </Route>
    <Route exact path="/edit_profile">
      <EditProfile />
    </Route>
    <Route exact path="/loan_process/:action/:loan_number">
      <LoanOverview />
    </Route>
    <Route exact path="/loan_change_request/:loan_number">
      <LoanChangeRequest />
    </Route>
    <Route exact path="/post_closing/:loan_number">
      <PostClosing />
    </Route>
    <Route exact path="/closing_calendar/:loan_number">
      <ClosingCalendar />
    </Route>
    <Route exact path="/closing_screen/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <ClosingScreen />
      </PermissionGate>
    </Route>
    <Route exact path="/fee_worksheet/:loan_number">
      <FeeWorkSheetPage />
    </Route>
    <Route exact path="/amortization_schedule/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <AmortizationSchedule />
      </PermissionGate>
    </Route>
    <Route exact path="/order_valuation/:loan_number">
      <AddAppraisalInformationV2 />
    </Route>
    <Route exact path="/document_genie/:loan_number">
      <DocumentGenie />
    </Route>
    <Route exact path="/review_form/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <ReviewForm />
      </PermissionGate>
    </Route>
    <Route exact path="/appraisal_information/:loan_number">
      <PermissionGate permission={'MANAGE_APPRAISAL_INFORMATION'}>
        <AppraisalInformation />
      </PermissionGate>
    </Route>
    <Route exact path="/appraisals">
      <PermissionGate permission={'MANAGE_APPRAISAL_INFORMATION'}>
        <Appraisals />
      </PermissionGate>
    </Route>
    <Route exact path="/expiration_dates/:loan_number">
      <ExpirationDates />
    </Route>
    <Route exact path="/notes/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <Notes />
      </PermissionGate>
    </Route>
    <Route exact path="/dataverify/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <Dataverify />
      </PermissionGate>
    </Route>
    <Route exact path="/underwriting_summary/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <UnderwritingSummary />
      </PermissionGate>
    </Route>
    <Route exact path="/purchase_advice/:loan_number">
      <PermissionGate permission={'PURCHASE_ADVICE_BUYER'}>
        <PurchaseAdvice />
      </PermissionGate>
    </Route>
    <Route exact path="/purchase_advice_seller/:loan_number">
      <PermissionGate permission={'PURCHASE_ADVICE_SELLER'}>
        <PurchaseAdviceSeller />
      </PermissionGate>
    </Route>
    <Route exact path="/secondary_marketing_screen/:loan_number">
      <PermissionGate permission={'MANAGE_ADMIN_TOOLS'}>
        <SecondaryMarketingScreen />
      </PermissionGate>
    </Route>
    <Route exact path="/national_flood/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <NationalFlood />
      </PermissionGate>
    </Route>
    <Route exact path="/reports">
      <PermissionGate permission={'MANAGE_REPORTS'}>
        <Reports />
      </PermissionGate>
    </Route>
    <Route exact path="/reports/:id">
      <PermissionGate permission={'MANAGE_REPORTS'}>
        <ReportDetails />
      </PermissionGate>
    </Route>
    <Route exact path="/pipeline">
      <Pipeline />
    </Route>
    <Route exact path="/ratesheet">
      <Ratesheet />
    </Route>
    <Route exact path="/tasks">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/tasks/:projectId">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/tasks/loan/:loanNumber">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/tasks/loan/:loanNumber/:taskNo">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/parties/:loanNumber">
      <PermissionGate permission={['MANAGE_PARTIES', 'MANAGE_PARTIES_INSURANCE_TITLE']}>
        <Parties />
      </PermissionGate>
    </Route>
    <Route exact path="/guidelineOverlay/:documentId">
      <GuidelineOverlay />
    </Route>
    <Route exact path="/funding/:loanNumber">
      <PermissionGate permission={'MANAGE_FUNDING'}>
        <Funding />
      </PermissionGate>
    </Route>
    <Route exact path="/contractor_application/:loanNumber">
      <ContractorApplications />
    </Route>
    <Route exact path="/contractor_application/:no/:loanNumber">
      <ContractorApplication />
    </Route>
    <Route exact path="/invoice/:loanNumber">
      <PermissionGate permission={'INVOICE'}>
        <Invoices />
      </PermissionGate>
    </Route>
    <Route exact path="/borrowers">
      <PermissionGate permission={'MANAGE_BORROWERS'}>
        <ManageBorrowers />
      </PermissionGate>
    </Route>
    <Route exact path="/borrowers/:borrowerId">
      <PermissionGate permission={'MANAGE_BORROWERS'}>
        <BorrowerDetails />
      </PermissionGate>
    </Route>
    <Route exact path="/manageInvestors">
      <PermissionGate permission={'MANAGE_INVESTORS'}>
        <ManageInvestors />
      </PermissionGate>
    </Route>
    <Route exact path="/manageParticipants">
      <PermissionGate permission={'MANAGE_PARTICIPANTS'}>
        <ManageParticipants />
      </PermissionGate>
    </Route>
    <Route path="*">
      <Redirect to={IS_MAIN_COMPANY ? '/home' : '/pipeline'} />
    </Route>
  </Switch>
)

export const ProtectedRoutes = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => state.auth)
  let success = false

  try {
    const payload: any = jwt(auth.token)
    if (payload.exp * 1000 > Date.now()) {
      success = true
    } else {
      dispatch(logout())
    }
  } catch {}

  try {
    if (auth.profile.temparary) {
      dispatch(logout())
      success = false
    }
  } catch {}

  const location = useLocation()

  if (success) return routes

  return <Redirect to={IS_MAIN_COMPANY ? '/home' : '/login'} from={location.pathname} />
}
